import * as Notifications from 'expo-notifications';

// TODO: Support web notifications
export default class NotificationManager {
  static setup() {
    
  }

  /** Show user a notification */
  static async send(title = 'Important', msg = ''): Promise<void> {
    alert(`${title}: ${msg}`)
  }

  /** Show user message at given time. */
  static async sendAt(dateTime: Date | null, title = 'Important', msg = ''): Promise<string> {
    throw new Error('not yet implemented')
  }

  static async cancelAll() {
    throw new Error('not yet implemented')
  }
}