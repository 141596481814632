import { NavigationRoutesReverseMapping } from "../routes"
import Logger from "./Logger"

/**
 * Web support for React Navigation is experimental, this shim is to support
 * visiting arbitrary paths. Very hacky.
 * 
 * @example handleWebPath("/dashboard")
 * @example handleWebPath("/gallery/14")
 * @example handleWebPath("/activity_details/6")
 * */
export function handleWebPath(path: string, navigation) {
  const segments = path.split('/').filter(segment => segment.length > 0)
  
  Logger.log('user wants to go to', segments)
  
  // Route has IDs and shit
  // Flaky, assume only 1 parameter and no nested routes
  if (segments.length > 1) {
    const routeName = NavigationRoutesReverseMapping[segments[0]]
    const routeId = segments[1]

    // This is so dumb lmao, each route has a different key so
    // to be lazy, just include all of them
    const params = { id: routeId, chat_id: routeId, option_id: routeId }

    navigation.navigate(routeName, params)

  } else {
    // Simple route
    navigation.navigate(NavigationRoutesReverseMapping[segments[0]])
  }
}