import { Text, Box, Button, Spinner, Divider, ScrollView, Badge, View } from "native-base";
import { useEffect, useState } from "react";
import SnackbarService from "../../services/SnackbarService";
import { Activity } from "../../types/schema/activities.schema";
import UserDashboardAPI from "../../utilities/api_slices/user_dashboard.api";
import { IS_WEB } from '../../utilities/constants';
import Card from "../shared/Card";
import ActivitySummary from './ActivitySummary';

export default function PendingSignUps({ loading = false }) {
  const [signUps, setSignUps] = useState<Activity[]>(null)

  const reset = async () => {
    const res = await UserDashboardAPI.pendingSignUps()
    if (res) {
      setSignUps(res)
    }
    else {
      SnackbarService.error('Failed to get pending sign ups.')
    }
  }

  useEffect(() => {
    if (loading) {
      reset()
    }
  }, [loading])


  if (!signUps || signUps?.length === 0) {
    return null
  }

  return (
    <Card 
      title="Open for Signup" 
      headerStyleProps={{ alignItems: "center" }} 
      end={<Badge ml={3} px={2.5} rounded="full" bg="tangerine"><Text color="white" fontSize={14} fontWeight="semibold">{signUps.length}</Text></Badge>}
    >
      { !signUps && <Spinner size='lg' color='slate' /> }

      <ScrollView>
        { 
          signUps.map((activity, idx) => 
            <View key={activity.id}>
              { idx > 0 && idx < signUps.length && <Divider my={1} bg="gray.200" _dark={{ bg: "gray.600" }} /> }
              <ActivitySummary key={activity.id} activity={activity} />
            </View>  
          )
        }
      </ScrollView>
    </Card>
  )
}