/** Sleep for given number of milliseconds */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * @deprecated DO NOT USE -- This doesn't work in React Native, JS dates are completely fucked, use Luxon instead.
 */
export function changeTimeZone(date: Date | string, timeZone = 'America/Barbados') {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone }));
}
