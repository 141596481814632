import { Button, Divider, Heading, Input, Row, Column, Spinner, Text, Spacer, Switch, useColorModeValue } from "native-base";
import { useEffect, useState } from "react";
import { Keyboard, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import SnackbarService from "../services/SnackbarService";
import { RootState } from "../store";
import AdminAPI from "../utilities/api_slices/admin.api";
import NotificationsAPI from "../utilities/api_slices/notifications.api";
import AdminActivitiesList from "./admin/AdminActivitiesList";
import PushNotifications from "./admin/PushNotifications";
import { MaterialTopTabNavigationOptions, createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CustomTheme } from "../utilities/theme";
import { setAdminEnabled } from "../redux/adminSlice";

const Tab = createMaterialTopTabNavigator();

export default function AdminScreen({ navigation }) {
  const dispatch = useDispatch()
  const adminEnabled = useSelector((state: RootState) => state.admin.adminEditEnabled)

  const bg = useColorModeValue("white", CustomTheme.colors.dark0)
  const fontColor = useColorModeValue("black", "white")

  const tabOptions: MaterialTopTabNavigationOptions = {
    lazy: true,
    tabBarStyle: {
      height: 40,
      backgroundColor: bg,
    },
    tabBarLabelStyle: {
      textTransform: 'capitalize',
      color: fontColor
    },
    tabBarIndicatorStyle: {
      backgroundColor: CustomTheme.colors.merlot,
      height: 3
    }
  }

  const toggleAdminMode = () => dispatch(setAdminEnabled(!adminEnabled))

  return (
    <SafeAreaView style={{ backgroundColor: bg }}>
      <Column bg={bg} width='full' height='full'>
        <Row px={2} pt={4} alignItems='center'>
          <Heading size='md' ml={2}>Admin</Heading>
          <Spacer />
          <Switch mr={2} isChecked={adminEnabled} onToggle={toggleAdminMode} onTrackColor={'merlot'} />
        </Row>
        <Tab.Navigator screenOptions={tabOptions}>
          <Tab.Screen name='AdminPushNotifications' options={{ title: 'Notifications' }} component={PushNotifications} />
          <Tab.Screen name='AdminActivitiesList' options={{ title: 'Activities' }} component={AdminActivitiesList} />
        </Tab.Navigator>
      </Column>
    </SafeAreaView>
  );
}