import { Button, Image, Spinner, Text, useColorModeValue } from "native-base";
import { TouchableOpacity } from "react-native";

type PropType = {
  loading: boolean,
  onPress: Function
}

const GoogleIcon = require('../../../assets/google-logo.png')

export default function GoogleSignInButton(props: PropType) {
  const { loading, onPress } = props

  const bg = useColorModeValue("white", "dark4")
  const borderColor = useColorModeValue("gray.300", "gray.500")

  return (
    <Button 
      disabled={loading}
      onPress={onPress}
      p={2}
      borderColor={borderColor}
      borderRadius={100}

      bg={bg}
      _pressed={{ bg: borderColor }}

      shadow="1"
      _spinner={{ color: "jet", size: 30 }}
      minHeight="70"
      width="100%"
      

      leftIcon={
        loading ?
        <Spinner mr={2} color="merlot" />
        :
        <Image mr={2} alt="Google Logo" source={GoogleIcon} size={5} />
      }
    >
      <Text fontSize={18}>
        Sign in with Google
      </Text>
    </Button>
  );
}
