import { Avatar, Column, Divider, Heading, Row, ScrollView, View, useColorModeValue } from "native-base";
import { useEffect, useRef, useState } from "react";
import { RefreshControl, TouchableHighlight, TouchableOpacity } from "react-native";
import { FadeIn, FadeInDown, FadeInLeft } from "react-native-reanimated";
import { useDispatch, useSelector } from "react-redux";
import { isDarkMode } from '../hooks/isDarkMode';
import SnackbarService from "../services/SnackbarService";
import { RootState } from "../store";
import { UserDashboardInfo } from '../types/schema/user_dashboard.schema';
import UserDashboardAPI from '../utilities/api_slices/user_dashboard.api';
import { ScreenVisitedBreadcrumb } from "../utilities/screents";
import { CustomTheme } from '../utilities/theme';
import { AnimatedBox, AnimatedColumn, AnimatedRow } from "./animated/AnimatedNativeBase";
import CurrentWeather from './dashboard/CurrentWeather';
import DashboardActivityRow from './dashboard/DashboardActivityRow';
import MyAccomodation from "./dashboard/MyAccomodation";
import PendingSignUps from "./dashboard/PendingSignUps";
import RecentlyUpdatedGallery from "./dashboard/RecentlyUpdatedGallery";
import UserInfoModal from "./dashboard/UserInfoModal";
import HappeningNow from "./dashboard/HappeningNow";
import Logger from "../utilities/Logger";

export default function Dashboard({ navigation }) {
  const dispatch = useDispatch()
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const { activitySignUp, gallery } = useSelector((state: RootState) => state.appEvents)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const updateRef = useRef(false)

  const [data, setData] = useState<UserDashboardInfo>(null)

  useEffect(() => {
    const removeSub = ScreenVisitedBreadcrumb('Dashboard Screen', navigation)
    reset()

    return () => {
      removeSub()
    }
  }, [])

  // Events that will trigger a dashboard refresh
  useEffect(() => {
    updateRef.current = true
  }, [activitySignUp, gallery])

  // Refresh dashboard when possible changes detected
  useEffect(() => {
    const sub = navigation.addListener("focus", () => {
      if (updateRef.current) {
        updateRef.current = false
        reset()
      }
    })

    // Un-subscribe on unmount
    sub
  }, [])

  const reset = async () => {
    setLoading(true)

    let data = await UserDashboardAPI.dashboardInfo()
  
    if (!data) {
      SnackbarService.error('Failed to get dashboard information.')
    }

    setData(data)
    setLoading(false)
  }

  const openUserModal = () => {
    setOpen(true)
  }

  const darkMode = isDarkMode()
  const bg = useColorModeValue("white", "dark1")
  const bg2 = useColorModeValue("white", "dark1")
  const cardBg = useColorModeValue("white", "dark2")
  const backdrop = useColorModeValue("slate", "dark0")
  const refreshColor = CustomTheme.colors.tangerine
  const underlayColor = useColorModeValue(CustomTheme.colors.slate, CustomTheme.colors.dark0)

  return (
    <View height="full" position="relative" bg={bg}>
      {/* Banner */}
      <AnimatedBox entering={FadeIn.duration(1000)} position='absolute' width='100%' height="190" bg={backdrop}></AnimatedBox>

      <ScrollView 
        height="full"
        // contentContainerStyle={{ flex: 1 }}
        refreshControl={<RefreshControl 
        colors={[refreshColor]} 
        tintColor={refreshColor} 
        refreshing={loading} 
        onRefresh={reset} />}
      >

        {/* <Column mt={4}> */}
            <Row position="relative" p={2} alignItems="stretch" mt={4} mb={-7} zIndex={10} flexWrap='wrap' >
              <TouchableOpacity onPress={openUserModal} style={{ flexGrow: 1 }} activeOpacity={0.85}>
                <AnimatedRow 
                  entering={FadeInLeft.delay(1000).duration(750)}
                  px={4} py={3} mr={2} 
                  shadow="none"
                  alignItems='center'
                >
                  <Avatar
                    source={{ uri: userInfo?.photo_src }} 
                    size={70}
                    mb={-5}
                    shadow="2"
                  />
                  
                  <Column>
                    <Heading mt={1} ml={3} color="white" size="md">Welcome, {userInfo?.first_name}</Heading>
                  </Column>
                </AnimatedRow>
              </TouchableOpacity>

            </Row>

            <AnimatedColumn 
              entering={FadeInDown.delay(750).duration(750)}
              p={4}
              pt={12}
              bg={bg2}
              borderTopLeftRadius={30} 
              borderTopRightRadius={30}

              // shadow="9"
              height="full"
            >
              <CurrentWeather loading={loading} />
              <View mb={4} />

              <MyAccomodation />
              <View mb={2} />

              <PendingSignUps loading={loading} />
              <View mb={2} />

              <HappeningNow loading={loading} current={data?.current} upcoming={data?.upcoming} />
              <View mb={2} />

              <RecentlyUpdatedGallery loading={loading} delay={750} />
            </AnimatedColumn>
        {/* </Column> */}
      </ScrollView>

      <UserInfoModal open={open} onClose={() => setOpen(false)} />      
    </View>
  )
}