import * as Font from 'expo-font';
import { View, useColorMode } from "native-base";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/userInfoSlice";
import AuthService from "../services/AuthService";
import UserAPI from "../utilities/api_slices/user.api";
import { IS_WEB } from "../utilities/constants";
import { ScreenVisitedBreadcrumb } from "../utilities/screents";
import { CustomFonts } from "../utilities/theme";
import { sleep } from "../utilities/time";
import { handleWebPath } from "../utilities/webRoutes";
import RallieIcon from "./shared/RallieIconSVG";
import StorageManager from '../services/StorageManager';

/** What the user sees first on app load */
export default function LandingScreen({ navigation }) {
  const { setColorMode } = useColorMode()

  const dispatch = useDispatch()

  const handleWebNav = () => {
    const path = localStorage.getItem("urlToVisit")
    localStorage.removeItem("urlToVisit")

    if (path && !path.includes("Login")) {
      handleWebPath(path, navigation)
    } else {
      navigation.replace('Home')
    }
  }

  async function init() {
    // Load assets
    await Font.loadAsync(CustomFonts)

    // Special case: visiting the support page
    if (IS_WEB) {
      const path = localStorage.getItem("urlToVisit")
      if (path === "/support") {
        handleWebNav()
        return
      }
    }

    if (await AuthService.checkValidSession()) {
      const userData = await UserAPI.getUserData()
      dispatch(setUserInfo(userData))

      // If on Web, user might be trying to visit a specific page
      if (IS_WEB) {
        handleWebNav()
      } else {
        // Is user logging in for the first time?
        let firstLogin = await StorageManager.getItem("FIRST_LOGIN")

        // Doesn't exist, they're new!
        if ((firstLogin == null || firstLogin.length === 0) && !IS_WEB) {
          navigation.replace('Onboarding')
        } else {
          navigation.replace('Home')
        }
      }
    } else {
      navigation.replace('Login')
    }
  }

  useEffect(() => { 
    async function perform() {
      await sleep(1000) 
      await init()
    }

    perform()

    const sub = ScreenVisitedBreadcrumb('Landing Screen', navigation)
    return sub
  }, [])

  return (
    <View width='full' height='full' bg={"lace"} _dark={{ bg: "dark0" }} justifyContent='center' alignItems='center'>
      <RallieIcon animated={true} />
    </View>
  )
}
