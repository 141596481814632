import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AdminState {
  adminEditEnabled: boolean,
}

const initialState: AdminState = {
  adminEditEnabled: false
}

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminEnabled: (state, action: PayloadAction<boolean>) => {
      state.adminEditEnabled = action.payload
    }
  }
})


export const { setAdminEnabled } = adminSlice.actions
export default adminSlice.reducer
