import { useState } from "react";

/** For use with NativeBase Input component. */
export function useInput<T>(defaultValue?: T) {
  const [value, setValue] = useState<T>(defaultValue)

  return {
    value,
    onChangeText: setValue
  }
}
