import { View, Text } from 'native-base';
import React from 'react';

export default class ErrorService {
  public static ErrorBoundary = (props) => props.children

  static init() {
    console.warn('TODO: Error reporting init not setup yet.')
  }

  static notify(error) {
    console.warn('TODO: Error reporting not setup yet.')
  }

  static setUser(id: string, email: string, name: string) {
    console.warn('TODO: Setting user context not setup yet.')
  }

  static breadcrumb(message: string, metadata?: any, type?: string) {
    console.warn('TODO: Setting breadcrumbs not setup yet.')
  }
}

