import { Column, ScrollView, Text, View, useColorModeValue } from "native-base";
import { ActivityDetailedInfo } from "../../types/schema/activity_info.schema";
import MarkdownViewer from "../shared/MarkdownViewer";
import Subtitle from "../shared/Subtitle";
import ActivityOptions from "./ActivityOptions";
import LocationInfo from "../shared/LocationInfo";
import { SafeAreaView } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ActivityDetailsHeader from "./ActivityDetailsHeader";


export default function AssignedActivity(props: { activity: ActivityDetailedInfo }) {
  const { activity } = props
  const bg = useColorModeValue("slate", "dark3")
  const fontColor = useColorModeValue("black", "white")
  
  const insets = useSafeAreaInsets()

  return (
    <>
      <Column p={4} flex={1}>
        <ScrollView>
          {
            activity.description && 

              <View px={2} mb={4}>
                <MarkdownViewer>{activity.description}</MarkdownViewer>
              </View>
          }
          <ActivityOptions activity={activity} />
        </ScrollView>
      </Column>

      <Subtitle px={5} pt={2} pb={Math.max(25, insets.bottom) + "px"}>
        This option has been selected for you. 
        Please reach out to a member of T&C for any questions.
      </Subtitle>
    </>
  )
}
