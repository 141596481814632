import Logger from "../Logger";
import RallieAPI from "../RallieAPI";
import ErrorService from "../../services/ErrorService";
import { PushNotificationMessage } from "../../types/notifications";

export default class NotificationsAPI extends RallieAPI {
  static async sendNotificationToAllUsers({ title, body }: PushNotificationMessage): Promise<boolean> {
    try {
      await this.client.post('/notifications/send_all', { title, body })
      return true

    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to send notifications to everyone.', error)
      return false
    }
  }

  static async sendToUsers(user_ids: number[], { title, body, url, activityId, optionId }: PushNotificationMessage): Promise<boolean> {
    let apiURL = optionId ? `/notifications/${optionId}/send_to_activity_option` : `/notifications/${activityId}/send_to_activity`

    if (activityId) {
      apiURL = optionId ? `/notifications/${optionId}/send_to_activity_option` : `/notifications/${activityId}/send_to_activity`
    } else {
      apiURL = '/notifications/send_to'
    }

    try {
      await this.client.post(apiURL, { title, body, url, user_ids })
      return true

    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to send notifications to users.', error)

      return false
    }
  }

  static async sendToActivityOrOption(title, body, url, activityId: number, optionId?: number) {
    const apiURL = optionId ? `/notifications/${optionId}/send_to_activity_option` : `/notifications/${activityId}/send_to_activity`
    
    try {
      await this.client.post(apiURL, { title, body, url })
      return true
    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to send notifications activity or option.', error)

      return false
    }
  }
}
