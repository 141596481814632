import { MaterialCommunityIcons } from '@expo/vector-icons'
import FeatherIcons from '@expo/vector-icons/Feather';
import { AxiosProgressEvent } from 'axios';
import { AlertDialog, Button, Heading, Row, Spacer, Spinner, Text, Column, View, useColorModeValue, IconButton, Avatar, ScrollView } from "native-base";
import { useEffect, useRef, useState } from "react";
import GalleryAPI from '../../utilities/api_slices/gallery.api';
import ImageGalleryViewer from '../gallery/ImageGalleryViewer';
import MediaUploadActionSheet from '../shared/MediaUploadActionSheet';
import { goBackNavigation, resetNavigation } from '../../helpers/utils';
import { SafeAreaView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Logger from '../../utilities/Logger';
import { shareActivity } from '../../helpers/share.helpers';
import Subtitle from '../shared/Subtitle';
import { MediaUploadProgress } from '../../types/schema/media.schema';
import { useDispatch } from 'react-redux';
import { galleryModified } from '../../redux/appEventsSlice';

export default function ImageGallery({ navigation, route }) {
  const dispatch = useDispatch()
  const insets = useSafeAreaInsets();

  const [actionSheet, setActionSheet] = useState(false)

  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<MediaUploadProgress>({ uploaded: 0, total: 0 })
  const [uploadMessage, setUploadMessage] = useState("")

  const [activity, setActivity] = useState()
  const [option, setOption] = useState()
  
  const alertRef = useRef(null)

  const option_id = route.params.option_id

  const goBack = () => {
    goBackNavigation(navigation)
  }

  const onUploadProgress = (ev: MediaUploadProgress) => { 
    setUploadProgress(ev) 
    setUploadMessage(`Uploading (${ev.uploaded}/${ev.total}), please wait...`)
  }

  const chosenMedia = async (assets) => {
    if (assets) {
      setUploading(true)
      setUploadProgress({ uploaded: 0, total: assets.length })
      setUploadMessage(`Uploading (1/${assets.length}), please wait...`)

      const successful = await GalleryAPI.uploadToGallery(activity.id, option?.id, assets, onUploadProgress)
      closeActionSheet()
      dispatch(galleryModified(null))
    }

    // TODO: Wait a bit before spamming refresh
    setUploading(false)
  }

  const goToActivity = () => {
    navigation.navigate('Activity Details', { id: activity.id });
  }

  const share = () => shareActivity(activity)

  const closeActionSheet = () => setActionSheet(false)

  const onUploadEvent = (event: string) => {
    if (event === "started") {
      setUploadMessage("Please wait...")
      setUploading(true)
    } else if(event === "cancelled") {
      setUploading(false)
    } else {
      // ???
    }
  }

  // Load gallery info
  useEffect(() => {
    if (!option_id) return

    async function run() {
      const result = await GalleryAPI.getGalleryInfo(option_id)

      setActivity(result.activity)
      setOption(result.activity_option)
    }

    run()
  }, [option_id])

  const loading = !activity || !option
  const headerBg = useColorModeValue("slate", "dark2")
  
  if (loading) {
    return (
      <View bg={"white"} _dark={{ bg: "dark0" }} height="full" width="full">
        <Spinner mt={4} />
      </View>
    )
  }
  
  const remainingUsersCount = option.user_count - option.user_photos.length

  return (
    <Column flex={1} bg={"lace"} _dark={{ bg: "dark0" }} stickyHeaderIndices={[2]} nestedScrollEnabled>
      <Row px={4} pt={insets.top + 25 + "px"} pb={insets.top ? 2 : 0} width='100%' minHeight='100' bg={headerBg} alignItems='center'>
        <FeatherIcons onPress={goBack} name='arrow-left' size={32} color='white' />
        <Column ml={4} mx={2} flexShrink={1}>
          <Heading size='md' color='white' numberOfLines={2} ellipsizeMode="tail"> {activity.title} </Heading>
          { option && <Text color='white' numberOfLines={1} ellipsizeMode="tail">{ option.title }</Text> }
        </Column>
      </Row>

      <Row pl={5} pr={4} py={2} bg={headerBg}>
        <ScrollView horizontal bg={headerBg} showsHorizontalScrollIndicator={false}>
          {
            option.user_photos.map((photo_src, idx) => 
              <Avatar key={idx + photo_src} mr={1.5} size="sm" source={{ uri: photo_src }} />  
            )
          }
          {
            remainingUsersCount > 0 && <Avatar color="white" size="sm"><Text fontSize="xs">+{remainingUsersCount}</Text></Avatar>
          }
        </ScrollView>
      </Row>

      <Row px={4} pt={"15px"} py={4} bg={headerBg}>
        <Button
          mr={3}
          background="merlotTint"
          bg="merlot"
          rounded="full"
          shadow="none"
          
          isLoading={uploading}
          onPress={() => setActionSheet(true)} 

          minHeight={1}
          leftIcon={<MaterialCommunityIcons color='white' size={18} name="file-image-plus-outline" />}>
            <Text color="white">Add Photos</Text>
        </Button>

        <Button
          variant="outline"
          rounded="full"
          shadow="none"
          onPress={goToActivity} 

          minHeight={1}
          leftIcon={<FeatherIcons color='white' size={18} name="external-link" />}>
            <Text color="white">Activity</Text>
        </Button>

        <Spacer />

        <IconButton onPress={share} ml={4} icon={<FeatherIcons color='white' size={22} name="share-2" />} />
      </Row>

      <AlertDialog leastDestructiveRef={alertRef} isOpen={uploading}>
        <AlertDialog.Content py={4} px={6}>
          <Row>
            <Spinner color="merlot" />
            <Column ml={4}>
              <Text>{uploadMessage}</Text>
              <Subtitle>This may take a while.</Subtitle>
            </Column>
          </Row>
        </AlertDialog.Content>
      </AlertDialog>

      <MediaUploadActionSheet isOpen={actionSheet} onClose={closeActionSheet} onChosenMedia={chosenMedia} onUploadEvent={onUploadEvent} />
      
      <ImageGalleryViewer key={option_id} activity={activity} option={option} refresh={!uploading} />
    </Column>
  )
}
