import { useNavigation } from "@react-navigation/native";
import { Button, Column, Row, ScrollView, Text, View, useColorModeValue } from "native-base";
import { useRef, useState } from "react";
import SnackbarService from "../../services/SnackbarService";
import { ActivityDetailedInfo, ActivityOption } from "../../types/schema/activity_info.schema";
import ActivityAPI from "../../utilities/api_slices/activity.api";
import { SNACKBAR_LONG } from "../../utilities/constants";
import MarkdownViewer from "../shared/MarkdownViewer";
import Subtitle from "../shared/Subtitle";
import ActivityOptions from "./ActivityOptions";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import LocationInfo from "../shared/LocationInfo";
import { useDispatch } from "react-redux";
import { activitySignUpModified } from "../../redux/appEventsSlice";
import ErrorService from "../../services/ErrorService";
import ActivityDetailsHeader from "./ActivityDetailsHeader";
import AlertService from "../../services/AlertService";

export default function SignUpActivity(props: { activity: ActivityDetailedInfo }) {
  const { activity } = props

  const dispatch = useDispatch()
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const [myActivity, setMyActivity] = useState(activity)
  const [loading, setLoading] = useState(false)

  const originalChosenOptionId = useRef(activity.chosenOption)

  const onSelectionUpdated = (chosen: ActivityOption) => {
    setMyActivity({
      ...myActivity,
      chosenOption: chosen.id
    })
  }

  const handleSignUp = async () => {
    setLoading(true)

    try {
      const result = await ActivityAPI.signUpForActivityOption(activity.id, myActivity.chosenOption)
      SnackbarService.show({
        text: result.success ? `Signed up for "${activity.title}"` : result.msg,
        duration: SNACKBAR_LONG,
        action: {
          text: 'Okay'
        }
      })
  
      // We have nested navigators (Root stack navigator --> Home tab navigation)
      // See: https://reactnavigation.org/docs/params/#passing-params-to-nested-navigators
      // Need to let the schedule screen know that it should refresh to reflect the chosen option
      if (result.success) {
        dispatch(activitySignUpModified(null))
  
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          navigation.navigate('Home', { screen: 'Schedule', params: { update: true } })
        }
      }
    } catch (error) {
      ErrorService.notify(error)
    } finally {
      setLoading(false)
    }
  }

  const removeSignUp = () => {
    AlertService.confirm(`Remove sign up for "${myActivity.title}"?`, "", [
      { text: "Cancel" },
      { text: "Remove", style: "destructive" ,
        onPress: async () => {
          setLoading(true)
          const success = await ActivityAPI.removeSignUpForActivity(activity.id)
          if (success) {
            dispatch(activitySignUpModified(null))
            SnackbarService.warn("Removed sign-up for:" + activity.title)
            if (navigation.canGoBack()) {
              navigation.goBack()
            } else {
              navigation.navigate('Home', { screen: 'Schedule', params: { update: true } })
            }
          } else {
            setLoading(true)
            SnackbarService.error("Failed to remove sign-up. Please try again later")
          }
        }
      }
    ])
  }

  const disabled = myActivity.chosenOption === activity.chosenOption
  const canRemoveSignUp = !!activity.chosenOption

  const bg = useColorModeValue("slate", "dark3")
  const fontColor = useColorModeValue("black", "white")

  return (
    <Column flex={1}>
      <Column p={4} flex={1}>
        <ScrollView>
          {
            myActivity.description && 
            <View px={2} mb={4}>
              <MarkdownViewer>{myActivity.description}</MarkdownViewer>
            </View>
          }

          <ActivityOptions activity={myActivity} onChosen={onSelectionUpdated} />
        </ScrollView>
      </Column>

      <Row>
        {
          canRemoveSignUp &&
          <Button
            isLoading={loading}
            rounded='none'
            shadow="none"
            colorScheme="gray"
            flexGrow={0.2}
            height={insets.bottom + 50 + "px"}
            onPress={removeSignUp}
          >
            <Text>Remove Sign-Up</Text>
          </Button>
        }

        <Button
          isLoading={loading}

          rounded='none'
          shadow="none"
          onPress={handleSignUp} 
          backgroundColor={disabled ? 'gray.400' : 'orange.400'}
          bg={disabled ? "gray.400" : "tangerine"}
          flexGrow={1}
          height={insets.bottom + 50 + "px"}
        >
          <Text color={disabled ? "gray.600" : "white"}>Sign Me Up!</Text>
        </Button>
      </Row>
    </Column>
  )
}
