import { Linking, Platform } from "react-native"
import Logger from "../utilities/Logger"
import { MapMarkerType } from "../types/maps"
import { NavigationProp } from "@react-navigation/native"

/** 
 * Open Apple Maps (on iOS) and Google Maps (on Android) for user.
 * 
 */
export function openExternalMap(latitude: number, longitude: number, label = 'Unknown') {
  // Important!! Will crash on iOS if it has trailing spaces
  label = label.trim()

  const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' })
  const latLng = `${latitude},${longitude}`

  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`
  })

  Logger.log('opening url', url)
  Linking.openURL(url)
}

/** Open to map screen with given location already selected */
export function openInternalMap(location: MapMarkerType, navigation: NavigationProp<ReactNavigation.RootParamList>) {
  const { routes } = navigation.getState()
  const prevScreen = routes[routes.length - 1]
  const prevScreenName = prevScreen.name.split("-")[0]

  // Extra info so the map can navigate
  navigation.navigate("Map", { marker: location, prevScreen: { name: prevScreenName, params: prevScreen.params } }) 
}
