export function randomId(length: number): string {
  let result = '';
  
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  let counter = 0;
  
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}

/** Get a random number between min and max */
export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min);
}

export function goBackNavigation(navigation) {
  if (navigation.canGoBack()) {
    // Make sure we really can. On web at least, going back
    // to landing screen causes page to do nothing.
    const routes = navigation.getState()?.routes
    const prevRoute = routes[routes.length - 2];

    if (prevRoute.name === "LandingScreen") {
      resetNavigation(navigation)
    } else {
      navigation.goBack()
    }
  } else {
    resetNavigation(navigation)
  }
}

export function resetNavigation(navigation) {
  navigation.reset({ routes: [{ name: 'LandingScreen' }] })
}
