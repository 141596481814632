import FeatherIcons from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { Box, Button, Column, Image, ScrollView, Spacer, Spinner, Text, View } from "native-base";
import { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { GalleryItemType, GalleryListType } from '../../types/schema/galleries.schema';
import GalleryAPI from '../../utilities/api_slices/gallery.api';
import Card from "../shared/Card";
import Subtitle from "../shared/Subtitle";
import { IS_WEB } from '../../utilities/constants';
import Logger from '../../utilities/Logger';

export default function RecentlyUpdatedGallery({ loading = false, delay = 0 }) {
  const navigation = useNavigation()
  const [galleries, setGalleries] = useState<GalleryListType>([])

  const getGalleries = async () => {
    Logger.log("Getting recently uploaded galleries")
    setGalleries(await GalleryAPI.getRecentlyUpdatedGalleries())
  }

  useEffect(() => {
    if (loading) {
      getGalleries()
    }
  }, [loading])

  const hasRecentlyUpdated = galleries.length > 0

  return (
    <Card 
      title="Recent Uploads" 
      styleProps={{ height: hasRecentlyUpdated ? 64 : 110 }} 
      end={
        <>
          <Spacer />
          <SeeMoreButton mt={-2} textOnly />
        </>
      }
    >
      { loading && <Spinner color="tangerine" /> }
      
      { !loading && !hasRecentlyUpdated && 
        <Column>
          <Subtitle>Be the first!</Subtitle> 
        </Column>
      }
      { !loading && hasRecentlyUpdated &&
      
        <Column mt={2} height={IS_WEB ? "full" : "auto"}>
          <ScrollView height={"92%"} horizontal contentContainerStyle={{ flexGrow: 1 }}>
            { galleries.map(
                gallery => 
                  <RecentlyUpdatedItem 
                    key={gallery.activity_option.id} 
                    gallery={gallery} 
                    onPress={() => navigation.navigate('Gallery', { option_id: gallery.activity_option.id })} 
                  />
            )}

            {/* <SeeMoreButton key="see_more_button" /> */}
          </ScrollView>
        </Column>
      }
    </Card>
  )
}

const SeeMoreButton = (props) => {
  const navigation = useNavigation()

  return (
    <Button
      ml={1}
      py={props.textOnly ? 0 : undefined}
      variant={props.textOnly ? "ghost" : "outline"}
      colorScheme="gray"
      shadow="none"

      minHeight="5"
      onPress={() => { navigation.navigate('GalleryList') }} 
      // rightIcon={<FeatherIcons size={20} name='arrow-right' color="gray" />}

      { ...props }
    >
      <Text color="gray.500">View All</Text>
    </Button>
  )
}

const RecentlyUpdatedItem = (props: { gallery: GalleryItemType, onPress: () => any }) => {
  const { gallery, onPress } = props

  const media_url = gallery.activity_option.last_media_url

  return (
    <TouchableOpacity onPress={onPress}>
      <Box mr={1} width={135} height={IS_WEB ? "95%" : "auto"} rounded="md" backgroundColor="white">
        <Column position="relative" flexGrow={1} alignItems={'center'} justifyContent={'center'}>  
          <Image 
            rounded="md"
            width='full' height='full' 
            source={{ uri: media_url }} 
            resizeMode='cover' 

            alt={gallery.activity_option.title}
            fallbackElement={<Text>Be the first to upload!</Text>}
          />

          <View p={1} width="full" position="absolute" bottom="0" backgroundColor="rgba(0,0,0,0.6)" roundedBottom="md">
            <Text numberOfLines={2} fontSize={12} color="white" ellipsizeMode="tail">
              { gallery.activity.title }
              &nbsp;{gallery.activity_option.title && `(${gallery.activity_option.title})`}
            </Text>
          </View>
        </Column>
      </Box>
    </TouchableOpacity>
  )
}
