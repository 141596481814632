import { Divider, Text } from "native-base";
import { Activity } from "../../types/schema/activities.schema";
import Card from "../shared/Card";
import ActivitySummary from "./ActivitySummary";
import Subtitle from "../shared/Subtitle";

type PropType = {
  current?: Activity
  upcoming?: Activity,
  loading: boolean
}

export default function HappeningNow({ current, upcoming, loading }: PropType) {
  const hasStuff = !!current || !!upcoming
  const hasBoth = !!current && !!upcoming

  return (
    <Card title="Happening Now">
      { !hasStuff && <Subtitle>You're all set!</Subtitle> }
      {
        hasStuff &&
        <>
          { current && <ActivitySummary activity={current} /> }
          { hasBoth && <Divider my={1} bg="gray.200" _dark={{ bg: "gray.600" }} /> }
          { upcoming && <ActivitySummary activity={upcoming} /> }
        </>
      }
    </Card>
  )
}