import * as Notifications from "expo-notifications";
import { Button, Column, Heading, Spacer, Text } from "native-base";
import { Overlay } from "./Overlay";
import { SkipButton } from "./SkipButton";
import { useState } from "react";

export function NotificationPermissions({ next }) {
  const [loading, setLoading] = useState(false);

  const requestNotifications = async () => {
    setLoading(true)

    try {
      await Notifications.requestPermissionsAsync();
    } catch (error) {
    } finally {
      setLoading(false)
      next();
    }
  };

  return (
    <Overlay>
      <Column>
        <Heading mb={4} color="lace" fontSize="2xl">Stay informed and connected</Heading>
        <Text color="lace" fontSize="lg">Get notified of upcoming events, changes to activities, and other important information.</Text>
      </Column>

      <Spacer />

      <Column
        position="absolute"
        bottom="12%" 
        alignSelf="center"
        width="full"
      >
        <Button
          mb={8}
          width="full"
          rounded="full"

          isLoading={loading}
          onPress={requestNotifications}

          background="merlotTint"
          bg="merlot"
        >
          <Text ml={2} color="white">Allow Notifications</Text>
        </Button>

        <SkipButton onPress={next} />
      </Column>
    </Overlay>
  );
}
