import FeatherIcons from '@expo/vector-icons/Feather';
import { useNavigation } from "@react-navigation/native";
import { Avatar, Box, Column, FlatList, Heading, Image, Row, Spacer, Switch, Text, View, useColorModeValue } from "native-base";
import { useEffect, useRef, useState } from "react";
import { RefreshControl, SafeAreaView, TouchableHighlight, TouchableOpacity } from "react-native";
import { GalleryItemType, GalleryListType } from "../../types/schema/galleries.schema";
import GalleryAPI from "../../utilities/api_slices/gallery.api";
import Subtitle from '../shared/Subtitle';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { goBackNavigation } from '../../helpers/utils';
import { CustomTheme } from '../../utilities/theme';
import Logger from '../../utilities/Logger';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const FAVICON = require("../../../assets/favicon.png")

export default function GalleryList() {
  const { gallery } = useSelector((state: RootState) => state.appEvents)

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState<GalleryListType>([])
  const [showAll, setShowAll] = useState(false)
  
  const refreshing = useRef(false)

  const insets = useSafeAreaInsets()
  const navigation = useNavigation()

  // navigation.addListener('focus', async () => {
  //   if(!refreshing.current) {
  //     refreshing.current = true

  //     await getGallerylist()
  //     refreshing.current = false
  //   }
  // })

  async function getGallerylist() {
    setLoading(true)

    setList(await GalleryAPI.getGalleryList(showAll))
    
    setLoading(false)
  }

  useEffect(() => {
    getGallerylist()
  }, [showAll])

  useEffect(() => {
    getGallerylist()
  }, [gallery])

  const emptyList = list.length === 0
  const bg = useColorModeValue("white", CustomTheme.colors.dark0)
  const headerBg = useColorModeValue("slate", CustomTheme.colors.dark2)
  const refreshColor = CustomTheme.colors.tangerine

  list.sort((a, b) => {
    const firstHas = !!a.activity_option.last_media_url
    const secondHas = !!b.activity_option.last_media_url
    const both = firstHas && secondHas

    if (both) {
      return 0
    } else if(firstHas) {
      return -1
    } else {
      return 1
    }
  })

  return (
    <View height="full" bg={bg}>
      <View>
        <Row pl='4' pr='2' width='full' minHeight={75 + insets.top + "px"} pt={insets.top + "px"} bg={headerBg} alignItems='center'>
          <Heading ml={2} size='md' color='white'>My Gallery</Heading>

          <Spacer />
          <Row px={3} py={1} alignItems="center">
            <Text mr={2} color="white">{ showAll? "All Galleries" : "For Me" }</Text>
            <Switch onTrackColor="tangerine" value={showAll} disabled={loading} onToggle={() => setShowAll(!showAll)} />
          </Row>
        </Row>
      </View>

      {
        emptyList && !loading && <Subtitle mt={3} fontSize="sm" textAlign="center">No galleries. Try again later.</Subtitle>
      }

      <SafeAreaView style={{ flex: 1, backgroundColor: bg }}>
        <FlatList
          mt={3}
          px={3}
          refreshing={loading}
          refreshControl={<RefreshControl refreshing={loading} onRefresh={getGallerylist} colors={[refreshColor]} tintColor={refreshColor} />}
          data={list}
          renderItem={({item}) => <GalleryRow key={item.activity_option.id} item={item} onPress={navigation.navigate} /> }
        />
      </SafeAreaView>
    </View>
  )
}

type PropType = {
  item: GalleryItemType,
  onPress: Function
}

function GalleryRow(props: PropType) {
  const { item: { activity, activity_option }, onPress } = props

  const openGallery = () => {
    onPress('Gallery', { option_id: activity_option.id })
  }

  const startTime = DateTime.fromJSDate(new Date(activity.start_time as Date));
  const startTimeTitle = startTime.toRelative();
  const startTimeReadable = startTime.setZone("America/Barbados").toLocaleString({
    month: 'short', day: '2-digit', weekday: "short",
    hour: '2-digit', minute: '2-digit', hour12: true
  });

  const NoImage = () =>
    <Row width="33%" justifyContent="center" alignItems="center">
      <FeatherIcons size={40} color="lightgray" name='image' />
    </Row>

  const underlayColor = useColorModeValue(CustomTheme.colors.white, CustomTheme.colors.dark0)

  console.log({ activity })
  return (
    <TouchableHighlight underlayColor={underlayColor} onPress={openGallery}>
      <Box mb={2} height="95px" flexDirection="row" bg="white" _dark={{ bg: "dark2" }} shadow={1} rounded="sm">
        {
          activity_option.last_media_url ?
          <Image 
            alt="Gallery Preview"
            width="33%" height="full" 
            source={{ uri: activity_option.last_media_url || "no-image" }} 
            rounded="sm" borderRightRadius={0} 
            fallbackElement={<NoImage />}
          />
          :
          <NoImage />
        }
        {/* <Image 
          alt="Gallery Preview"
          width="25%" height="full" 
          source={{ uri: activity_option.last_media_url || "no-image" }} 
          rounded="sm" borderRightRadius={0} 
          fallbackElement={
            <Row width="25%" justifyContent="center" alignItems="center">
              <FeatherIcons size={40} color="lightgray" name='image' />
            </Row>
          }
        /> */}

        <Column flexShrink={1} px={3} py={2}>
          <Heading size="sm" numberOfLines={2} ellipsizeMode="tail">{activity.title}</Heading>
          {
            activity_option?.title?.length > 0 &&
            <>
              <Text fontSize="sm" numberOfLines={2} ellipsizeMode="tail">{activity_option.title}</Text>
              <Spacer />
            </>
          }
          <Subtitle fontSize="sm">{startTimeReadable}</Subtitle>
        </Column>
      </Box>
    </TouchableHighlight>
  );
}
