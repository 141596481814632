import { Column } from "native-base";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const Overlay = (props) => {
  const insets = useSafeAreaInsets()
  const pt = Math.max(insets.top + 10, 50) + "px"

  return (
    <Column px={8} pt={"35%"} position="relative"  width="full" height="full"  {...props} />
  )
}
