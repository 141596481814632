import AsyncStorage from '@react-native-async-storage/async-storage';

export default class StorageManager {
    static _storageDriver = AsyncStorage

    static async setItem(key: string, value: string) {
        await StorageManager._storageDriver.setItem(key, value)
    }

    static async getItem(key: string): Promise<string | null>  {
        return await StorageManager._storageDriver.getItem(key)
    }

    static async removeItem(key: string) {
        return await StorageManager._storageDriver.removeItem(key)
    }

    static async clear() {
        return await StorageManager._storageDriver.clear()   
    }
}