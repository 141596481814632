import { Button, Column, Text } from "native-base";
import { SafeAreaView, StyleSheet } from "react-native";
import Subtitle from "./shared/Subtitle";
import { resetNavigation } from "../helpers/utils";

export default function SupportScreen({ navigation }) {
  return (
    <SafeAreaView style={StyleSheet.absoluteFill}>
      <Column width="full" height="full" justifyContent="center" alignItems="center">
        <Text>For any questions, comments or support, please contact: support@rallie.org</Text>
        <Button mt={4} onPress={() => resetNavigation(navigation)}>Done</Button>
      </Column>
    </SafeAreaView>
  )
}