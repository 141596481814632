import { IS_WEB } from "../constants";
import Logger from "../Logger";
import RallieAPI from "../RallieAPI";
import ErrorService from "../../services/ErrorService";
import { ActivityOptionIdToUserMapping, ActivityOptionSignedUpUser } from "../../types/schema/activity_info.schema";
import SnackbarService from "../../services/SnackbarService";
import { store } from "../../store";

export default class ActivityAPI extends RallieAPI {
  static ACTIVITY_DETAILS_CACHE_KEY = "activity_details_cache_"

  static async getActivityDetails(id: number) {
    const cacheKey = this.ACTIVITY_DETAILS_CACHE_KEY + id
    const state = store.getState()

    if (!state.network.networkConnected) {
      // Use cache
      return await this.cachedDataFor(cacheKey)
    }
    
    try {
      const resp = await this.client.get('/activities/' + id)    
      const signed_up = (await this.client.get('/activities/' + id + '/attending')).data as ActivityOptionSignedUpUser[]

      const optionIdToUserMapping: ActivityOptionIdToUserMapping = {}

      signed_up.forEach(entry => {
        optionIdToUserMapping[entry.activityId] = optionIdToUserMapping[entry.activityId] || []
        optionIdToUserMapping[entry.activityId].push(entry)
      })

      const result = {
        ...(resp.data),
        optionIdToUserMapping
      }

      // Update cache
      await this.setCacheDataFor(cacheKey, result)

      return result
    } catch (error) {
      ErrorService.notify(error)

      if (error.code === "ERR_NETWORK") {
        // SnackbarService.error("Had a problem reaching the server. Information may be outdated.")
        return await this.cachedDataFor(cacheKey)
      }
    }
  }

  static async signUpForActivityOption(activityId: number, optionId: number): Promise<{ success: boolean, msg: string }> {
    try {
      const resp = await this.client.put(`/activities/${activityId}/sign_up/${optionId}`)
      return resp.data

    } catch (error) {
      ErrorService.notify(error)

      const result = { success: false, msg: '' }
      switch (error.response?.data?.msg) {
        case 'LIMIT':
          result.msg = 'This option is full, please choose another.'
          break;
      
        case "NOT_ALLOWED":
          result.msg = 'You are not allowed to sign up for this.'
          break;

        case "EXPIRED":
          result.msg = 'Sign up has expired for this activity.'
          break;
        default:
          result.msg = 'Error signing up, please try again later.'
          Logger.error('Failed to sign up for activity', activityId, 'with option', optionId, ':', error)
          break;
      }

      return result
    }
  }

  /** True iff successful */
  static async removeSignUpForActivity(activityId: number): Promise<boolean> {
    try {
      await this.client.put(`/activities/${activityId}/remove_sign_up`)
      return true
    } catch (error) {
      ErrorService.notify(error)
      return false
    }
  }

  static async checkInToActivity(activityId: number): Promise<boolean> {
    try {
      const resp = await this.client.put(`/activities/${activityId}/check_in`)
      return resp.status === 200

    } catch (error) {
      ErrorService.notify(error)
      return false
    }
  }

  static async checkOutOfActivity(activityId: number): Promise<boolean> {
    try {
      const resp = await this.client.put(`/activities/${activityId}/check_out`)
      return resp.status === 200

    } catch (error) {
      ErrorService.notify(error)
      return false
    }
  }

  static async listOfCheckedInAndOutUsers(activityId: number): Promise<any> {
    try {
      const resp = await this.client.get(`/activities/${activityId}/checked_in_status`)
      return resp.data

    } catch (error) {
      ErrorService.notify(error)
      return null
    }
  }
}
