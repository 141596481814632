import { Row, Text } from "native-base";
import { SafeAreaView, TouchableOpacity } from "react-native";
import { useDispatch } from "react-redux";
import { setAdminEnabled } from "../../redux/adminSlice";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export default function AdminModeEnabledMessage() {
  const dispatch = useDispatch()
  const insets = useSafeAreaInsets()
  
  return (
    <TouchableOpacity onPress={() => dispatch(setAdminEnabled(false))}>
      <Row pb={2} justifyContent='center' alignItems="flex-end" height={45 + insets.top + "px"} minHeight="50px" backgroundColor='blue.500'>
        <Text mr={2} mb={1} color='white'>Admin Mode: Enabled.</Text>
        <Text mb={1} color='white' underline>Tap to disable.</Text>
      </Row>
    </TouchableOpacity>
  )
}
