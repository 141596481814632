import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface DeepLinkState {
  lastDeepLink: string | null,
}

const initialState: DeepLinkState = {
  lastDeepLink: null
}

export const deepLinkSlice = createSlice({
  name: "deeplinks",
  initialState,
  reducers: {
    setLatestDeepLink: (state, action: PayloadAction<string | null>) => {
      state.lastDeepLink = action.payload
    }
  }
})


export const { setLatestDeepLink } = deepLinkSlice.actions
export default deepLinkSlice.reducer
