import { Image } from "native-base"

const RESORT_MAP = require('../../../assets/grand_palladium_map.png')

/** Web does not have access to google maps. Fallback to resort map. */
export default function MapScreen() {
  return (
    <Image width='full' height='full' source={RESORT_MAP} resizeMode="center" />
  )
}
