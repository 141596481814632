import { HStack, Pressable, Text, useColorModeValue } from "native-base";
import {MaterialIcons} from '@expo/vector-icons';
import { CustomTheme } from "../../utilities/theme";

type PropType = {
  checked: boolean,
  onPress: Function,
  children: any,

  size?: number
}

/** 
 * We tried using the Checkbox provided by native-base, but it's a 
 * steaming pile of horse shit, so here's a better fucking implementation :)
 */
export default function BetterFuckingCheckbox(props: PropType) {
  const { children, checked, onPress, size } = props

  const iconColor = useColorModeValue("black", "white")
  const accentColor = useColorModeValue(CustomTheme.colors.merlot, CustomTheme.colors.tangerine)

  return (
    <Pressable onPress={onPress}>
      <HStack alignItems='center'>
        <MaterialIcons size={size || 18} color={checked ? accentColor : iconColor} name={checked ? 'check-box' : 'check-box-outline-blank' } />
        <Text fontSize={size-6 || 12} ml={2}>{children}</Text>
      </HStack>
    </Pressable>
  );
}
