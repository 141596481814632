import FeatherIcons from '@expo/vector-icons/Feather';
import type { StackScreenProps } from '@react-navigation/stack';
import { Button, Row, Spacer, Spinner, Column, useColorModeValue, IconButton, Text } from "native-base";
import { useEffect, useState } from "react";
import { SafeAreaView } from "react-native";
import ErrorService from "../services/ErrorService";
import SnackbarService from "../services/SnackbarService";
import { ActivityDetailedInfo } from "../types/schema/activity_info.schema";
import ActivityAPI from "../utilities/api_slices/activity.api";
import AssignedActivity from "./activity_details/AssignedActivity";
import InfoActivity from "./activity_details/InfoActivity";
import SignUpActivity from "./activity_details/SignUpActivity";
import { goBackNavigation } from '../helpers/utils';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { shareActivity } from '../helpers/share.helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CustomTheme } from '../utilities/theme';
import ActivityDetailsHeader from './activity_details/ActivityDetailsHeader';

type ActivityDetailsProps = {

}

type PropType = StackScreenProps<ActivityDetailsProps, 'Activity Details'>

/** View detailed info about an activity */
export default function ActivityDetails(props: PropType) {
  const { id } = props.route.params
  const { navigation } = props

  const insets = useSafeAreaInsets()
  const [activity, setActivity] = useState<ActivityDetailedInfo>()
  const [loading, setLoading] = useState(false)
  
  const reset = async () => {
    const data = await ActivityAPI.getActivityDetails(id)
    setActivity(data)
  }
  
  const goBack = () => {
    goBackNavigation(navigation)
  }

  const checkin = async () => {
    setLoading(true)

    if (await ActivityAPI.checkInToActivity(activity.id)) {
      SnackbarService.log(`Checked-in to "${activity.title}".`)
    } else {
      SnackbarService.error(`Failed to check-in to "${activity.title}".`)
    }

    await reset()
    setLoading(false)
  }

  const checkout = async () => {
    setLoading(true)

    if (await ActivityAPI.checkOutOfActivity(activity.id)) {
      SnackbarService.warn(`Checked out of "${activity.title}".`)
    } else {
      SnackbarService.error(`Failed to check out of "${activity.title}".`)
    }

    await reset()
    setLoading(false)
  }

  const share = () => shareActivity(activity)
  const goToAdmin = () => {
    navigation.navigate('ActivityEdit', { activity_id: activity.id })
  }

  useEffect(() => {
    ErrorService.breadcrumb('Activity Details', { activity_id: id }, 'state');

    reset()

  }, [id])

  let activityToRender;

  // If activity isn't loaded yet, we can just fallback to loading spinner
  switch (activity?.type) {
    case 'assigned':
      activityToRender = <AssignedActivity activity={activity} />; break;
    case 'sign_up':
      activityToRender = <SignUpActivity activity={activity} />; break;
    case 'info':
      activityToRender = <InfoActivity activity={activity} />; break;
    default:
      activityToRender = <Spinner mt='6' color='#ff8500' size='lg' />; break;
  }

  const bg = useColorModeValue("lace", "dark0")
  const headerBg = useColorModeValue("slate", "dark3")

  return (
    <Column height={'full'} bg={bg}>
      {/* Header */}
      <Row width='full' minHeight={16} px="30px" pt={insets.top + 20 + "px"} bg={headerBg} alignItems='center'>
        <FeatherIcons onPress={goBack} name='arrow-left' size={32} color='white' />
        <Spacer />

        <IconButton onPress={share} ml={4} icon={<FeatherIcons color="white" size={22} name="share-2" />} />

      </Row>

      {
        activity &&
        <ActivityDetailsHeader loading={loading} activity={activity} onCheckIn={checkin} onCheckOut={checkout} />
      }
      { activityToRender }
    </Column>
  )
}
