import Ionicons from '@expo/vector-icons/Ionicons';
import { IconButton, Row, Spacer, useColorModeValue } from "native-base";
import { useEffect, useRef, useState } from "react";
import { CalendarProvider, ExpandableCalendar, TimelineList } from 'react-native-calendars';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import { isDarkMode } from '../../hooks/isDarkMode';
import { RootState } from '../../store';
import Logger from "../../utilities/Logger";
import RallieAPI from "../../utilities/RallieAPI";
import { CustomTheme } from "../../utilities/theme";
import TimelineActivityEntry from "./schedule_screen/TimelineActivityEntry";

/** Provides a date format compatible with TimelineList (yyyy-mm-dd) */
const dateToString = (date: Date): string => {
  // Account for offset, since ISO string doesn't...
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))

  return date.toISOString().split('T')[0];
}

export default function ScheduleScreen(props) {
  const { activitySignUp } = useSelector((state: RootState) => state.appEvents)

  const insets = useSafeAreaInsets()

  const [days, setDays] = useState([])
  const [currentDay, setCurrentDay] = useState(dateToString(new Date())) // YYYY-MM-DD
  const [activities, setActivities] = useState({})
  const [refreshing, setRefreshing] = useState(true)

  // Optimizations
  const activitiesRef = useRef({})
  const gettingScheduleFor = useRef({})
  const updateRef = useRef(false)

  const today = dateToString(new Date())

  const getDaysAvailable = async () => {
    const daysAvailable = await RallieAPI.getAvailableDays();    
    setDays(daysAvailable)
  }

  const getSchedule = async () =>  {
    let forDay = currentDay
    
    // Only if data doesn't already exist, prevents spamming
    if (activities[forDay] || activitiesRef.current[forDay]) {
      Logger.log("Skipping", forDay, "already have data.")
      return
    }
    
    if (gettingScheduleFor.current[forDay]) {
      return
    }

    gettingScheduleFor.current[forDay] = true

    Logger.log("Getting schedule for day", forDay)

    setRefreshing(true)
    
    const data = await RallieAPI.getSchedule(forDay)
    data.forEach(event => {
      event.color = 'rgba(0,0,0,0)' // Google Calendar LOL
    })

    const events = {}
    events[forDay] = data

    Logger.log('got', data.length, 'events for', forDay)

    const newActivities = { ...activities, ...events }
    
    activitiesRef.current = newActivities
    setActivities(newActivities)
    setRefreshing(false)
    gettingScheduleFor.current[forDay] = false
  }

  const handleDateChanged = async (date: string) => {
    Logger.log("[handleDateChanged]", { currentDay, date })

    // Only query if in list of available dates)
    if (days.includes(date)) {
      // Do we already have data for this date? If so, skip
      if (activities[date] && activitiesRef.current[date]) {
        Logger.log('Already have data for', date, 'skipping...')
        return;
      }

      setCurrentDay(date)

    } else {
      Logger.log(date, 'is outside of range:', days)
    }
  }

  const reset = async () => {
    activitiesRef.current = {}
    setActivities({})
  }

  useEffect(() => {
    Logger.log("Current day changed:", currentDay)
    getSchedule()
  }, [currentDay])

  // When list of activities is nuked, assume refresh
  useEffect(() => {
    if (Object.keys(activities).length > 0) {
      return
    }

    Logger.log("Reset schedule detected, getting new data...", { currentDay });

    (async function run() {
      await getDaysAvailable()
      await getSchedule()
    })()
  }, [activities])

  // When user signs up for something, update
  useEffect(() => {
    updateRef.current = true
  }, [activitySignUp])

  // Refresh when sign up detected
  useEffect(() => {
    const sub = props.navigation.addListener("focus", () => {
      if (updateRef.current) {
        updateRef.current = false
        reset()
      }
    })

    // Un-subscribe on unmount
    sub
  }, [])
  
  const markedDates = {}
  days.forEach(day => markedDates[day] = { marked: true })

  const isDark = isDarkMode()
  const bg = useColorModeValue(CustomTheme.colors.lace, CustomTheme.colors.dark0)
  const lineBg = useColorModeValue(CustomTheme.colors.gray[300], CustomTheme.colors.gray[600])
  const expandableBg = useColorModeValue(CustomTheme.colors.white, CustomTheme.colors.dark2)

  const fontColor = useColorModeValue("black", "white")

  return (
    <>
      <Row w="full" h={insets.top + "px"} bg="white" _dark={{ bg: "dark2" }}>
        <Spacer />
      </Row>
      <IconButton 
        onPress={reset} 
        disabled={refreshing}
        icon={<Ionicons name="refresh" color={fontColor} size={22} />} 

        position="absolute"
        bottom="25px"
        right="25px"
        zIndex={1000}

        p={4}
        shadow="3"
        bg="white"
        
        _pressed={{ bg: "gray.200" }}
        _dark={{ bg: "dark12", _pressed: { bg: "gray.700" } }}
        opacity={refreshing ? 0.5 : 1}

        rounded="full"
      />
      <CalendarProvider
        date={today}
        onDateChanged={handleDateChanged}
        showTodayButton={true}
        theme={{
          backgroundColor: "red"
        }}

        key={days.join("") + isDark.toString()}
      >
        <ExpandableCalendar 
          firstDay={1}
          horizontal={true}
          initialPosition={"closed"}
          
          disablePan={false}
          hideKnob={false}
          allowShadow={false}

          showScrollIndicator={true}

          minDate={days[0]}
          maxDate={days[days.length - 1]}
          pastScrollRange={1}
          futureScrollRange={1}
          
          staticHeader={true}
          closeOnDayPress={true}
          markedDates={markedDates}
          
          numberOfDays={8}
          displayLoadingIndicator={refreshing}

          theme={{
            dayTextColor: fontColor,
            todayTextColor: CustomTheme.colors.tangerine,
            textSectionTitleColor: fontColor,
            monthTextColor: fontColor,
            selectedDayTextColor: "white",
            selectedDayBackgroundColor: CustomTheme.colors.tangerine,

            dotColor: CustomTheme.colors.tangerine,

            // Dark mode
            calendarBackground: expandableBg,
            textDisabledColor: lineBg,
          }}
          headerStyle={{
            backgroundColor: expandableBg,
          }}
          calendarStyle={{
            backgroundColor: expandableBg,
          }}
        />
        <TimelineList 
          events={activities}
          showNowIndicator
          
          timelineProps={{
            // Typical weekday
            // start: 6,
            // end: 23,
            overlapEventsSpacing: 8,
            rightEdgeSpacing: 24,        
            format24h: false,
            renderEvent: (event) => {
              return (
                <TimelineActivityEntry onPress={() => props.navigation.navigate('Activity Details', { id: event.id })} event={event} />
              )
            },
            styles: {
              event: {
                borderWidth: 0
              },
              line: {
                backgroundColor: lineBg
              },
              verticalLine: {
                backgroundColor: lineBg
              },
              calendarBackground: bg,
              backgroundColor: 'red',
              todayBackgroundColor: 'red',
              nowIndicatorLine: {
                backgroundColor: CustomTheme.colors.tangerine
              },
              nowIndicatorKnob: {
                width: 10,
                height: 10,
                marginLeft: -2,
                backgroundColor: CustomTheme.colors.tangerine
              }
            }
          }}
          scrollToNow={true}
        />
      </CalendarProvider>
    </>
  )
}