import * as Location from "expo-location";
import { Button, Column, Heading, Spacer, Text } from "native-base";
import { Overlay } from "./Overlay";
import { SkipButton } from "./SkipButton";
import { useState } from "react";

export function LocationPermissions({ next }) {
  const [loading, setLoading] = useState(false);

  const requestLocation = async () => {
    setLoading(true)

    try {
      await Location.requestForegroundPermissionsAsync();
    } catch (error) {
    } finally {
      setLoading(false)
      next();
    }
  };

  return (
    <Overlay>
      <Column>
        <Heading mb={4} color="lace" fontSize="2xl">Discover more with location</Heading>
        <Text mb={4} color="lace" fontSize="lg">
          Need to grab a bite? Running late for an event? Quickly find what you're looking for.
        </Text>
        <Text color="lace" fontSize="sm">Your location never leaves your device.</Text>
      </Column>

      <Spacer />

      <Column
        position="absolute"
        bottom="12%" 
        alignSelf="center"
        width="full"
      >
        <Button
          mb={8}
          width="full"
          rounded="full"

          isLoading={loading}
          onPress={requestLocation}

          background="merlotTint"
          bg="merlot"
        >
          <Text ml={2} color="white">Allow Location</Text>
        </Button>

        <SkipButton onPress={next} />
      </Column>
    </Overlay>
  );
}
