import FeatherIcons from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { Avatar, Button, Column, Divider, Modal, Row, Spacer, Switch, Text, useColorMode, useColorModeValue } from "native-base";
import { Linking, TouchableHighlight } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../redux/userInfoSlice';
import ActionCableService from '../../services/ActionCableService';
import AlertService from '../../services/AlertService';
import ErrorService from '../../services/ErrorService';
import StorageManager from '../../services/StorageManager';
import { RootState } from '../../store';
import RallieAPI from '../../utilities/RallieAPI';
import { CustomTheme } from '../../utilities/theme';
import { useState } from 'react';

export default function UserInfoModal({ open, onClose }) {
  const dispatch = useDispatch()

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const navigation = useNavigation()

  const colorMode = useColorMode()

  const [loading, setLoading] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(colorMode.colorMode === "dark")

  const logout = async () => {
    try {
      ActionCableService.reset()
      await StorageManager.clear()

      await RallieAPI.logout()
      dispatch(setUserInfo(null))

    } catch (error) {
      AlertService.alert("Error logging out.", "We've been notified of this issue.")
      ErrorService.notify(error)
    } finally {
      navigation.replace('Login')
    }    
  }

  const toggleColorMode = async () => {
    setLoading(true)
    setIsDarkMode(!isDarkMode)

    colorMode.toggleColorMode()
    
    setTimeout(() => setLoading(false), 1000)
  }

  const commonBtnProps = {
    mt: 2,
    minHeight: 5,
    width: "full",
    colorScheme: "blueGray"
  }

  const iconColor = useColorModeValue("black", "white")
  const underlayColor = useColorModeValue(CustomTheme.colors.gray[200], CustomTheme.colors.gray[700])
  
  return (
    <Modal isOpen={open} onClose={onClose}>
      <Modal.Content>
        <Column p={7} alignItems="center">
          <Avatar 
            source={{ uri: userInfo?.photo_src }} 
            size={75}
          />

          <Text mt={4} fontSize="lg">{userInfo?.first_name} {userInfo?.last_name}</Text>
          <Text>{userInfo?.email}</Text>

          <Column mt={4} textAlign="left">
            <Text>Retreat: <Text bold>{userInfo?.retreat?.name}</Text></Text>
            {/* <Text>Branch: <Text bold>{userInfo?.branch?.name}</Text></Text> */}
          </Column>

          <Column my={4} width="full">
            <Divider />
            <Row mt={3} px={2} alignItems="center">
              <Text>Dark Mode</Text>
              <Spacer />
              <Switch value={isDarkMode} isDisabled={loading} onToggle={toggleColorMode} onTrackColor="tangerine" />
            </Row>

            <TouchableHighlight 
              underlayColor={underlayColor}
              {...commonBtnProps}
              style={{ marginTop: 15, marginBottom: 10, padding: 9 }}
              onPress={() => Linking.openURL("https://docs.google.com/document/d/1O5zw8uY6_yJv5Wv4Ek-YlqFQFB5ovjCz2XbTI1eiVFE/edit")} 
              >
              <Row>
                <Text>FAQ</Text>
                <Spacer />
                <FeatherIcons color={iconColor} size={22} name="external-link" />
              </Row>
            </TouchableHighlight>

            <TouchableHighlight 
              underlayColor={underlayColor}
              {...commonBtnProps}
              style={{ marginBottom: 15, padding: 9 }}
              onPress={() => navigation.replace("Onboarding")} 
              >
              <Row>
                <Text>Onboarding</Text>
                <Spacer />
                <FeatherIcons color={iconColor} size={22} name="external-link" />
              </Row>
            </TouchableHighlight>
            <Divider />
          </Column>

          {/* <Button 
            {...commonBtnProps}
            mb={4}
            onPress={Updates.reloadAsync}
            disabled={IS_WEB}
            >
            <Text color="white">DEV - Reload</Text>
          </Button> */}

          <Button 
            {...commonBtnProps}
            onPress={logout}
            colorScheme="danger"
            leftIcon={<FeatherIcons name="log-out" color="white" size={16} />}
          >
            <Text color="white">Logout</Text>
          </Button>
        </Column>
      </Modal.Content>
    </Modal>
  )
}