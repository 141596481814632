import { FontAwesome } from '@expo/vector-icons';
import FeatherIcons from '@expo/vector-icons/Feather';
import { useNavigation } from "@react-navigation/native";
import { Button, FlatList, Row, Pressable, Spacer, Text, Column, View, useColorModeValue } from "native-base";
import { Linking, TouchableOpacity } from "react-native";
import { ActivityDetailedInfo, ActivityOption, ActivityOptionSignedUpUser } from "../../types/schema/activity_info.schema";
import { CustomTheme } from "../../utilities/theme";
import LocationInfo from "../shared/LocationInfo";
import MarkdownViewer from "../shared/MarkdownViewer";
import { useMemo, useRef } from 'react';
import ActionButton from '../shared/ActionButton';
import { openExternalMap } from '../../helpers/map.helpers';

type ActivityOptPropTypes = { 
  activity: ActivityDetailedInfo, 
  onChosen?: Function,
}

export default function ActivityOptions(props: ActivityOptPropTypes) {
  const navigation = useNavigation()
  const { onChosen, activity: { chosenOption, options, optionIdToUserMapping } } = props

  // Hacky lol
  const renderedOnce = useRef(-1)
  renderedOnce.current += 1

  const allowEdit = !!onChosen

  const sortedOptions = useMemo(() => [...options].sort((a, b) => a.id === chosenOption ? -1 : (b.id === chosenOption ? 1 : 0)), [])

  // Only sort the list of options ONCE.
  // If user selects a different option, this will prevent
  // the options from jumping around each time.
  // if (renderedOnce.current < 1) {
  //   sortedOptions.sort((a, b) => a.id === chosenOption ? -1 : (b.id === chosenOption ? 1 : 0))
  // }

  return sortedOptions.map(item => 
    <View px={2} key={item.id} >
      <ActivityOptionRow 
        opt={item} 
        users={optionIdToUserMapping[item.id]} 
        isChosen={item.id === chosenOption}
        onGallerySelected={() => navigation.navigate('Gallery', { option_id: item.id })}

        allowEdit={allowEdit}

        onSelected={onChosen}
      />
    </View>
  )
}

type ActivityOptRowPropType = { 
  opt: ActivityOption, 
  isChosen: boolean,
  onGallerySelected: Function,
  allowEdit?: boolean, 
  onSelected?: Function,
  users: ActivityOptionSignedUpUser[]
}

function ActivityOptionRow(props: ActivityOptRowPropType) {
  let { opt, users, isChosen, allowEdit, onSelected, onGallerySelected } = props

  const handlePress = () => {
    if(allowEdit && onSelected) onSelected(opt)
  }

  const handleNavigation = () => {
    if (!opt.location) {
      return
    }

    console.log('loc url', opt.location?.url)
    if (opt.location.url) {
      Linking.openURL(opt.location.url)
    } else {
      // TODO: Open LOCAL map instead!
      openExternalMap(opt.location.lat, opt.location.long, opt.title)
    }
  }

  users = users?.sort((a, b) => a.userName > b.userName ? 1 : -1)

  const hasLocation = opt.location && (!!opt.location.url || (opt.location.lat && opt.location.long))

  const fontColor = useColorModeValue("black", "white")

  return (
    <Column
      mb='4'
      rounded="lg"
      bg="white"
      _dark={{ bg: "dark2" }}

      borderColor={ isChosen ? 'blue.300' : 'white' }
      borderWidth={isChosen ? 5 : 0}
    >
        <TouchableOpacity onPress={handlePress} activeOpacity={onSelected ? 0.2 : 1}>
          <Row
            px='4' 
            py='4' 
            alignItems='center'
          >
            {
              allowEdit &&
              <View>
                {
                  isChosen ? 
                  <FontAwesome name='dot-circle-o' size={16} color='#2663eb' />
                  :
                  <FontAwesome name='circle-o' size={16} color='gray' />
                }
              </View>
            }

            <Column ml='4' width='full' pr='8'>
              <Row mb='1'>
                <Text color='tangerine' fontWeight='semibold' flexShrink={1}>{ opt.title }</Text>
                <Spacer />
                {opt.limit != null && <Text mt={1} fontSize='xs'>{users?.length || '0'} / {opt.limit}</Text>}
              </Row>

              {
                opt.description && 
                <MarkdownViewer styles={{ paragraph: { fontSize: 14, color: fontColor } }}>{ opt.description }</MarkdownViewer>
              }

              { opt.location && 
                <View mt={2}>
                  <LocationInfo location={opt.location} />
                </View>
              }

              <Text mt='1' color="gray.500" _dark={{ color: "gray.300" }} fontSize='sm'>
                { users?.length > 0 ? 'Going: ' + users.map(u => u.userName).join(', ') : 'Be the first one!' }
              </Text>
            </Column> 
          </Row>
        </TouchableOpacity>

        <Row>
          {
            hasLocation &&
            <ActionButton
              mx={0} p={0} 
              width="50%"
              onPress={handleNavigation}

              colorScheme="blueGray"
              borderBottomWidth={0}
              borderLeftWidth={0}
              borderRightWidth={0}
              borderTopRadius={0}
              borderBottomRightRadius={0}

              startIcon={<FeatherIcons name='map-pin' size={17} color={fontColor} />}
            >
              <Text ml={1}>Navigate</Text>
            </ActionButton>
          }

          <ActionButton
            mx={0} p={0} 
            width="50%"
            onPress={onGallerySelected}

            opacity={0.90}
            
            background="merlotTint"
            bg="merlot"

            borderBottomWidth={0}
            borderLeftWidth={0}
            borderRightWidth={0}

            borderTopRadius={0}
            borderBottomLeftRadius={hasLocation ? 0 : undefined}

            startIcon={<FeatherIcons name='image' color="white" size={17} />}
          >
            <Text ml={1} color="white">Gallery</Text>
          </ActionButton>
        </Row>
    </Column>

  )
}
