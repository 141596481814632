import Logger from "../Logger";
import RallieAPI from "../RallieAPI";
import ErrorService from "../../services/ErrorService";
import { UserDashboardInfo } from "../../types/schema/user_dashboard.schema";
import { Activity } from "../../types/schema/activities.schema";
import { store } from "../../store";
import StorageManager from "../../services/StorageManager";
import SnackbarService from "../../services/SnackbarService";
import { SNACKBAR_LONG } from "../constants";

export default class UserDashboardAPI extends RallieAPI {
  static USER_INFO_CACHE_KEY = "dashboard_user_info"
  static PENDING_SIGNUP_CACHE = "pending_signups_cache"

  /** Returns cached data if no internet */
  static async dashboardInfo(): Promise<UserDashboardInfo> {
    const state = store.getState()

    if (!state.network.networkConnected) {
      // Use cache
      SnackbarService.warn("Offline mode. Info may be out-of-date.", { duration: SNACKBAR_LONG })
      return await this.cachedDataFor(this.USER_INFO_CACHE_KEY)
    }

    try {
      const resp = await this.client.get('/user_dashboard')

      await this.setCacheDataFor(this.USER_INFO_CACHE_KEY, resp.data)

      return resp.data

    } catch (error) {
      ErrorService.notify(error)
      
      // Use cache
      if (error.code === "ERR_NETWORK") {
        SnackbarService.warn("Unable to connect to server. Info may be out-of-date.", { duration: SNACKBAR_LONG })

        return await this.cachedDataFor(this.USER_INFO_CACHE_KEY)
      }
    }

    return null
  }

  static async pendingSignUps(): Promise<Activity[]> {
    const state = store.getState()

    if (!state.network.networkConnected) {
      // Use cache
      return await this.cachedDataFor(this.PENDING_SIGNUP_CACHE)
    }

    try {
      const resp = await this.client.get('/user_dashboard/pending_sign_up')

      await this.setCacheDataFor(this.PENDING_SIGNUP_CACHE, resp.data)

      return resp.data

    } catch (error) {
      ErrorService.notify(error)

      if (error.code === "ERR_NETWORK") {
        return await this.cachedDataFor(this.PENDING_SIGNUP_CACHE)
      }
    }

    return null
  }
}
