import FeatherIcons from '@expo/vector-icons/Feather';
import { Actionsheet, Button, Column, Heading, IconButton, Input, KeyboardAvoidingView, Row, Spinner, Text } from "native-base";
import { useEffect, useState } from "react";
import { ActionSheetIOS, Linking, TouchableOpacity } from "react-native";
import { LivingAccomodation } from "../../types/schema/livingAccomodation.schema";
import LivingAccomodationsAPI from "../../utilities/api_slices/livingAccomodations.api";
import ActionButton from "../shared/ActionButton";
import Card from "../shared/Card";
import Subtitle from "../shared/Subtitle";
import { useNavigation } from '@react-navigation/native';
import { useInput } from '../../hooks/forms';
import SnackbarService from '../../services/SnackbarService';

/** Living accomodations are stored LOCALLY on the user's device */
export default function MyAccomodation() {
  const [open, setOpen] = useState(false)
  const [accomodation, setAccomodation] = useState<LivingAccomodation>(null)
  
  const description = useInput("")

  const getAccomodation = async () => {
    const result = await LivingAccomodationsAPI.getAccomodation()
    setAccomodation(result)
    // description.onChangeText(result?.description || "")
  }

  const handleClose = () => {
    // description.onChangeText(accomodation.description || "")
    setOpen(false)
  }

  const handleSave = async () => {
    // User wants to remove their room number
    if (description.value.trim().length === 0) {
      // @ts-ignore Using deleteAccomodation is borked rn, using empty string as a workaround
      await LivingAccomodationsAPI.setAccomodation("")

      SnackbarService.log("Removed accomodation info.")

      await getAccomodation()
      handleClose()
      return;
    }

    await LivingAccomodationsAPI.setAccomodation({
      title: "Grand Palladium",
      description: description.value
    })

    SnackbarService.log("Saved accomodation info.")

    await getAccomodation()
    handleClose()
  }

  useEffect(() => {
    getAccomodation()
  }, [])

  useEffect(() => {
    if (accomodation) {
      description.onChangeText(accomodation.description)
    }
  }, [accomodation])

  return (
    <Card 
      title="My Accomodation" 
      headerStyleProps={{ alignItems: "center", justifyContent: "space-between" }}
      end={
        <Button 
          minHeight={5} 
          py={1} 
          shadow="none" 
          colorScheme='gray' 
          variant="ghost" 
          onPress={() => setOpen(true)} 
          leftIcon={<FeatherIcons style={{ marginBottom: 2.5 }} size={16} name="edit" color="gray" /> }
        >
            <Text fontSize={14} color="gray.500">
              Edit
            </Text>
          </Button>
      } 
    >
      { !accomodation && <Subtitle>Add your room number here.</Subtitle> }
      { accomodation &&
      
        <Row>
          <Row mb={2} alignItems={'center'}>
            { accomodation.location && <FeatherIcons style={{ marginRight: 8 }} name='map-pin' size={16} />}
            <Text fontSize={14}>Room { accomodation.description }</Text>
          </Row>          
        </Row>
      }

      <Actionsheet isOpen={open} onClose={handleClose}>
        <KeyboardAvoidingView behavior="padding">
          <Actionsheet.Content>

              <Column p={3} mb={4} alignItems="flex-start">
                <Heading mb={2} size="md">Update My Accomodation</Heading>
                <Subtitle mb={6}>Keep track of where you are staying. This information does not leave your device.</Subtitle>

                <Input placeholder="Room Number" {...description} />
              </Column>

              <Row mb={4} px={3}>
                <Button mr={4} flexGrow={1} variant="ghost" shadow="none" onPress={handleClose}>
                  <Text color="gray.500">Cancel</Text>
                </Button>

                <Button color="white" ml={4} flexGrow={1} colorScheme="success" onPress={handleSave}>
                  <Text color="white">Save</Text>
                </Button>
              </Row>
          </Actionsheet.Content>
        </KeyboardAvoidingView>
      </Actionsheet>
    </Card>
  )
}
