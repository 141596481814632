import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';
import { DateTime } from "luxon";
import { Platform } from 'react-native';
import ErrorService from '../services/ErrorService';
import SnackbarService from '../services/SnackbarService';
import StorageManager from '../services/StorageManager';
import { store } from '../store';
import { Activity } from '../types/schema/activities.schema';
import { UserInfoType } from '../types/userInfo';
import { WeatherForecast } from '../types/weather';
import Logger from './Logger';
import { API_URL, AUTH_COOKIE_KEY, WEATHER_URL } from './constants';

const IS_WEB = Platform.OS === 'web'

export default class RallieAPI {
  static SCHEDULE_CACHE_KEY = "schedule_cache_"
  static SCHEDULE_DAYS_AVAILABLE_CACHE_KEY = "schedule_days_available_cache_key"

  static authCookie = ''

  protected static _http = axios.create()

  static get client() { return RallieAPI._http }

  /** Must call this first before using the client! */
  static async setup(cookie: string) {
    await StorageManager.setItem(AUTH_COOKIE_KEY, cookie)

    RallieAPI.authCookie = cookie
    RallieAPI._http = axios.create({
      baseURL: API_URL + '/api',
      withCredentials: IS_WEB, // Force using provided cookie
      headers: IS_WEB ? {} : {
        cookie: cookie,
      }
    })
  }

  /** True iff server is alive. */
  static async isAlive(): Promise<boolean> {
    try {
      const resp = await axios.get(API_URL + "/status")
      return resp.status === 200
    } catch (error) {
      // Don't notify error service -- other API calls 
      // will also likely fail if this fails.
      return false
    }
  }

  /** Check if session with given cookie is valid. */
  static async isSessionValid(cookie: string): Promise<boolean> {
    try {
      // Cookie is unused in web target, as it is already persisted in the browser!
      const resp = await axios.get(API_URL + '/api/user_info', {
        withCredentials: IS_WEB,
        headers: IS_WEB ? {} : {
          cookie: cookie
        }
      })
      Logger.log('is session valid', resp.status)
      return resp.status === 200
    } catch (error) {
      Logger.error('Session invalid:', error)

      // Server could be down
      if (error.code === "ERR_NETWORK") {
        return cookie?.length > 0
      }

      return false
    }
  }

  /** 
   * Authenticates with Rails backend, and gets session token.
   * 
   * @return User data iff authentication was successful, null otherwise
   */
  static async authenticateWithServer(idToken: string, test_user = false): Promise<UserInfoType | null> {
    let resp = await axios.post(`${API_URL}/api/${test_user ? 'sign_in_test_user' : 'sign_in_mobile'}`, { 
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        idToken
      },
    }, {
      withCredentials: IS_WEB
    })

    if (resp.status === 200) {
      const userData = resp.data

      // This will still be undefined on web, since browser
      // does not allow access to the set-cookie header for
      // various reasons. But that's okay because the browser
      // will still persist the cookie as it normally does.
      const authCookie = (resp.headers['set-cookie'] || '')[0]
      
      await RallieAPI.setup(authCookie)
      return userData
    }
    else {
      // TODO: Show error
      Logger.error('error authentication with server', resp.status)
      return null
    }
  }

  static async guestLogin(email: string, password: string): Promise<UserInfoType | null>  {
    try {
      let resp = await axios.post(`${API_URL}/api/sign_in_credentials`, { 
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          email,
          password
        },
      }, {
        withCredentials: IS_WEB
      })

      if (resp.status === 200) {
        const userData = resp.data
        const authCookie = (resp.headers['set-cookie'] || '')[0]

        await RallieAPI.setup(authCookie)
        return userData
      }

    } catch (error) {
      ErrorService.notify(error)
    }

    return null
  }

  static async logout() {
    try {
      await RallieAPI.client.delete('/logout')

      RallieAPI.authCookie = ""
      RallieAPI._http = axios.create()
    } catch (error) {
      ErrorService.notify(error)
    }
  }


  /** Let server know what the new (if at all) notification token is */
  static async updateNotificationToken(token: string) {
    if (token?.length === 0) return

    try {
      await RallieAPI.client.post('/notifications', { token: token })
      return true
    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to update FCM token with server:', error)
      return false
    }
  }

  /** List of days that have events, in the formst yyyy-mm-dd */
  static async getAvailableDays(): Promise<string[]> {
    try {
      const resp = await RallieAPI.client.get('/activities_schedule')

      await StorageManager.setItem(this.SCHEDULE_DAYS_AVAILABLE_CACHE_KEY, JSON.stringify(resp.data.days))
      return resp.data.days

    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to get list of available days:', error)

      if (error.code === "ERR_NETWORK") {
        const days = await StorageManager.getItem(this.SCHEDULE_DAYS_AVAILABLE_CACHE_KEY)

        if (days) {
          return JSON.parse(days)
        }
      }

      return []
    }
  }

  /** Get schedule for given day of the year, e.g. 2023-09-18 */
  static async getSchedule(day: string): Promise<Activity[]> {
    let respData;
    const key = this.SCHEDULE_CACHE_KEY + day
    const { networkConnected } = store.getState().network

    if (networkConnected) {
      try {
        const resp = await RallieAPI.client.get('/activities_schedule/' + day)
        respData = resp.data

        // Cache for offline use later
        await StorageManager.setItem(key, JSON.stringify(respData))
      
      } catch (error) {
        ErrorService.notify(error)
        
        // Use cache if can't connect to server
        if (error.code === "ERR_NETWORK") {
          Logger.warn("Server unreachable, used cached data")
          const cached = await StorageManager.getItem(key)
          respData = JSON.parse(cached || null) || []

          // SnackbarService.warn("Unable to connect with server. Schedule may be out-of-date.")
        }
      }
    }
    else {
      Logger.warn('Using cached schedule.')

      const cached = await StorageManager.getItem(key)
      respData = JSON.parse(cached || null) || []
    }

    // Account for offset so that calendar appears identical everywhere
    const local = DateTime.local()
    const puntaCana = DateTime.local({ zone: "America/Barbados" })
    const diff = puntaCana.hour - local.hour

    Logger.log("Timezone difference:", diff)
    respData.forEach(entry => {
      entry.start =  DateTime.fromISO(entry.start).plus({ hours: diff }).toJSDate()
      entry.end = DateTime.fromISO(entry.end).plus({ hours: diff }).toJSDate()
    });

    return respData as Activity[]
  }

  static async currentWeather(): Promise<WeatherForecast> {
    try {
      const response = await fetch(WEATHER_URL)
      const data = await response.json()

      return data
    } catch (error) {
      ErrorService.notify(error)
      return null
    }
  }

  protected static async cachedDataFor(key: string) {
    const data = await StorageManager.getItem(key) || null
    return JSON.parse(data)
  }

  protected static async setCacheDataFor(key: string, data: any) {
    await StorageManager.setItem(key, JSON.stringify(data))
  }

  /** Get formdata for uploading media assets to Rails. */
  protected static formDataFor(toUpload: ImagePicker.ImagePickerAsset[]): FormData {
    const formData = new FormData()
  
    toUpload.forEach((media, index) => {
      const localUri = media.uri

      let filename = localUri.split('/').pop();
      let match = /\.(\w+)$/.exec(filename);
      let type = match ? `image/${match[1]}` : `image`; 

      // Each platfrom has some weird and annoying quirk smh...
      const common = { name: filename, type }
      const contents = Platform.select({
        ios: {
          uri: localUri.replace("file://", ""), 
          ...common 
        },
        android: {
          uri: localUri, 
          ...common
        }
      })

      formData.append('file_' + index, IS_WEB ? localUri : contents)
    })

    formData.append('num_files', toUpload.length)

    return formData
  }
}
