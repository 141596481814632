import Octicons from '@expo/vector-icons/Octicons';
import { Button, Row, Text, View } from "native-base";
import { useEffect, useRef, useState } from "react";
import PagerView from './shared/PagerView';
import Animated, { Easing, FadeInDown, FadeOutUp, cancelAnimation, useSharedValue, withDelay, withRepeat, withSequence, withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { CustomTheme } from '../utilities/theme';
import { AnimatedView } from './animated/AnimatedNativeBase';
import { AllDone } from './onboarding_screen/AllDone';
import { LocationPermissions } from "./onboarding_screen/LocationPermissions";
import { NotificationPermissions } from "./onboarding_screen/NotificationPermissions";
import { StoragePermission } from "./onboarding_screen/StoragePermission";
import { Welcome } from "./onboarding_screen/Welcome";
import StorageManager from '../services/StorageManager';

/** User is authenticated for the first time */
export default function OnboardingScreen({ navigation }) {
  const insets = useSafeAreaInsets()
  const [page, setPage] = useState(0)
  const [show, setShow] = useState(true)

  const scrollerRef = useRef<PagerView>(null)

  const animationDuration = 4500
  const width = useSharedValue(1380)
  const widthForPage = [1400, 1425, 1450, 1475, 1600]

  const next = () => scrollerRef.current.setPage(page + 1)

  const selectedPage = (val) => {
    setPage(val)
    setBubbleAnimation(val, val > page)
  }

  const setBubbleAnimation = (page = 0, overshoot = true) => {
    const amount = widthForPage[page]
    const initialTarget = overshoot ? amount + 20 : amount - 20

    width.value = withSequence(
      withTiming(
        initialTarget,
        {
          easing: Easing.inOut(Easing.sin),
          duration: 750
        }
      ),
      withRepeat(
        withTiming(amount, {
          easing: Easing.inOut(Easing.sin),
          duration: animationDuration,
        }),
        -1,
        true
      )
    )
  }

  const onFinishedOnboarding = (skip = false) => {
    setShow(false)
    // Animate
    width.value = withDelay(
      750,
      withTiming(700,
        {
          easing: Easing.inOut(Easing.sin),
          duration: 1500
        }
      )
    )
    
    if (skip) {
      StorageManager.setItem("FIRST_LOGIN", "true").finally(() => navigation.replace("Home"))
    } else {
      setTimeout(() => navigation.replace("Home"), 1500 + 750)
    }
  }

  useEffect(() => {
    width.value = withDelay(
      1000,
      withRepeat(
        withTiming(widthForPage[0], {
          easing: Easing.inOut(Easing.sin),
          duration: animationDuration,
        }),
        -1,
        true
      )
    )
  }, [])

  return (
    <View width="full" height="full" position="relative">
      <AnimatedView entering={FadeInDown.duration(1000)} style={{ width: width, height: width }} shadow="6" pointerEvents="none" position="absolute" rounded="full" background="slate" top="-950px" left="-650px" />
      {
        show &&
        <>
          <Animated.View exiting={FadeOutUp.duration(750)} style={{ width: "100%", height: "100%" }}>
            <PagerView
              // scrollEnabled={false}

              ref={(ref) => {scrollerRef.current = ref}}
              style={{ flex: 1 }}
              initialPage={page}
              orientation="horizontal"

              onPageSelected={(event) => selectedPage(event.nativeEvent.position)}
            >
              <Welcome next={next} />
              <NotificationPermissions next={next} />
              <LocationPermissions next={next} />
              <StoragePermission next={next} />
              <AllDone onFinishedOnboarding={onFinishedOnboarding} />
            </PagerView>
          </Animated.View>

          <Button onPress={() => onFinishedOnboarding(true)} position="absolute" right="15px" top={Math.max(insets.top + 10, 50) + "px"} pointerEvents="auto" variant="ghost" shadow="none" minH={1}>
            <Text color="gray.300">Skip</Text>
          </Button>

          {/* Pagination */}
          {/* <Row position="absolute" bottom={insets.bottom + 10 + "px"} width="full" justifyContent="center">
            <Octicons name={page === 0 ? "dot-fill" : "dot"} size={24} color={CustomTheme.colors.slate} style={{ marginRight: 10 }} />
            <Octicons name={page === 1 ? "dot-fill" : "dot"} size={24} color={CustomTheme.colors.slate} style={{ marginRight: 10 }} />
            <Octicons name={page === 2 ? "dot-fill" : "dot"} size={24} color={CustomTheme.colors.slate} style={{ marginRight: 10 }} />
            <Octicons name={page === 3 ? "dot-fill" : "dot"} size={24} color={CustomTheme.colors.slate} style={{ marginRight: 10 }} />
            <Octicons name={page === 4 ? "dot-fill" : "dot"} size={24} color={CustomTheme.colors.slate} style={{ marginRight: 10 }} />
          </Row> */}
        </>
      }
    </View>
  )
}

