import FeatherIcons from '@expo/vector-icons/Feather';
import { Actionsheet, Button, HStack, Icon, Image, Spinner, Text, View, useColorModeValue } from "native-base";
import { useState } from "react";
import { Platform, TouchableOpacity } from "react-native";
import { shareUserUploadedMedia } from '../../helpers/share.helpers';
import AlertService from '../../services/AlertService';
import ErrorService from '../../services/ErrorService';
import { UserUploadedMedia } from "../../types/schema/media.schema";
import { IS_WEB } from "../../utilities/constants";
import { CustomTheme } from "../../utilities/theme";
import SwipeableFullScreenMediaViewer from './SwipeableFullScreenMediaViewer';

type PropType = {
  source: UserUploadedMedia,
  allSources: UserUploadedMedia[],

  width: number,
  height: number,

  allowDelete?: boolean,
  handleDelete: Function,

  dense?: boolean,
  imageStyles?: any,

  onLongPress?: (event: any) => any,
  getMoreImages?: Function,
}

export function GalleryItem(props: PropType) {
  const [open, setOpen] = useState(false)
  const [actionSheet, setActionSheet] = useState(false)
  const [deleting, setDeleting] = useState(false)

  let { source, allSources, width, height, imageStyles, dense, allowDelete, onLongPress, getMoreImages } = props
  allowDelete = !!allowDelete

  if (dense == null) dense = false

  const handleLongPress = (event) => onLongPress ? onLongPress(event) : setActionSheet(true)
  const closeActionSheet = () => setActionSheet(false)
  const shareImage = async (source: UserUploadedMedia) => shareUserUploadedMedia(source)

  const _deleteMedia = async (id: number) => {
    setDeleting(true)
    try {      
      await props.handleDelete(id)
    } catch (error) {
      ErrorService.notify(error)      
    }

    setDeleting(false)
  }

  const handleDelete = async (id: number) => {
    const result = AlertService.confirm(
      "Are you sure you want to delete this?", 
      null,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: () => _deleteMedia(id)
        }
      ]
    )

    if (IS_WEB && result) {
      await _deleteMedia(id)
    }
  }

  const commonProps = {
    style: Platform.select({
      web: { width: width, height: height },
      native: { width: '100%', height: '100%' }
    }),
    source: { uri: source.thumbnail }
  }

  const imgProps = {
    alt: '  Failed to load. Please check your network.',
    ...commonProps
  }

  const btnProps = {
    mx: 2,
    flexGrow: 1,
    variant: 'outline',
  }

  const iconColor = useColorModeValue("black", "white")
  const isPhoto = source.content_type.startsWith('image')

  return (
    <TouchableOpacity onPress={() => setOpen(true)} onLongPress={handleLongPress}>
      { 
        open && 
          <SwipeableFullScreenMediaViewer 
            allowDelete={allowDelete} 
            shareImage={shareImage} 
            handleDelete={handleDelete} 
            
            source={source}
            allSources={allSources}

            onClose={() => setOpen(false)}
            onRunninOutOfImages={getMoreImages}
        />
      }

      <View mx={dense ? 0 : 0.5} my={dense ? 0 : 0.5} width={width} height={source.meta.thumbHeight + "px"}>
        {/* All content has image previews! */}
        <View position='relative'>
          <Image rounded='xs' {...(imageStyles || {})}  {...imgProps} />
          {
            !isPhoto && <Icon as={<FeatherIcons name='video' />} position='absolute' top={2} right={2} size={25} color='lace' />
          }
      
          <View px={2} py={1} position="absolute" width="full" bottom={0}>
            <Text color="white" fontSize="12" numberOfLines={2} ellipsizeMode="tail" fontWeight="medium">{ source.uploaded_by }</Text>
          </View>
        </View>
      </View>

      <Actionsheet isOpen={actionSheet} onClose={closeActionSheet}>
        <Actionsheet.Content>
          <Image {...imgProps} mb={4} rounded='md' style={{ width: 200, height: 200 }} />
          <HStack>
            {/* <Button {...btnProps} onPress={closeActionSheet}>
              <Text color='gray.500'>Cancel</Text>
            </Button> */}

            <Button 
              {...btnProps} 
              onPress={() => shareImage(source)}

              shadow="none"

              startIcon={<FeatherIcons color={iconColor} name="share-2" size={14} />}
            >
              <Text>Share</Text>
            </Button>

            {
              allowDelete &&
              <Button 
                {...btnProps} 
                onPress={() => handleDelete(source.id)}

                isLoading={deleting}
                spinner={<Spinner color='error' />}

                shadow="none"

                borderColor='error' 
                startIcon={<FeatherIcons name='trash-2' size={14} color={CustomTheme.colors.error} />}
              >
                <Text color='error'>Delete</Text>
              </Button>
            }
          </HStack>
        </Actionsheet.Content>
      </Actionsheet>

    </TouchableOpacity>
  ) 
}
