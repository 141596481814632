import { Row, Text } from "native-base";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function NoNetworkMessage() {
  const insets = useSafeAreaInsets()

  return (
    <Row 
      pt={insets.top} 
      pb={insets.top ? "8px" : 0}

      justifyContent='center' 
      alignItems='center' 
      minHeight={insets.top + 30 + "px"} 
      backgroundColor='error'
    >
      <Text color='white'>Offline Mode</Text>
    </Row> 
  )
}