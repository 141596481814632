import deepmerge from 'deepmerge';
import { SnackBarOptions } from 'react-native-snackbar';

/** Show alerts to use. Uses snackbar on native, and alert on web. */
export default class SnackbarService {
  static DEFAULT_OPTS = {
    action: {
      text: "Okay"
    }
  }

  static show(opt: SnackBarOptions) { confirm(opt.text) }

  static log(msg: string, opt?: SnackBarOptions) { SnackbarService.show({ text: msg, ...(deepmerge(SnackbarService.DEFAULT_OPTS, opt || {})) }) }
  static warn(msg: string, opt?: SnackBarOptions) { SnackbarService.show({ text: msg, ...(deepmerge(SnackbarService.DEFAULT_OPTS, opt || {})) })  }
  static error(msg: string, opt?: SnackBarOptions) { SnackbarService.show({ text: msg, ...(deepmerge(SnackbarService.DEFAULT_OPTS, opt || {})) }) }
}
