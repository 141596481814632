import { Center, Row, Spinner, Text, View } from "native-base";
import { UserUploadedMedia } from "../../types/schema/media.schema";
import { Modal } from "react-native";
import FullScreenMediaViewer from "./FullScreenMediaViewer";
import { useRef, useState } from "react";
import Logger from "../../utilities/Logger";
import PagerView from "../shared/PagerView";
import { IS_WEB } from "../../utilities/constants";

type PropType = {
  source: UserUploadedMedia,
  allSources: UserUploadedMedia[],
  allowDelete: boolean,
  shareImage?: Function,
  handleDelete: Function,
  localImage?: boolean,

  onClose: Function,
  onRunninOutOfImages?: Function,
}

export default function SwipeableFullScreenMediaViewer(props: PropType) {
  const { allSources, source, onClose, onRunninOutOfImages } = props

  const [hideOverlays, setHideOverlays] = useState(false)
  const [scroll, setScroll] = useState(true)

  const [index, setIndex] = useState(allSources.findIndex(obj => obj.id === source.id))
  // const index = allSources.findIndex(obj => obj.id === source.id)
  const [scrollEnabled, setScrollEnabled] = useState(true)

  const loadedRef = useRef({})

  // Ref used as optimization, to prevent spamming setState
  const scrollEnabledRef = useRef(true)
  const prevZPosition = useRef(0)

  /** User swiped to another image */
  const swipedImage = (idx) => {
    setIndex(idx)

    // When nearing the end, try getting more images
    if (Math.abs(allSources.length - idx) < 6) {
      Logger.log('requesting more images')
      onRunninOutOfImages && onRunninOutOfImages()
    }
  }

  const enableScroll = (why?) => {
    if (!scrollEnabledRef.current) {
      scrollEnabledRef.current = true
      setScrollEnabled(true)

      Logger.log("enabled scroll:", why)
    }
  }

  const toggleClick = (why?) => {
    if (hideOverlays) {
      Logger.log('disabled swipe:', why)
    } else {
      Logger.log('enabled swipe:', why)
    }
    setHideOverlays(!hideOverlays)
  }

  const disableScroll = (why?) => {
    if (scrollEnabledRef.current) {
      scrollEnabledRef.current = false
      setScrollEnabled(false)

      Logger.log("disabled scroll:", why)
    }
  }

  return (
    <Modal statusBarTranslucent presentationStyle='overFullScreen' onRequestClose={onClose}>
      <PagerView 
        style={{ flex: 1 }} 
        initialPage={index}
        scrollEnabled={!hideOverlays}

        orientation="horizontal"
        offscreenPageLimit={5}

        onPageSelected={(e) => swipedImage(e.nativeEvent.position)}
      >
        {
          allSources.map((currSource, idx) => {
            // Web doesn't support pager views...
            if (IS_WEB) {
              if (idx === index) {
                return <FullScreenMediaViewer {...props} source={currSource} />
              } else {
                return null
              }
            }

            if (Math.abs(index - idx) > 2) {
              return (
                <Row key={"hidden_" + currSource.id} width="full" height="full" background="black" alignItems="center" justifyContent="center">
                  <Spinner color="white" />
                </Row>
              )
            }

            return (
              <FullScreenMediaViewer 
                key={currSource.id}
                {...props} 
                source={currSource}
                hideOverlays={hideOverlays}
                imageZoomProps={{
                  panToMove: true,
                  onClick: () => toggleClick("image tap"),
                  // onDoubleClick: () => toggleClick("image double tap"),
                  useNativeDriver: true
                  // ******
                  // TODO: MIGHT NEED TO ENABLE THESE ON IOS??
                  // ******

                  // onDoubleClick: () => disableScroll("double tap"),
                  // onMove: (position) => {
                  //   // If scroll is enabled and user zommed in enough
                  //   const pinchedAmound = Math.abs(prevZPosition.current - position.zoomCurrentDistance)
                  //   if (scrollEnabledRef.current && pinchedAmound > 1) {
                  //     disableScroll("user pinched " + pinchedAmound)
                  //   }

                  //   prevZPosition.current = position.zoomCurrentDistance
                  // },
                  // horizontalOuterRangeOffset: (offsetX) => {
                  //   if (Math.abs(offsetX) > 10) {
                  //     enableScroll("swiping")
                  //   }
                  // }
                }}
              />
            )
          }
          )
        }
      </PagerView>
    </Modal>
  )
}