import ErrorService from "../services/ErrorService";

/** 
 * Adds a subscribtion such that anytime the 
 * screen is viewed, we log a breadcrumb.
 * 
 * Returns a method that, when called, will unsubscribe.
 */
export function ScreenVisitedBreadcrumb(title: string, navigation: any) {
  return navigation.addListener('focus', () => ErrorService.breadcrumb(title, null, 'state'))
}
