import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { Platform, SafeAreaView } from "react-native";
import React, { useContext, useEffect, useState } from 'react';
import StorageManager from '../services/StorageManager';
import { Column, Text, Button, Center, Image, Box, Row, Spinner, View, VStack, ZStack } from 'native-base';
import { API_URL, AUTH_CLIENT_IDS, IS_DEV, IS_WEB } from '../utilities/constants';
import RallieAPI from '../utilities/RallieAPI';
import Logger from '../utilities/Logger';
import RallieIconSVG from './shared/RallieIconSVG';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../redux/userInfoSlice';
import LoginForms from './login_screen/LoginForms';

const GoogleIcon = require('../../assets/google-logo.png')

WebBrowser.maybeCompleteAuthSession();

export default function LoginScreen({ navigation }) {  
  return (
    <VStack width='full' height='full' justifyContent='center' alignItems='center'>
      <View mb={4}>
        <RallieIconSVG animated={false} />
      </View>

      <LoginForms />
    </VStack>
  );
}
