import * as ImagePicker from "expo-image-picker";
import { Button, Column, Heading, Spacer, Text } from "native-base";
import { Overlay } from "./Overlay";
import { SkipButton } from "./SkipButton";
import { useState } from "react";

export function StoragePermission({ next }) {
  const [loading, setLoading] = useState(false);

  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [mlStatus, requestMLPermission] = ImagePicker.useMediaLibraryPermissions();

  const requestStorage = async () => {
    setLoading(true)

    await requestPermission();
    await requestMLPermission();

    setLoading(false)
    next();
  };

  return (
    <Overlay>
      <Column>
        <Heading mb={4} color="lace" fontSize="2xl">Capture and share the moments</Heading>
        <Text mb={4} color="lace" fontSize="lg">Share your favourite photos and videos with the team!</Text>
        <Text fontSize="sm" color="lace">Only the photos and videos you intentionally share leave your device.</Text>
      </Column>
      <Spacer />

      <Column
        position="absolute"
        bottom="12%" 
        alignSelf="center"
        width="full"
      >
        <Button
          mb={8}
          width="full"
          rounded="full"

          isLoading={loading}
          onPress={requestStorage}

          background="merlotTint"
          bg="merlot"
        >
          <Text ml={2} color="white">Allow Photos/Videos</Text>
        </Button>

        <SkipButton onPress={next} />
      </Column>
    </Overlay>
  );
}
