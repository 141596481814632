import { Actionsheet, useColorModeValue } from "native-base";
import FeatherIcons from '@expo/vector-icons/Feather';
import * as ImagePicker from 'expo-image-picker';
import SnackbarService from "../../services/SnackbarService";
import { SNACKBAR_ERROR } from "../../utilities/constants";
import ErrorService from "../../services/ErrorService";
import Logger from "../../utilities/Logger";

type PropType = {
  isOpen: boolean,
  onClose: () => any,
  onChosenMedia: (...args: any) => any,
  onUploadEvent?: (event: string) => any
}

export default function MediaUploadActionSheet(props: PropType) {
  const { isOpen, onClose, onChosenMedia, onUploadEvent } = props

  const [status, requestPermission] = ImagePicker.useCameraPermissions();
  const [mlStatus, requestMLPermission] = ImagePicker.useMediaLibraryPermissions();

  const chooseImage = async (fileUpload = true, video = false) => {
    const options = {
      mediaTypes: video ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
      allowsMultipleSelection: true,
      quality: 1,
    }

    let result
    try {
      onUploadEvent && onUploadEvent("started")
      if (fileUpload) {
        await requestMLPermission();
        result = await ImagePicker.launchImageLibraryAsync(options)
      } else {
        await requestPermission();
        result = await ImagePicker.launchCameraAsync(options);
      }

    } catch (error) {
      // Usually device error
      SnackbarService.show({ 
        text: '[Error] Please make sure you have all permissions enabled.',
        ...SNACKBAR_ERROR,
      })

      console.error(error)
      ErrorService.notify(error)
      onChosenMedia(null)

      onUploadEvent && onUploadEvent("cancelled")
      return
    }

    onUploadEvent && onUploadEvent("cancelled")

    if (result.canceled) {
      onChosenMedia(null)
      onUploadEvent && onUploadEvent("cancelled")

      return
    }
    else {
      onChosenMedia(result.assets)
    }
  }

  const iconColor = useColorModeValue("black", "white")

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content>
        <Actionsheet.Item onPress={() => chooseImage(true)} startIcon={<FeatherIcons style={{ marginTop: 2 }} size={18} name='image' color={iconColor} />}>
          Upload from device
        </Actionsheet.Item>

        <Actionsheet.Item onPress={() => chooseImage(false)} startIcon={<FeatherIcons style={{ marginTop: 2 }} size={18} name='camera' color={iconColor} />}>
          Take a picture
        </Actionsheet.Item>

        <Actionsheet.Item onPress={() => chooseImage(false, true)} startIcon={<FeatherIcons style={{ marginTop: 2 }} size={18} name='video' color={iconColor} />}>
          Take a video
        </Actionsheet.Item>

        <Actionsheet.Item onPress={onClose} startIcon={<FeatherIcons style={{ marginTop: 2 }} size={18} name='x' color={iconColor} />}>
          Cancel
        </Actionsheet.Item>
      </Actionsheet.Content>
    </Actionsheet>
  );
}
