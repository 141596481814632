import FeatherIcons from '@expo/vector-icons/Feather';
import { useNavigation } from "@react-navigation/native";
import { Button, Column, Heading, Spacer, Text } from "native-base";
import { useState } from "react";
import StorageManager from "../../services/StorageManager";
import { Overlay } from "./Overlay";
import { sleep } from '../../utilities/time';

export function AllDone({ onFinishedOnboarding }) {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const finished = async () => {
    setLoading(true)
    await StorageManager.setItem("FIRST_LOGIN", "true");
    await sleep(1000)
    onFinishedOnboarding()
  };

  return (
    <Overlay>
      <Column>
        <Heading mb={4} color="lace" fontSize="2xl">That's it!</Heading>
        <Text color="lace" fontSize="lg">You're ready to Rallie.</Text>
      </Column>
      <Spacer />

      <Button
        position="absolute"
        bottom="22.5%" 
        alignSelf="center"
        width="full"

        background="merlotTint"
        bg="merlot"
        rounded="full"

        shadow={loading ? "none" : "undefined"}
        isLoading={loading}
        onPress={finished}

        startIcon={<FeatherIcons name="home" color="white" size={22} />}
      >
        <Text ml={2} color="white">My Dashboard</Text>
      </Button>

    </Overlay>
  );
}
