import * as FileSystem from 'expo-file-system';
import { AxiosProgressEvent } from "axios";
import ErrorService from "../../services/ErrorService";
import { GalleryListType } from "../../types/schema/galleries.schema";
import { MediaUploadProgress, UserUploadedMedia } from "../../types/schema/media.schema";
import Logger from "../Logger";
import RallieAPI from "../RallieAPI";
import { API_URL, IS_WEB } from "../constants";
import { Alert } from "react-native";
import AlertService from "../../services/AlertService";
import SnackbarService from "../../services/SnackbarService";
import { store } from "../../store";
import { ImagePickerAsset } from 'expo-image-picker';

export default class GalleryAPI extends RallieAPI {  
  static GALLERY_LIST_CACHE_KEY = "gallery_list_cache_key"

  /** Gallery data for given activity or option */
  static async getGallery(activityId: number, optionId: number, page = 0): Promise<UserUploadedMedia[]> {
    let url = `/activities/${activityId}/media/${optionId || ''}?page=${page}`
    const resp = await RallieAPI.client.get(url)

    return resp.data.sources
  }

  static async getGalleryList(showAll: boolean): Promise<any> {
    const { networkConnected } = store.getState().network

    if (!networkConnected) {
      return (await this.cachedDataFor(this.GALLERY_LIST_CACHE_KEY) || [])
    }

    try {
      const data = (await this.client.get(`/gallery?showAll=${showAll}`)).data
      this.setCacheDataFor(this.GALLERY_LIST_CACHE_KEY, data)

      return data
    } catch (error) {
      ErrorService.notify(error)
      SnackbarService.error("Error trying to get gallery list, please try again later.")

      if (error.code === "ERR_NETWORK") {
        return (await this.cachedDataFor(this.GALLERY_LIST_CACHE_KEY) || [])
      }
    }

    return []
  }

  static async getRecentlyUpdatedGalleries(): Promise<GalleryListType> {
    try {
      const resp = await this.client.get('/gallery/recently_updated')
      return resp.data
    } catch (error) {
      ErrorService.notify(error)
      return []
    }
  }

  static async getGalleryInfo(option_id: number) {
    try {
      return (await this.client.get(`/gallery/${option_id}`)).data
    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to get gallery info', error)
      return null
    }
  }

  /** Delete photo/video by id. Returns true iff successful, false otherwise */
  static async deleteGalleryItem(activityId, optionId, mediaId): Promise<boolean> {
    try {
      let url = `/activities/${activityId}/media/${optionId}?mediaId=${mediaId}`
      const resp = await RallieAPI.client.delete(url)

      return resp.status === 204
    } catch (error) {
      ErrorService.notify(error)
      
      if (error.response.status === 401) {
        AlertService.alert("Unauthorized", "You can not delete someone else's uploaded content.")
      }
      return false
    }
  }

  /** True iff upload successful, false otherwise. */
  static async uploadToGallery(activityId: number, optionId: number, toUpload: ImagePickerAsset[], onProgressUpdate?: (ev: MediaUploadProgress) => void): Promise<boolean> {
    Logger.log("Triggered upload to Gallery:", { activityId, optionId }, "with num items:", toUpload.length)

    try {
      let url = `/activities/${activityId}/media/${optionId}/upload`
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      let success = true

      let numUploaded = 0

      if (IS_WEB) {
        success = await this._uploadFromWeb(url, headers, toUpload)
      } else {
        // Upload one at a time to prevent crashes
        for (const item of toUpload) {
          Logger.log("Upload", item.uri)
          
          const resp = await FileSystem.uploadAsync(
            API_URL + "/api" + url,
            item.uri,
            {
              fieldName: "file_0",
              httpMethod: "POST",
              uploadType: FileSystem.FileSystemUploadType.MULTIPART,
              headers: {
                cookie: this.authCookie
              },
            }
          )

          numUploaded++
          onProgressUpdate && onProgressUpdate({ uploaded: numUploaded, total: toUpload.length })
          Logger.log("Finished uploading file", item.uri, "response: ", resp)
        }
      }
  
      return success

    } catch (error) {
      ErrorService.notify(error)
      Logger.error('Failed to upload', error)
      return false
    }
  }

  private static async _uploadFromWeb(url: string, headers: any, toUpload: ImagePickerAsset[]) {
    const formData = this.formDataFor(toUpload)

    headers['Content-Transfer-Encoding'] = 'base64'
    
    const resp = await RallieAPI.client.post(
      url, 
      formData, 
      { 
        headers,
        onUploadProgress: function() {}
      }
    )

    return resp.status === 200
  }
}
