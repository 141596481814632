import FeatherIcons from '@expo/vector-icons/Feather';
import DateTimePicker from '@react-native-community/datetimepicker';
import { Button, Column, Input, Row, Select, Spacer, Text, View, useColorModeValue } from "native-base";
import { useState } from 'react';
import { AdminActivityInfo } from "../../../types/schema/activity_info.schema";
import BetterFuckingCheckbox from '../../shared/BetterFuckingCheckbox';
import MarkdownViewer from '../../shared/MarkdownViewer';
import { IS_ANDROID, IS_IOS } from '../../../utilities/constants';
import Logger from '../../../utilities/Logger';
import { MapMarkerType } from '../../../types/maps';

type PropType = {
  activity: AdminActivityInfo,
  locations: MapMarkerType[],
  onUpdate: (field: string, value: any) => any
}

const Section = ({children, style = {}}) => {
  const bg = useColorModeValue("white", "dark3")

  return (
    <View mb={4} p={3} rounded='md' shadow={2} bg={bg} {...style}>
      {children}
    </View>
  )
}

const DateTimeSelectors = ({ date, onUpdate }) => 
  <Row>
    <DateTimePicker
      is24Hour={false}
      value={date}
      mode={"date"}
      onChange={onUpdate}
    />

    <DateTimePicker
      is24Hour={false}
      value={date}
      mode={"time"}
      onChange={onUpdate}
    />
  </Row>

export default function AdminEditActivitySummary({ activity, locations, onUpdate }: PropType) {
  const [showFor, setShowFor] = useState("")
  const startDate = new Date(activity.startTime)
  const endDate = new Date(activity.endTime)
  
  const [showDateSelector, setDateSelector] = useState(false)
  const [showTimeSelector, setTimeSelector] = useState(false)

  const [editDescription, setEditDescription] = useState(false)

  const chooseStartDate = () => {
    setDateSelector(true)
    setShowFor("start")
  }

  const chooseEndDate = () => {
    setDateSelector(true)
    setShowFor("end")
  }

  const onDateChange = (event, date: Date) => {
    Logger.log("Date change", {showFor, date})

    if(showFor === "start") {
      onUpdate('startTime', date)
    }
    if(showFor === "end") onUpdate('endTime', date)

    setDateSelector(false)
    setTimeSelector(true)
  }

  const onTimeChange = (event, date: Date) => {
    if(showFor === "start") onUpdate('startTime', date)
    if(showFor === "end") onUpdate('endTime', date)

    setTimeSelector(false)
  }

  const updateActivityLocation = (id: string) => {
    const locId = parseInt(id)

    const location = locations.find(loc => loc.id === locId)
    onUpdate("location", location)
  }

  const descriptionIcon = editDescription ? 'eye' : 'edit-2'
  const fontColor = useColorModeValue("black", "white")

  return (
    <Column py={2} px={3}>
      <Section>
        <Text bold mb={2}>Metadata</Text>
        <View mb={2}>
          <BetterFuckingCheckbox 
            size={26} 
            checked={activity.hidden} 
            onPress={() => onUpdate('hidden', !activity.hidden)}>
              Hidden
          </BetterFuckingCheckbox>

          <BetterFuckingCheckbox 
            size={26} 
            checked={activity.allowCheckIn} 
            onPress={() => onUpdate('allowCheckIn', !activity.allowCheckIn)}>
              Allow Check-in
          </BetterFuckingCheckbox>
        </View>
        <Select defaultValue={activity.type} onValueChange={(type) => onUpdate('type', type)} placeholder="Choose Type">
          <Select.Item label="Sign Up" value="sign_up" />
          <Select.Item label="Assigned" value="assigned" />
          <Select.Item label="Info" value="info" />
        </Select>

      </Section>

      <Section>
        <View mb={4}>
          <Text bold mb={2}>Summary</Text>
          <Input 
            mb={2}
            value={activity.title} 
            onChangeText={(val) => onUpdate('title', val)} 
            placeholder="Activity title*" 
          />
          
          <Row mt={3} mb={2} alignItems="center">
            <Text bold>Description</Text>
            <Spacer />
            <Button 
              size="sm"
              variant="outline"
              shadow="none"
              minHeight={5}
              leftIcon={<FeatherIcons size={14} name={descriptionIcon} />} 
              onPress={() => setEditDescription(!editDescription)} 
            >
              <Text>{ editDescription ? 'Preview' : 'Edit' }</Text>
            </Button>
          </Row>
          <View position="relative" px={editDescription ? 0 : 2} borderWidth={1} borderRadius={7} borderColor="gray.300">
            {
              editDescription ? 
              <Input 
                value={activity.description} 
                onChangeText={(val) => onUpdate('description', val)} 
                multiline numberOfLines={8} 
                placeholder="Activity description" 
              />
              :
              <MarkdownViewer>
                { activity.description }
              </MarkdownViewer>            
            }
          </View>
        </View>

        <Column mb={4}>
          <Text bold mb={2}>Activity Location (optional)</Text>
          <Select defaultValue={activity.location?.id.toString()} onValueChange={updateActivityLocation} placeholder="Location">
            <Select.Item label="No location" value={null} />
            {
              locations.map(location => 
                <Select.Item label={`(ID: ${location.id}): ${location.title || "No title"}`} value={location.id.toString()} />
              )
            }
          </Select>
        </Column>

        <Column>
          <Column flexGrow={1}>
            <Text ml={1} bold>Start at*</Text>
            {
              IS_IOS ?
              <DateTimeSelectors date={startDate} onUpdate={(_, date) => onUpdate('startTime', date)} />
              :
              <Button px={0} variant="outline" mx={1} shadow="none" onPress={chooseStartDate}>
                <Text>{startDate.toLocaleDateString()}, {startDate.toLocaleTimeString()}</Text>
              </Button>
            }

          </Column>

          <Column mt={2} flexGrow={1}>
            <Text ml={1} bold>End at*</Text>
            {
              IS_IOS ?
              <DateTimeSelectors date={endDate} onUpdate={(_, date) => onUpdate('endTime', date)} />
              :
              <Button px={0} variant="outline" mx={1} shadow="none" onPress={chooseEndDate}>
                <Text>{endDate.toLocaleDateString()}, {endDate.toLocaleTimeString()}</Text>
              </Button>
            }
          </Column>
        </Column>
      </Section>

      {/* On android, these show up as modals */}
      {
        (showDateSelector && IS_ANDROID) &&
        <DateTimePicker
          is24Hour={false}
          value={showFor === "start" ? startDate : endDate}
          mode={"date"}
          onChange={onDateChange}
        />
      }

      {
        (showTimeSelector && IS_ANDROID) &&
        <DateTimePicker
          is24Hour={false}
          value={showFor === "start" ? startDate : endDate}
          mode={"time"}
          onChange={onTimeChange}
        />
      }
    </Column>
  );
}
