import { HStack, Text, useColorModeValue } from "native-base";
import FeatherIcons from '@expo/vector-icons/Feather';
import { ActivityLocation } from "../../types/schema/activities.schema";
import { Linking, TouchableOpacity } from "react-native";
import { openExternalMap, openInternalMap } from "../../helpers/map.helpers";
import Logger from "../../utilities/Logger";
import ErrorService from "../../services/ErrorService";
import { useNavigation } from "@react-navigation/native";

type PropType = {
  location: ActivityLocation, 
  color?: string, 
  fontSize?: number | string,
  allowPress?: boolean, 
  styleProps?: any
}

export default function LocationInfo(props: PropType) {
  let { color, location, allowPress, fontSize } = props
  allowPress = allowPress === undefined ? true : allowPress

  const navigation = useNavigation()

  const locationPresent = !!location.url || (location.lat && location.long)

  const handlePress = () => {
    if (!locationPresent) { return }

    // if (location.url && allowPress && location.url.startsWith("http")) {
    //   Linking.openURL(location.url).catch(ErrorService.notify)
    // }
    if(location.lat && location.long) {
      openInternalMap(location, navigation)
      // openExternalMap(location.lat, location.long, location.title)
    } else if (location.url && allowPress && location.url.startsWith("http")) {
      Linking.openURL(location.url).catch(ErrorService.notify)
    }
  }

  const iconColor = useColorModeValue("gray", "white")
  const fontColor = useColorModeValue("gray.500", "gray.300")

  return(
    <TouchableOpacity onPress={handlePress} activeOpacity={allowPress ? 0.2 : 1}>
      <HStack alignItems='center'>
        <FeatherIcons name='map-pin' color={color || iconColor} />
        <Text mx='1' fontSize={fontSize || "sm"} color={color || fontColor} textDecorationLine={ locationPresent && 'underline' }>{ location.title || 'No title' }</Text>

        { locationPresent && allowPress && <FeatherIcons name='external-link' color={color || iconColor} /> }
      </HStack>
    </TouchableOpacity>
  )
}