import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { useNavigation } from "@react-navigation/native";
import { Column, Button, Image, Text, Spinner, Input, Row, Divider } from "native-base";
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/userInfoSlice";
import { AUTH_CLIENT_IDS, IS_DEV, IS_NATIVE, IS_WEB } from '../../utilities/constants';
import Logger from "../../utilities/Logger";
import RallieAPI from "../../utilities/RallieAPI";
import GoogleSignInButton from './GoogleSignInButton';
import ErrorService from '../../services/ErrorService';
import SnackbarService from '../../services/SnackbarService';
import StorageManager from '../../services/StorageManager';

type PropType = {
  style?: any
}

WebBrowser.maybeCompleteAuthSession();


export default function LoginForms(props: PropType) {
  const style = props.style || {}

  const dispatch = useDispatch()
  const navigation = useNavigation()
  
  const authReqOptions = {
    ...AUTH_CLIENT_IDS,

    selectAccount: true,
    shouldAutoExchangeCode: false,
    responseType: 'id_token'
  }

  if (IS_NATIVE) {
    // On native apps the token is given by default, and we 
    // need to exchange the given code for it.

    authReqOptions.shouldAutoExchangeCode = true
    delete authReqOptions.responseType
  }

  const [request, response, promptAsync] = Google.useAuthRequest(authReqOptions);

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [guestLogin, setGuestLogin] = useState(false)

  const [loading, setLoading] = useState(false)

  const authenticateWithServer = async (idToken: string) => {
    try {
      const userData = await RallieAPI.authenticateWithServer(idToken)
      if (userData) {
        dispatch(setUserInfo(userData))

        // Is user logging in for the first time?
        let firstLogin = await StorageManager.getItem("FIRST_LOGIN")

        if ((firstLogin == null || firstLogin.length === 0) && !IS_WEB) {
          navigation.replace('Onboarding')
        } else {
          navigation.replace('Home')
        }
      }
      else {
        // TODO: Show error
        
      }
    } catch (error) {
      ErrorService.notify(error)
      Logger.log('Failed to authenticate with server', error)
    } finally {
      setLoading(false)
    }
  }

  const signInTestUser = async () => {
    let res = await RallieAPI.authenticateWithServer('', true)
    dispatch(setUserInfo(res))
    navigation.replace('Home')
  }

  const signInGuest = async () => {
    let res = await RallieAPI.guestLogin(username, password)
    if (res) {
      dispatch(setUserInfo(res))
      navigation.replace('Home')
    } else {
      SnackbarService.error('Failed to login. Make sure username/password is correct.')
    }
  }

  useEffect(() => {    
    if (response?.type === 'success') {
      const { params } = response;
      authenticateWithServer(params.id_token)
    }
    else {
      // TODO: Show error message. 
      setLoading(false)
    }
  }, [response]);

  return (
    <Column { ...style }>
      { !guestLogin && 
        <Column>
          <GoogleSignInButton loading={loading} onPress={() => { setLoading(true); promptAsync()}} /> 

          <Button mt={6} minHeight="5px" shadow="none" variant="ghost" onPress={() => setGuestLogin(true)}>
            <Text color="gray.500">Sign in as a Guest</Text>
          </Button>
        </Column>
      }
      { guestLogin && 
        <Column width="3/4" alignSelf="center">
          <Input placeholder="Email" value={username} onChangeText={setUsername} mb={4} />
          <Input placeholder="Password" type="password" value={password} onChangeText={setPassword} />
          <Row alignSelf="center" width="full" mt={4} justifyContent='space-between'>
            <Button mr={2} flexGrow={1} variant="outline" shadow="none" onPress={() => setGuestLogin(false)}>
              <Text color="gray.500">Back</Text>
            </Button>
            <Button ml={2} flexGrow={1} onPress={signInGuest}>
              <Text color="white">Login</Text>
            </Button>
          </Row>
        </Column>
      }      

      { IS_DEV && <Button mt={4} onPress={signInTestUser}>Sign in Test User (DEV-ONLY)</Button> }
    </Column>
  );
}