import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MapType } from 'react-native-maps'
import { MapMarkerType } from '../types/maps'

export interface MapStoreState {
  markers: MapMarkerType[],
  temporaryMarker: MapMarkerType,

  mapType: MapType,
  showResortMap: boolean,
  newMarkerActionSheetOpen: boolean
}

const initialState: MapStoreState = {
  markers: [],
  temporaryMarker: null,
  mapType: "satellite",
  showResortMap: false,
  newMarkerActionSheetOpen: false
}

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMarkers: (state, action: PayloadAction<MapMarkerType[]>) => {
      state.markers = action.payload
    },
    setTemporaryMarker: (state, action: PayloadAction<MapMarkerType>) => {
      state.temporaryMarker = action.payload
    },
    setMapType: (state, action: PayloadAction<MapType>) => {
      state.mapType = action.payload
    },
    setShowResortMap: (state, action: PayloadAction<boolean>) => {
      state.showResortMap = action.payload
    },
    setNewMarkerActionSheetOpen: (state, action: PayloadAction<boolean>) => {
      state.newMarkerActionSheetOpen = action.payload
    },
  }
})


export const { setMarkers, setTemporaryMarker, setMapType, setShowResortMap, setNewMarkerActionSheetOpen } = mapSlice.actions
export default mapSlice.reducer
