import MasonryList from '@react-native-seoul/masonry-list';
import { Text, View, useColorModeValue } from "native-base";
import { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import SnackbarService from "../../services/SnackbarService";
import { RootState } from "../../store";
import { Activity } from "../../types/schema/activities.schema";
import { ActivityOption } from "../../types/schema/activity_info.schema";
import { UserUploadedMedia } from "../../types/schema/media.schema";
import Logger from '../../utilities/Logger';
import GalleryAPI from "../../utilities/api_slices/gallery.api";
import { IS_IOS } from '../../utilities/constants';
import { GalleryItem } from "./GalleryItem";
import ErrorService from '../../services/ErrorService';
import { galleryModified } from '../../redux/appEventsSlice';

type PropType = {
  activity: Activity,
  option: ActivityOption,
  refresh: boolean
}

/** Masonry list of all images in the Gallery. */
export default function ImageGalleryViewer(props: PropType) {
  const { activity, option, refresh } = props

  const dispatch = useDispatch()
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const { width } = useWindowDimensions()

  const [loading, setLoading] = useState(true)
  const [gallery, setGallery] = useState<UserUploadedMedia[]>([])

  const page = useRef(0)
  const endReached = useRef(false)

  // Prevents race condition where user tries 
  // to scroll while we're loading images.
  const gettingMoreImages = useRef(false)

  const getMoreImages = async () => {
    if (endReached.current || gettingMoreImages.current) return

    gettingMoreImages.current = true
    setLoading(true)

    try {
      const data = await GalleryAPI.getGallery(activity.id, option.id, page.current)

      // Stop requesting more
      if (data.length === 0) { endReached.current = true }
      page.current += 1
  
      if (page.current === 1) 
        setGallery(data)
      else
        setGallery([ ...gallery, ...data ])
    } catch (error) {
      ErrorService.notify(error)

      Logger.error('get more images', error)
      SnackbarService.error('[Error] Failed to load more content. Please try again later.')
    } finally {
      gettingMoreImages.current = false
      setLoading(false)
    }    
  }

  const reset = () => {
    page.current = 0
    endReached.current = false
    
    getMoreImages()
  }

  useEffect(() => {
    console.log('changed, resetting')
    reset()
  }, [option])

  useEffect(() => {
    if (refresh) {
      Logger.log("Resetting gallery")
      reset()
    }
  }, [refresh])

  const handleDelete = async (mediaId: number) => {
    const success = await GalleryAPI.deleteGalleryItem(activity.id, option.id, mediaId)
    if (!success) {
      SnackbarService.error('Failed to delete photo with id: ' + mediaId )
    }
    else {
      // Instead of nuking everything and doing a hard reset, 
      // we can filter to simulate an immediate refresh.
      setGallery(gallery.filter(media => media.id !== mediaId))
      dispatch(galleryModified(null))
    }
  }
  
  const bg = useColorModeValue("lace", "dark0")

  return (
    <View flex={1} bg={bg}>
      { !loading && gallery.length === 0 && <Text mt={4} fontSize='sm' textAlign='center' color='gray.500'>Nothing yet. Be the first to upload!</Text> }
      <MasonryList 
        data={gallery}
        renderItem={({item}: { item: UserUploadedMedia }) => {
          const allowDelete = userInfo.id === item.uploaded_by_id || userInfo.is_admin

          return (
            <GalleryItem source={item} allSources={gallery} width={width / 3.1} height={250} getMoreImages={getMoreImages} allowDelete={allowDelete} handleDelete={(id: number) => handleDelete(id)} />
          )
        }}
        numColumns={3}

        keyExtractor={(item) => item.id.toString()}

        onEndReached={getMoreImages}
        onEndReachedThreshold={IS_IOS ? 0.2 : 0.5}

        // Performance related
        removeClippedSubviews={true}
        // maxToRenderPerBatch={1}

        refreshing={loading} 
        onRefresh={reset}

        // refreshControl={<RefreshControl refreshing={loading} colors={[CustomTheme.colors.merlot]} onRefresh={reset} />}
      />
    </View>
  )
}
