import { Button, Divider, Input, Column, Row, Heading, Text, Select, useColorModeValue, ScrollView, } from "native-base";
import { useEffect, useState } from "react";
import { Keyboard } from "react-native";
import { useSelector } from "react-redux";
import SnackbarService from "../../services/SnackbarService";
import { RootState } from "../../store";
import NotificationsAPI from "../../utilities/api_slices/notifications.api";
import AlertService from "../../services/AlertService";
import Subtitle from "../shared/Subtitle";
import AdminAPI from "../../utilities/api_slices/admin.api";
import { ActivityOption, AdminActivityInfoOption, AdminActivitySummary } from "../../types/schema/activity_info.schema";
import { useInput } from "../../hooks/forms";

export default function PushNotifications({ ...rootProps }) {
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const url = useInput("")

  const [activityId, setActivityId] = useState<number>(null)
  const [optionId, setOptionId] = useState<number>(null)

  const [activities, setActivities] = useState<AdminActivitySummary[]>([])
  const [options, setOptions] = useState<ActivityOption[] & AdminActivityInfoOption[]>([])

  const validData = !(body.trim().length === 0 && title.trim().length === 0)

  const clearInputs = () => {
    setTitle('')
    setBody('')
    url.onChangeText("")
  }

  const send = async () => {
    Keyboard.dismiss()

    const doSend = async () => {
      let success = await NotificationsAPI.sendNotificationToAllUsers({ title, body })
      if (success) {
        clearInputs()
        SnackbarService.log("Sent to all users.")
      }
      else {
        SnackbarService.error('Failed to send notifications to everyone. Please try again later.')
      }
    }

    if (!validData) {
      SnackbarService.warn("A notification title OR body is required!")
      return
    }

    AlertService.confirm(
      "Are you sure you want to notify everyone?", 
      "",
      [
        { text: "Cancel", style: "cancel" },
        { text: "Send", style: "default", onPress: doSend }
      ]
    )
  }

  const sendToMe = async () => {
    Keyboard.dismiss()

    if (!validData) {
      SnackbarService.warn("A notification title or body is required!")
      return
    }

    let success = await NotificationsAPI.sendToUsers([userInfo?.id], { title, body, activityId, optionId, url: url.value || null })
    if (success) {
      SnackbarService.log(`Sent notification to this device.`)
    }
    else {
      SnackbarService.error('Failed to send notification to this device. Please try again later.')
    }
  }

  const sendToSelectedUsers = async () => {
    Keyboard.dismiss()

    if (!validData || !activityId) {
      SnackbarService.warn("A notification title or body, and activity is required!")
      return
    }

    const success = await NotificationsAPI.sendToActivityOrOption(title, body, url.value, activityId, optionId)
    if (success) {
      clearInputs()
      SnackbarService.log(`Sent notification to specific users.`)
    }
    else {
      SnackbarService.error('Failed to send notification. Please try again later.')
    }
  }

  const selectedActivity = async (id: string) => {
    setOptionId(null)

    const newId = parseInt(id)
    setActivityId(newId)

    setOptions((await AdminAPI.activityDetails(newId)).options)
    // Get list of options for this activity
  }

  // Populate list of activities
  useEffect(() => {
    async function run() {
      setActivities(await AdminAPI.getAllActivities())
    }

    run()
  }, [])

  const bg = useColorModeValue("lace", "dark0")

  return (
    <ScrollView bg={bg}>
      <Column p={3} pt={5} height="full">
        <Heading size="md">Send Push Notifications</Heading>
        <Subtitle fontSize={14} mb={4}>Notifications can sometimes take a few minute to deliver.</Subtitle>

        <Input mb={2} placeholder="Message title" value={title} onChange={(ev) => setTitle(ev.nativeEvent.text)} clearButtonMode="always" />
        <Input placeholder="Message body" value={body} onChange={(ev) => setBody(ev.nativeEvent.text)} clearButtonMode="always" />

        <Divider my={2} />

        <Column>
          <Select 
            placeholder="Activity (optional)" 
            selectedValue={activityId?.toString()} onValueChange={selectedActivity}
          >
            <Select.Item label="unselected" value={null} />
            {
              activities.map(activity => 
                <Select.Item 
                  label={`[ID: ${activity.id}] [users: ${activity.type === "info" ? "Everyone" : activity.numAttending}]: ${activity.title}`} 
                  value={activity.id.toString()} 
                />
              )
            }
          </Select>

          <Select
            mt={2} 
            mb={4}
            placeholder="Activity Option (optional)" 
            isDisabled={options.length === 0}
            selectedValue={optionId?.toString()} onValueChange={(val) => setOptionId(parseInt(val))}
          >
            <Select.Item label="unselected" value={null} />
            {
              options.map(option => 
                <Select.Item label={`[ID: ${option.id}]: ${option.title}`} value={option.id.toString()} />
              )
            }
          </Select>

          <Input mb={2} {...url} placeholder="URL (optional)" autoCapitalize="none" clearButtonMode="always" />
          <Subtitle px={1}>If no URL is provided, the notification will be linked to the activity chosen.</Subtitle>
        </Column>

        <Row my={4} width="100%" justifyContent='center'>
          <Button flexGrow={1} onPress={sendToMe} mr={2}>
            <Text color="white">Send to Me</Text>
          </Button>

          <Button flexGrow={1} onPress={sendToSelectedUsers}>
            <Text color="white">Send to selected users</Text>
          </Button>
        </Row>

        <Button onPress={send}>
          <Text color="white">Send to Everyone</Text>
        </Button>
      </Column>
    </ScrollView>
  );
}
