import FeatherIcons from '@expo/vector-icons/Feather';
import { Button, Column, Heading, Row, Spacer, Text } from "native-base";
import Subtitle from '../shared/Subtitle';
import { Overlay } from "./Overlay";

export function Welcome({ next }) {
  return (
    <Overlay>
      <Column>
        <Heading mb={4} color="lace" fontSize="3xl">Welcome to Rallie!</Heading>
        <Text color="lace" fontSize="lg">We're almost ready to launch your adventure! 🚀</Text>
      </Column>
      
      <Button 
        position="absolute"
        bottom="22.5%" 
        alignSelf="center"
        width="full"

        onPress={next} 
        rounded="full" 
        
        background="merlotTint" 
        bg="merlot" 
      >
          <Text color="white">Continue</Text>
        </Button>

    </Overlay>
  );
}
