import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import FeatherIcons from '@expo/vector-icons/Feather';
import MapScreen from "./home_screens/MapScreen";
import ScheduleScreen from "./home_screens/ScheduleScreen";
import { Button, Column, Text, View, useColorModeValue } from 'native-base'
import * as Notifications from 'expo-notifications';
import { useContext, useEffect, useRef } from "react";
import Dashboard from "./DashboardScreen";
import { CustomTheme } from "../utilities/theme";
import RallieAPI from "../utilities/RallieAPI";
import PushNotificationService from "../services/PushNotificationService";
import Logger from "../utilities/Logger";
import SocialScreen from "./SocialsScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { Linking } from "react-native";
import { setLatestDeepLink } from "../redux/deepLinkSlice";
import { isChatUnread } from "../utilities/chat";
import ActionCableService from "../services/ActionCableService";
import AdminScreen from "./AdminScreen";
import { setAdminEnabled } from "../redux/adminSlice";
import GalleryList from "./social_screen/GalleryList";
import { IS_WEB } from "../utilities/constants";
import { isDarkMode } from "../hooks/isDarkMode";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { AnimatedView } from "./animated/AnimatedNativeBase";
import { FadeInDown, FadeInLeft, FadeInRight } from "react-native-reanimated";
import ActivityDetails from "./ActivityDetails";

const Tab = createBottomTabNavigator();

export default function HomeScreen({ navigation }) {
  const routeToIconNameMapping = {
    Schedule: 'calendar',
    Map: 'map',
    AllEvents: 'activity',
    Socials: 'message-circle',
    Admin: "user",
    Dashboard: 'home',
    GalleryList: "image"
  }

  const routeNameMapping = {
    Dashboard: "Home",
    Schedule: "Events",
    GalleryList: "Gallery",
    Map: "Map",
    Admin: "Admin"
  }

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const lastDeepLink = useSelector((state: RootState) => state.deepLinks.lastDeepLink)
  // const numUnReads = useSelector((state: RootState) => {
  //   let count = 0;
  //   let allConvos = state.conversations.availablsChats
  //   for(const id in allConvos) {
  //     let chat = allConvos[id]
  //     if(isChatUnread(chat, userInfo?.id)) count++;
  //   }

  //   return count
  // })

  // At this point, user is authenticated and 
  // likely has internet access
  const dispatch = useDispatch()

  const loginInit = async () => {
    const token = await PushNotificationService.setup()
    await RallieAPI.updateNotificationToken(token || '')
  }

  useEffect(() => {
    loginInit()

    // Rare case when FCM token is updated
    const fcmSubscription = Notifications.addPushTokenListener((event) => RallieAPI.updateNotificationToken(event.data));

    return () => {
      fcmSubscription.remove()
    };
  }, [])

  // Deep Linking
  useEffect(() => {
    if (!lastDeepLink) return

    async function run() {
      Logger.log('[Home] Deep link:', lastDeepLink)
      Linking.openURL(lastDeepLink)


      await ActionCableService.init()
      dispatch(setLatestDeepLink(null)) 
    }

    run()
  }, [lastDeepLink])

  const darkMode = isDarkMode()
  const tabBarBg = useColorModeValue("white", CustomTheme.colors.dark4)

  return (
    // See: https://reactnavigation.org/docs/screen-options/#screenoptions-prop-on-the-navigator
    <Tab.Navigator
      initialRouteName="Dashboard"
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          
          return (
            <AnimatedView entering={FadeInDown.delay(750).duration(750)}>
              <Column px={1} py={2} bg={focused ? "tangerine" : undefined} minWidth="75px" rounded="3xl" width="full" justifyContent="center" alignItems="center">
                <FeatherIcons name={routeToIconNameMapping[route.name] || 'alert-triangle'} color={color} size={size} />
                <Text mt={1} fontSize="xs" color={color}>{routeNameMapping[route.name]}</Text>
              </Column>
            </AnimatedView>
          )
        },
        tabBarActiveTintColor: 'white',
        // tabBarActiveBackgroundColor: CustomTheme.colors.tangerine,
        
        headerTintColor: 'white',
        headerStyle: {
          backgroundColor: CustomTheme.colors.slate,
          height: 50
        },
        tabBarStyle: {
          backgroundColor: tabBarBg,
          height: "12%",
          paddingHorizontal: 16,
          paddingTop: 5
        },
        tabBarShowLabel: false
      })}
    >
      <Tab.Screen name='Dashboard' component={Dashboard} options={{ headerShown: false }} />
      <Tab.Screen name='Schedule' component={ScheduleScreen} options={{ headerShown: false }} />
      <Tab.Screen name='GalleryList' component={GalleryList} options={{ headerShown: false }} />
      <Tab.Screen name='Map' options={{ headerShown: false }} component={MapScreen} />

      {/* <Tab.Screen 
        options={{ tabBarBadge: numUnReads || null, headerShown: false, lazy: false }} 
        name='Socials' 
        component={SocialScreen} 
      /> */}

      {
        userInfo?.is_admin && !IS_WEB &&
        <Tab.Screen 
          name='Admin' 
          options={{
            title: 'Me', 
            headerShown: false,
          }} 
          component={AdminScreen} 
        />
      }
    </Tab.Navigator>
  )
}
