import Logger from "../Logger";
import RallieAPI from "../RallieAPI";
import ErrorService from "../../services/ErrorService";
import { AdminActivityInfo, AdminActivityOptionUser, AdminActivitySummary } from "../../types/schema/activity_info.schema";
import { MapMarkerType } from "../../types/maps";

export default class AdminAPI extends RallieAPI {
  static async getAllActivities(): Promise<AdminActivitySummary[]> {
    try {
      return (await this.client.get('/activities?showAll=true')).data
    } catch (error) {
      ErrorService.notify(error)
      return []
    }
  }

  static async getAllUsers(): Promise<AdminActivityOptionUser[]> {
    try {
      const res = (await this.client.get('/users')).data
      res.sort((a, b) => a.name > b.name ? 1 : -1)
      return res
    } catch (error) {
      ErrorService.notify(error)
      return []
    }
  }

  static async activityDetails(activity_id: number): Promise<AdminActivityInfo> {
    try {
      return (await this.client.get(`activities/${activity_id}?for_edit=true`)).data
    } catch (error) {
      ErrorService.notify(error)
    }
  }

  static async allLocations(): Promise<MapMarkerType[]> {
    try {
      return (await this.client.get("maps/")).data
    } catch (error) {
      ErrorService.notify(error)
    }
  }

  static async saveActivity(activity: AdminActivityInfo): Promise<boolean> {
    try {
      let url = `/activities`,
      method = 'POST'

      if (!!activity.id) {
        method = 'PATCH'
        url = `${url}/${activity.id}`
      }

      let resp = await this.client.request({
        url,
        method,
        data: {
          ...activity
        }
      })

      return resp.status === 200
    } catch (error) {
      ErrorService.notify(error)
      return false
    }
  }

  /** True iff delete successful */
  static async deleteActivity(id: number): Promise<boolean> {
    try {
      const resp = await this.client.delete(`/activities/` + id)

      return resp.status === 200
    } catch (error) {
      ErrorService.notify(error)
      return false
    }
  }
}
