import { ColorMode, StorageManager as SM, extendTheme } from "native-base";
import * as Poppins from '@expo-google-fonts/poppins'
import StorageManager from "../services/StorageManager";
import ErrorService from "../services/ErrorService";

export const ColorModeManager: SM = {
  get: async () => {
    try {
      const val = await StorageManager.getItem("DARK_MODE") || ""
      return val === "dark" ? "dark" : "light"

    } catch (error) {
      ErrorService.notify(error)
      return "light"
    }
  },
  set: async (value: ColorMode) => {
    try {
      await StorageManager.setItem("DARK_MODE", value)

    } catch (error) {
      ErrorService.notify(error)
    }
  }
}

export const CustomFonts = {
  'Poppins_Thin': Poppins.Poppins_100Thin,
  'Poppins_ThinItalic': Poppins.Poppins_100Thin_Italic,

  'Poppins_ExtraLight': Poppins.Poppins_200ExtraLight,
  'Poppins_ExtraLightItalic': Poppins.Poppins_200ExtraLight_Italic,

  'Poppins_Light': Poppins.Poppins_300Light,
  'Poppins_LightItalic': Poppins.Poppins_300Light_Italic,

  'Poppins_Regular': Poppins.Poppins_400Regular,
  'Poppins_RegularItalic': Poppins.Poppins_400Regular_Italic,

  'Poppins_Medium': Poppins.Poppins_500Medium,
  'Poppins_MediumItalic': Poppins.Poppins_500Medium_Italic,

  'Poppins_SemiBold': Poppins.Poppins_600SemiBold,
  'Poppins_SemiBoldItalic': Poppins.Poppins_600SemiBold_Italic,

  'Poppins_Bold': Poppins.Poppins_700Bold,
  'Popping_BoldItalic': Poppins.Poppins_700Bold_Italic,

  'Poppins_ExtraBold': Poppins.Poppins_800ExtraBold,
  'Poppins_ExtraBoldItalic': Poppins.Poppins_800ExtraBold_Italic,

  'Poppins_Black': Poppins.Poppins_900Black,
  'Poppins_BlackItalic': Poppins.Poppins_900Black_Italic
}

export const CustomTheme = extendTheme({
  config: {
    initialColorMode: "light"
  },
  components: {
    Button: {
      defaultProps: {
        rounded: "md",
        shadow: 1,
        minHeight: "55px",
        colorScheme: "info"
      }
    },
    Text: {
      defaultProps: {
        fontSize: 16,
      }
    },
    Input: {
      defaultProps: {
        minHeight: "50px"
      }
    }
  },
  fontConfig: {
    Poppins: {
      100: {
        normal: 'Poppins_Thin',
        italic: 'Poppins_ThinItalic'
      },
      200: {
        normal: 'Poppins_ExtraLight',
        italic: 'Poppins_ExtraLightItalic'
      },
      300: {
        normal: 'Poppins_Light',
        italic: 'Poppins_LightItalic'
      },
      400: {
        normal: 'Poppins_Regular',
        italic: 'Poppins_RegularItalic'
      },
      500: {
        normal: 'Poppins_Medium',
        italic: 'Poppins_MediumItalic'
      },
      600: {
        normal: 'Poppins_SemiBold',
        italic: 'Poppins_SemiBoldItalic'
      },
      700: {
        normal: 'Poppins_Bold',
        italic: 'Popping_BoldItalic'
      },
      800: {
        normal: 'Poppins_ExtraBold',
        italic: 'Poppins_ExtraBoldItalic'
      },
      900: {
        normal: 'Poppins_Black',
        italic: 'Poppins_BlackItalic'
      },
    }
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    mono: 'Poppins'
  },
  colors: {
    jet: '#282828',
    merlot: '#8D335B',
    merlotTint: "#a35b7b",
    slate: '#3F5167',
    lace: '#F2F4F3',
    tangerine: '#FF8C21',
    error: '#d32f2e',


    // Dark surface, stolen from: https://m2.material.io/design/color/dark-theme.html#properties
    dark0: "rgb(19, 19, 19)",
    dark1: "rgb(27, 27, 27)",
    dark2: "rgb(30, 30, 30)",
    dark3: "rgb(33, 33, 33)",
    dark4: "rgb(34, 34, 34)",
    dark6: "rgb(39, 39, 39)",
    dark8: "rgb(40, 40, 40)",
    dark12: "rgb(44, 44, 44)",
    dark16: "rgb(46, 46, 46)",
    dark24: "rgb(48, 48, 48)",
  },
})