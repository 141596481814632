import FeatherIcons from '@expo/vector-icons/Feather';
import { Column, Row, IconButton, Button, ScrollView, Spinner, Text, Spacer, useColorModeValue } from "native-base";
import { useEffect, useState } from "react";
import { Alert, SafeAreaView } from "react-native";
import SnackbarService from '../../services/SnackbarService';
import { AdminActivityInfo, AdminActivityOptionUser } from "../../types/schema/activity_info.schema";
import AdminAPI from "../../utilities/api_slices/admin.api";
import AdminEditActivityOptions from './activity_edit_screen/AdminEditActivityOptions';
import AdminEditActivitySummary from "./activity_edit_screen/AdminEditActivitySummary";
import AlertService from '../../services/AlertService';
import { CustomTheme } from '../../utilities/theme';
import Logger from '../../utilities/Logger';
import { MapMarkerType } from '../../types/maps';

export default function ActivityEditScreen({ navigation, route }) {
  const { activity_id } = route.params
  const [activity, setActivity] = useState<AdminActivityInfo>()
  const [userList, setUserList] = useState<AdminActivityOptionUser[]>()
  const [locations, setLocations] = useState<MapMarkerType[]>([])

  const [saving, setSaving] = useState(false)
  
  const getActivityDetails = async () => {
    setActivity(await AdminAPI.activityDetails(activity_id))
    setLocations(await AdminAPI.allLocations())
  }

  const getUserList = async () => {
    setUserList(await AdminAPI.getAllUsers())
  }

  const reset = async () => {
    setActivity(null)
    setUserList(null)

    await getUserList()
    await getActivityDetails()
  }

  useEffect(() => { getUserList() }, [])
  useEffect(() => { getActivityDetails() }, [activity_id])

  const walkToField = (field, obj) => {
    let segments = field.split(".")
    let lastSegment = segments[segments.length - 1]

    let to_update = obj
    for(let i = 0; i < segments.length - 1; i++) 
      to_update = to_update[segments[i]]

    return [lastSegment, to_update]
  }

  const updateActivity = (field: string, value: any) => {
    let new_activity = { ...activity }

    // Can give nested attributes, such as "location.url" or "w00t.b.another.c"
    let [lastSegment, to_update] = walkToField(field, new_activity)
    to_update[lastSegment] = value

    setActivity(new_activity)
  }

  const handleOptionUpdate = (id: number, field: string, value: any) => {
    let new_activity = { ...activity }
    let option = new_activity.options.find(e => e.id === id)

    let [lastSegment, to_update] = walkToField(field, option)

    to_update[lastSegment] = value
    setActivity(new_activity)
  }

  const handleNewOption = () => {
    let new_activity = { ...activity }
    new_activity.options.unshift({
      id: Math.random(),
      title: '',
      description: '',
      limit: 0,
      url: '',
      users: [],
      location: {
        id: Math.random(),
        title: '',
        link: '',
        created_at: new Date().toString(),
        updated_at: new Date().toString()
      }
    } as any)

    setActivity(new_activity)
  }

  const handleOptionDelete = (id) => {
    let new_activity = { ...activity }
    new_activity.options = new_activity.options.filter(e => e.id !== id) as any

    setActivity(new_activity)
    console.log('deleting option', id)
  }

  const saveActivity = async () => {
    async function save() {
      setSaving(true)
      let success = await AdminAPI.saveActivity(activity)
      if (success) SnackbarService.log('Saved "' + activity.title + '".')
      else         SnackbarService.error('Failed to update activity with id: ' + activity_id)

      reset().finally(() => setSaving(false))
    }

    AlertService.alert(
      activity.title, 
      'Are you sure you want to SAVE?',
      [
        { text: 'Cancel', style: 'cancel'},
        {
          onPress: save,
          text: 'Save', style: 'default'
        }
      ]
    )
  }

  if (!activity) {
    return <Spinner color='merlot' size='lg' mt={4} />
  }

  const isInfo = activity.type === 'info'
  const bg = useColorModeValue(CustomTheme.colors.lace, CustomTheme.colors.dark0)

  return (
    <SafeAreaView style={{ backgroundColor: bg }}>
      <ScrollView>
        <Column>
        <Row px={4} py={2} mb={2} backgroundColor='slate'>
          <IconButton disabled={saving} onPress={() => navigation.goBack()} icon={<FeatherIcons size={24} color='white' name='arrow-left' />} />
          <Spacer />
          
          <Button mr={4} backgroundColor="gray.500" onPress={reset} leftIcon={<FeatherIcons color='white' name="refresh-ccw" />}>
            Reset
            </Button>
          <Button disabled={saving} isLoading={saving} onPress={saveActivity} background="merlotTint" bg="merlot">Save Activity</Button>
        </Row>

          <AdminEditActivitySummary activity={activity} locations={locations} onUpdate={updateActivity} />

          { 
          !isInfo && 
            <AdminEditActivityOptions 
              activity={activity} 
              userList={userList} 
              locations={locations}

              onNew={handleNewOption} 
              onUpdate={handleOptionUpdate} 
              onDelete={handleOptionDelete} 
            /> 
          }
        </Column>
      </ScrollView>
    </SafeAreaView>
  );
}
