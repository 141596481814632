import FeatherIcons from '@expo/vector-icons/Feather';
import { Button, Column, FlatList, Input, Modal, Row, ScrollView, Select, Spacer, Text, View, useColorModeValue } from "native-base";
import { useState } from "react";
import { ActivityOption, AdminActivityInfo, AdminActivityInfoOption, AdminActivityOptionUser } from "../../../types/schema/activity_info.schema";
import BetterFuckingCheckbox from '../../shared/BetterFuckingCheckbox';
import { MapMarkerType } from '../../../types/maps';

type PropType = {
  activity: AdminActivityInfo,
  userList: AdminActivityOptionUser[],
  locations: MapMarkerType[],

  onNew: Function,
  onUpdate: Function,
  onDelete: Function,
}

const Section = ({children, style = {}}) => {
  const bg = useColorModeValue("white", "dark3")
  return (
    <View mb={2} p={3} shadow="1" rounded='md' borderColor="gray.300" bg={bg} {...style}>
      {children}
    </View>
  )
}

export default function AdminEditActivityOptions({ activity: { options }, userList, locations, onNew, onUpdate, onDelete }: PropType) {
  return (
    <Column py={2} px={3}>
      <Row mb={2} alignItems="baseline" justifyContent="space-between">
        <Text mb={2} bold>Options</Text>
        <Button onPress={onNew} size='xs' minHeight={5} shadow="none" leftIcon={<FeatherIcons size={20} name='plus' color='white' />}>
          <Text color="white">New</Text>
        </Button>
      </Row>

      <FlatList 
        data={options}
        renderItem={({ item }) =>  <Option key={item.id} opt={item as AdminActivityInfoOption} userList={userList} locations={locations} onUpdate={onUpdate} onDelete={onDelete} />}
      />
    </Column>
  );
}

type OptionPropType = {
  opt: AdminActivityInfoOption,
  userList: AdminActivityOptionUser[],
  locations: MapMarkerType[],

  onUpdate: Function,
  onDelete: Function
}

const Option = ({ opt, userList, locations, onDelete, onUpdate }: OptionPropType) => {
  const [open, setOpen] = useState(false)
  const users = opt.users.sort((a, b) => a.name > b.name ? 1 : -1)

  const toggleUser = (user: AdminActivityOptionUser) => {
    const newUserList = [...(opt.users)]
    let exists = newUserList.findIndex(u => u.id === user.id)

    // Helpful to denote newly added users
    user.modified = !user.modified

    if (exists > -1) 
      newUserList.splice(exists, 1)
    else 
      newUserList.push(user)

    newUserList.sort((a, b) => a.name > b.name ? 1 : -1)

    onUpdate(opt.id, 'users', newUserList)
  }

  const updateOptionLocation = (id: string) => {
    const locId = parseInt(id)

    const location = locations.find(loc => loc.id === locId)
    onUpdate(opt.id, "location", location)
  }

  const selectAll = () => onUpdate(opt.id, 'users', [...userList])
  const deselectAll = () => onUpdate(opt.id, 'users', [])

  return (
    <Section key={opt.id}>
      <Column>
        {/* Title + Limit */}
        <Row mb={2}>
          <Input 
            placeholder="Title" 
            flexGrow={1} 
            value={opt.title} 
            onChangeText={(val) => onUpdate(opt.id, 'title', val)} 
          />

          <Input 
            placeholder="Limit" ml={2} width={16} 
            keyboardType="numeric" 
            value={opt.limit?.toString()} 
            onChangeText={(val) => onUpdate(opt.id, 'limit', val)} 
          />
        </Row>
        
        {/* Description */}
        <Input
          mb={2} 
          placeholder="Description" 
          numberOfLines={3} 
          value={opt.description} 
          onChangeText={(val) => onUpdate(opt.id, 'description', val)} 
        />

        {/* Location Info */}
        {/* <Select defaultValue={opt.location?.id.toString()} onValueChange={updateOptionLocation} placeholder="Location">
          <Select.Item label="No location" value={null} />
          {
            locations.map(location => 
              <Select.Item label={`(ID: ${location.id}): ${location.title || "No title"}`} value={location.id.toString()} />
            )
          }
        </Select> */}

        {/* Edit Users */}
        <Text mt={4} bold fontSize={11}>Users</Text>
        <Row flexWrap="wrap">
          {
           users.map(
            user =>  <Text bold={user.modified} fontSize={14}>{user.name}, </Text>
          ) 
          }

        </Row>
        <Button mt={2} size="xs" backgroundColor="slate" minHeight={5} shadow="none" onPress={() => setOpen(true)}>
          <Text color="white">Edit Users</Text>
        </Button>


        <Row mt={4}>
          <Spacer />
          <Button 
            size='xs' 
            backgroundColor='red.600' 
            minHeight={5}
            shadow="none"
            onPress={() => onDelete(opt.id)} 
            leftIcon={<FeatherIcons color="white" name="trash" />}>
              <Text color="white">Delete</Text>
          </Button>
        </Row>
      </Column>

      {/* Assign users modal */}
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <Modal.Content>
          <Modal.Header>{"Users • " + opt.title}</Modal.Header>
          <Modal.Body>
            <ScrollView>
              <Row mb={4}>
                <Button minHeight="40px" size="sm" flexGrow={1} mr={2} onPress={selectAll}>
                  <Text color="white">Select All</Text>
                </Button>
                <Button minHeight="40px" size="sm" flexGrow={1} onPress={deselectAll}>
                  <Text color="white">De-select All</Text>
                </Button>
              </Row>
              {
                userList?.map(user => 
                  <BetterFuckingCheckbox 
                    key={user.id} size={26} 
                    checked={opt.users.findIndex(u => u.id === user.id) > -1} 
                    onPress={() => toggleUser(user)}
                  >
                    { user.name }
                  </BetterFuckingCheckbox>
                )
              }
            </ScrollView>
          </Modal.Body>
          <Modal.Footer>
            <Spacer />
            <Button onPress={() => setOpen(false)} backgroundColor="merlot">Done</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Section>  
  );
}
