import { DateTime } from 'luxon';
import { Box, Text, Row, Spacer, Spinner, useColorModeValue, Column, Heading } from "native-base";
import { useEffect, useRef, useState } from 'react';
import { FadeInRight } from 'react-native-reanimated';
import { WeatherForecast } from '../../types/weather';
import RallieAPI from '../../utilities/RallieAPI';
import { IconForWeatherCode } from '../../utilities/weather.helpers';
import { AnimatedBox } from '../animated/AnimatedNativeBase';
import { isDarkMode } from '../../hooks/isDarkMode';

export default function CurrentWeather({ loading }) {
  const [weather, setWeather] = useState<WeatherForecast>(null)
  const [lastUpdated, setLastUpdated] = useState(DateTime.now())

  const getWeather = async () => {
    setWeather(await RallieAPI.currentWeather())
    setLastUpdated(DateTime.now())
  }

  useEffect(() => {
    if (loading) {
      getWeather()
    }
  }, [loading])

  const cardBg = useColorModeValue("white", "dark1")
  const updatedAtColor = useColorModeValue("gray.500", "gray.300")
  const iconColor = useColorModeValue("black", "white")

  const lastUpdatedStr = lastUpdated.toLocaleString({ 
    hour: '2-digit', minute: '2-digit', hour12: true
  })

  const darkMode = isDarkMode()

  return (
    <AnimatedBox 
      px={2}
      py={3} 

      flexDir="row" 
      alignItems="center" 
      entering={FadeInRight.delay(250).duration(600)} 
      rounded="md" 

      // borderWidth={darkMode ? undefined : 1}
      // borderColor="gray.200" 
      bg={cardBg}
    >
      <Column px={4} pt={2} mb={1}>
        <Heading mr={2} fontSize="md" bold>Punta Cana</Heading>
        <Text mt={2} fontSize='sm' color={updatedAtColor}>Updated: { lastUpdatedStr }</Text>

      </Column>

      <Spacer />

      <Row px={4} alignItems="baseline">
        {!weather && <Spinner /> }
        { weather &&
          <>
            { IconForWeatherCode(weather.current_weather.weathercode, iconColor, 28) }
            <Heading ml={2}>{weather.current_weather.temperature}°C</Heading>
          </>
        }
      </Row>
    </AnimatedBox>
  );
}