import { Box, Column, Heading, Row, Spacer, Text, useColorMode, useColorModeValue } from "native-base";
import { FadeInDown, FadeIn, FadeInRight, FadeInLeft, FadeInUp } from "react-native-reanimated";
import { AnimatedBox } from "../animated/AnimatedNativeBase";
import { isDarkMode } from "../../hooks/isDarkMode";

type PropType = {
  title: string,
  end?: any,
  styleProps?: any,
  headerStyleProps?: any,
  children?: any
}

export default function Card(props: PropType) {
  let { 
    title, children, end = null,
    styleProps = {}, headerStyleProps = {}
  } = props

  const animationMapping = {
    left: FadeInRight,
    right: FadeInLeft,
    top: FadeInDown,
    bottom: FadeInUp
  }

  const darkMode = isDarkMode()
  const bg = useColorModeValue("white", "dark4")

  return (
    <AnimatedBox 
      my={2}
      borderWidth={darkMode ? undefined : 1}
      borderColor="gray.200" 
      rounded="md" 
      bg={bg}

      px={6} py={5} 
      {...styleProps}
    >
      {/* flexGrow on Column is needed for Web */}
      <Column space="2" flexGrow={1}>
        <Row {...headerStyleProps}>
          <Heading size="sm" fontWeight="semibold" flexShrink={1}>
            {title}
          </Heading>

          { end }
        </Row>
        {/* flexGrow on Box is needed for Web */}
        <Box flexGrow={1}>
          {children}
        </Box>
      </Column>
    </AnimatedBox>
  );
}