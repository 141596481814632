import FeatherIcons from '@expo/vector-icons/Feather';
import { ResizeMode, Video } from "expo-av";
import { DateTime } from "luxon";
import { Button, Column, IconButton, Image, Row, Spacer, Text, View } from "native-base";
import { Dimensions } from "react-native";
import { Gesture, GestureDetector, GestureHandlerRootView } from "react-native-gesture-handler";
import ImageZoom, { ImageZoomProps } from "react-native-image-pan-zoom";
import { FadeIn } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { UserUploadedMedia } from '../../types/schema/media.schema';
import { IS_WEB } from '../../utilities/constants';
import { AnimatedRow } from "../animated/AnimatedNativeBase";
import { downloadMedia } from '../../helpers/share.helpers';
import SnackbarService from '../../services/SnackbarService';
import { useState } from 'react';

type PropType = {
  source: UserUploadedMedia | any,
  allowDelete: boolean
  onClose: Function,
  shareImage?: Function,
  handleDelete: Function,
  localImage?: boolean,
  imageZoomProps?: ImageZoomProps,

  hideOverlays?: boolean,
  captureGestures?: boolean
}

export default function FullScreenMediaViewer(props: PropType) {
  const { source, shareImage, allowDelete, handleDelete, onClose, localImage, imageZoomProps, hideOverlays, captureGestures = true } = props
  
  const [downloading, setDownloading] = useState(false)
  const downloadUserMedia = async () => {
    setDownloading(true)

    const success = await downloadMedia(source)
    if (success) {
      // SnackbarService.log("Finished downloading.")
    } else {
      SnackbarService.error("Error download image/video. Please try again later.")
    }

    setDownloading(false)
  }
  
  const isPhoto = localImage ? true : source.content_type.startsWith('image')

  const screenWidth = Dimensions.get('window').width
  const screenHeight = Dimensions.get('window').height

  const createdAtStr = DateTime.fromJSDate(new Date(source.created_at)).toLocaleString({ 
    hour: '2-digit', minute: '2-digit', hour12: true, day: "numeric", month: "short"
  })

  const insets = useSafeAreaInsets()

  return (
    <View width="full" height="full" position="relative">
      <AnimatedRow 
        entering={FadeIn.duration(200)}
        // exiting={FadeOut.duration(200)}

        position='absolute' 
        pt={insets.top + 25 + "px"} pb={4} px={4} 
        width="full" zIndex={100} 
        alignItems="center" 
        backgroundColor="rgba(0,0,0,0.5)"

        display={hideOverlays ? "none" : undefined}
      >
        <Column>
          <Text color="lace" fontSize="lg">Shared by: {source.uploaded_by || "Rallie"}</Text>
          {
            !localImage &&
            <>
              <Text color="lace">{createdAtStr}</Text>
              {/* <Text color="lace" fontSize="sm">id: {source.id}</Text> */}
            </>
          }
        </Column>
        <Spacer />
        <IconButton
          onPress={onClose} 
          size='md' 

          icon={<FeatherIcons color='white' size={20} name='x' />}
        />
      </AnimatedRow> 

      <FullScreenMedia 
        isPhoto={isPhoto}
        localImage={localImage}
        source={source}
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        imageZoomProps={imageZoomProps}
        hideOverlays={hideOverlays}
        captureGestures={captureGestures}
      />  

      {
        (shareImage || allowDelete) && !hideOverlays && isPhoto &&
        <AnimatedRow
          entering={FadeIn.duration(200)}
          // exiting={FadeOut.duration(200)}
          position="absolute" 
          bottom={"30px"} width="100%" 
          justifyContent="center"
        >
          <Row 
            p={1} 
            rounded="full" 
            justifyContent="space-evenly" 
            
            backgroundColor="rgba(0,0,0,0.5)" 

            width={200}
            height={50} 
          >
            { shareImage && <IconButton onPress={() => shareImage(source)} icon={<FeatherIcons color="white" size={20} name='share-2' />} /> }
            {/* <Button 
              minH={1}
              variant="ghost"
              isLoading={downloading}
              onPress={downloadUserMedia}
              _spinner={{ color: "white" }}
              startIcon={<FeatherIcons color="white" size={20} name="download-cloud" />}
            /> */}
            {
              allowDelete &&
              <IconButton onPress={() => handleDelete(source.id)} icon={<FeatherIcons color="white" size={20} name="trash-2" />} />
            }
          </Row>
        </AnimatedRow>
      }
    </View>
  )
}

type MediaPropType = {
  isPhoto: boolean,
  localImage: boolean,
  source: UserUploadedMedia | any,
  screenWidth: number,
  screenHeight: number,
  imageZoomProps?: ImageZoomProps,
  hideOverlays?: boolean,
  captureGestures?: boolean
}

const FullScreenMedia = ({ isPhoto, localImage, source, screenWidth, screenHeight, imageZoomProps = {}, hideOverlays, captureGestures }: MediaPropType) => {
  const imageToRender = 
    <Image 
      alt='Unable to load image.' 
      fallbackElement={<Text color="white">Unable to load image</Text>} 
      width='full' height='full' 
      source={localImage ? source : { uri: source.uri }} 
      resizeMode='contain' 
    />
  
  const doClick = () => {
    imageZoomProps.onClick && imageZoomProps.onClick()
  }

  const empty = () => {}
  const tap =  Gesture.Tap().numberOfTaps(1).runOnJS(true).onStart(isPhoto ? doClick : empty);
  const pinch = Gesture.Pinch().runOnJS(true).onStart(isPhoto ? doClick : empty)
  const doubleTap = Gesture.Tap().numberOfTaps(2).runOnJS(true).onStart(empty)

  const composedGestures = Gesture.Simultaneous(tap, doubleTap, pinch)

  return (
    <Column zIndex={-1} backgroundColor='black' width='full' height='full' justifyContent='center' alignItems='center'>
      {
        isPhoto ? 
        (
          IS_WEB ? 
          imageToRender
          :
          <ImageZoom
            cropWidth={screenWidth}
            cropHeight={screenHeight}
            imageWidth={screenWidth}
            imageHeight={screenHeight}
            {...imageZoomProps}
          >
            {imageToRender}
          </ImageZoom>
        )
        : 
        <Video 
          style={{ width: screenWidth, height: screenHeight - 100 }} 
          useNativeControls={true} 
          
          source={{ uri: source.uri }} 

          
          resizeMode={ResizeMode.CONTAIN} 
        />
      }
      
      {
        !hideOverlays && captureGestures && isPhoto &&
        <GestureHandlerRootView style={{ position: "absolute", width: "100%", height: "100%" }}>
          <GestureDetector gesture={composedGestures}>
            <View
              display={hideOverlays ? "none" : undefined}
              width="full" height="full"
              background="rgba(0,0,0,0)"
              >
            </View>
          </GestureDetector>
        </GestureHandlerRootView>
      }
    </Column>
  )
}