import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { UserUploadedMedia } from '../types/schema/media.schema';
import { Alert, Share } from 'react-native';
import { randomId } from './utils';
import ErrorService from '../services/ErrorService';
import AlertService from '../services/AlertService';
import { Activity } from '../types/schema/activities.schema';

/** True iff download successful */
export async function downloadMedia(source: UserUploadedMedia): Promise<boolean> {
  const fileExtension = source.content_type.split('/')[1]
  const fileName = randomId(16) + '.' + fileExtension;

  // Document directory should stay persisted? Maybe unless user
  // uninstalls the app??
  const downloadPath = `${FileSystem.documentDirectory}${fileName}`;

  try {
    await FileSystem.downloadAsync(source.uri, downloadPath);
    return true
  } catch (error) {
    ErrorService.notify(error)
    return false
  }
}

// Written with help from: https://stackoverflow.com/a/62759172
export async function shareUserUploadedMedia(source: UserUploadedMedia) {
  if (await Sharing.isAvailableAsync()) {
    const shareOptions = {
      mimeType: source.content_type,
      UTI: source.content_type,
    }

    const fileExtension = source.content_type.split('/')[1]
    const fileName = randomId(16) + '.' + fileExtension;

    // Download file first, can only share local media
    const downloadPath = `${FileSystem.cacheDirectory}${fileName}`;
    const { uri: localUrl } = await FileSystem.downloadAsync(source.uri, downloadPath);
    
    try {
      await Sharing.shareAsync(localUrl, shareOptions)
    } catch (error) {
      AlertService.alert("Failed to share", "Something went wrong when trying to share.")
      ErrorService.notify(error)
    }

  } else {
    AlertService.alert("Unable to share", "Your device does not allow sharing.")
  }
}

/** Trigger share modal for some given text. */
export async function shareActivity(activity: Activity) {
  try {
    Share.share({
      message: "https://rallie.org/activity_details/" + activity.id
    })
  } catch (error) {
    ErrorService.notify(error)
  }
}