import * as Updates from "expo-updates";
import { Alert } from "react-native";
import ErrorService from "../services/ErrorService";
import { IS_DEV, IS_WEB } from "./constants";
import AlertService from "../services/AlertService";
import { sleep } from "./time";
import Logger from "./Logger";

async function reload() {
  try {
    await Updates.reloadAsync()
  } catch (error) {
    ErrorService.notify(error)
    AlertService.alert("Error trying to relaunch the app.", error.toString())
  }
}

export async function CheckForUpdates() {
  if (IS_WEB) return

  try {
    const update = await Updates.checkForUpdateAsync()

    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();

      await sleep(1250)
      const result = AlertService.confirm(
        "Update Available", 
        "A new version of the app is available. Would you like to restart the app now?",
        [
          { text: "Cancel", style: "cancel" },
          {
            text: "Restart",
            onPress: reload
          }
        ]
      )

      if (IS_WEB && result) {
        await reload()
      }
    }
  } catch (error) {
    Logger.log('FAILED TO CHECK FOR UPDATES', error)
    ErrorService.notify(error)

    // if (!IS_DEV) {
    //   AlertService.alert("ERROR trying to check if update exists.", error.toString())
    // }
  }
}
