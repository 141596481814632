import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createConsumer } from '@rails/actioncable';
import { UserChat, UserChats, UserChatContent } from '../types/schema/user_chats.schema'
import { CABLE_URL } from '../utilities/constants'

export interface ConversationsState {
  availablsChats: UserChats,
  conversations: {
    [x: string]: UserChatContent[]
  }
}

const initialState: ConversationsState = {
  availablsChats: {},
  conversations: {},
}

const nonReactiveState = {
  /** The consumer for a given conversation id */
  connections : {}
}

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setAvailableChats: (state, action: PayloadAction<UserChats>) => {
      state.availablsChats = action.payload
    },
    loadConversation: (state, action: PayloadAction<{ chat_id: string, messages: UserChatContent[] }>) => {
      const { chat_id, messages } = action.payload

      state.conversations[chat_id] = [...messages]
    },
    addMessage: (state, action: PayloadAction<{chat_id: string, newMsg: UserChatContent}>) => {
      const { chat_id, newMsg } = action.payload

      if(state.conversations[chat_id]) {
        // Deleted messages also come through here
        let existsIndex = state.conversations[chat_id].findIndex((msg) => msg.id === newMsg.id || (newMsg.nonce && msg.nonce === newMsg.nonce))

        if(existsIndex > -1) {
          state.conversations[chat_id][existsIndex] = newMsg  
        } else {
          state.conversations[chat_id].push(newMsg)
        }
      }

      // User can be viewing list of all chats
      let oldLatest = state.availablsChats[chat_id].latest_message

      if(!oldLatest || oldLatest?.id === newMsg.id || newMsg.id > oldLatest?.id) {
        state.availablsChats[chat_id].latest_message = newMsg
      }
      
    },
    addMultipleMessages: (state, action: PayloadAction<{chat_id: string, messages: UserChatContent[]}>) => {
      const { chat_id, messages } = action.payload

      let originalMessages = state.conversations[chat_id]
      if(!originalMessages) return

      messages.reverse().forEach(msg => {
        let existsIndex = originalMessages.findIndex((oldMsg) => msg.id === oldMsg.id)
        if(existsIndex === -1) {
          state.conversations[chat_id].unshift(msg)
        }
      })     
    },
    markConversationRead: (state, action: PayloadAction<string>) => {
      const chatId = action.payload
      const convos = state.conversations[chatId]
      
      if (convos) {
        state.availablsChats[chatId].last_read_message_id = convos[convos.length - 1].id
      } 
    }
  }
})

export const { setAvailableChats, addMessage, addMultipleMessages, loadConversation, markConversationRead } = conversationsSlice.actions
export default conversationsSlice.reducer
