import { MarkdownView } from 'react-native-markdown-view';
import merge from 'deepmerge'
import { Linking } from 'react-native';
import ErrorService from '../../services/ErrorService';
import { useColorModeValue } from 'native-base';

export default function MarkdownViewer(props: { styles?: any, [x: string]: any }) {
  const fontColor = useColorModeValue("black", "white")
  const fontSize = 18

  const commonTextProps = { fontSize, color: fontColor }

  const defaultStyles = {
    paragraph: {
      marginTop: 3,
      marginBottom: 3,
      fontSize: fontSize,
      color: fontColor
    },
    list: {
      marginBottom: 3,
      marginTop: 3,
      color: fontColor
    },
    listItem: {
      ...commonTextProps
    },
    listItemBullet: {
      ...commonTextProps
    },
    listItemOrderedContent: {
      ...commonTextProps
    },
    listItemUnorderedContent: {
      ...commonTextProps
    },
    listItemNumber: {
      ...commonTextProps
    },
  }

  const styles = merge(defaultStyles, props.styles || {})

  return (
    <MarkdownView
    onLinkPress={(url) => {
      Linking.openURL(url).catch(ErrorService.notify)
    }}
    styles={merge(styles, props.styles || {})}
    >
      { props.children }
    </MarkdownView>
  );
}
