import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserInfoType } from '../types/userInfo'

type UserLocation = {
  latitude: number,
  longitude: number
}
export interface AdminState {
  userInfo: UserInfoType,
  location?: UserLocation
}

const initialState: AdminState = {
  userInfo: null,
  location: null
}

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfoType>) => {
      state.userInfo = action.payload
    },
    setUserLocation: (state, action: PayloadAction<UserLocation>) => {
      state.location = action.payload
    }
  }
})


export const { setUserInfo, setUserLocation } = userInfoSlice.actions
export default userInfoSlice.reducer
