import { Column, Heading, Row, Spacer, Text, View } from "native-base";
import { Activity } from "../../../types/schema/activities.schema";
import Subtitle from "../../shared/Subtitle";
import LocationInfo from "../../shared/LocationInfo";
import { TouchableHighlight, TouchableOpacity } from "react-native";
import { CustomTheme } from "../../../utilities/theme";

export default function TimelineActivityEntry(props: { event: Activity, onPress: any }) {
  const { event, onPress } = props;

  const { location } = event;
  
  const isSignUp = event.type === 'sign_up'
  const isAssigned = event.type === 'assigned'
  const isInfo = event.type === 'info'

  const needsSignUp = isSignUp && !event.chosenOption

  const hasColoredBorder = true
  let borderColor = 'white'
  if (hasColoredBorder) {
    if (isInfo) borderColor = 'slate'
    if (isAssigned) borderColor = 'tangerine'
    if (isSignUp) borderColor = "merlot"
  }

  return (
    // Margin top needed becuase it's showing events an hour behind...
    <TouchableHighlight onPress={onPress} style={{  width: "100%", height: "100%", borderRadius: 6 }} underlayColor={CustomTheme.colors.slate}>
      <Column 
        py={1} pl={2}
        bg="white"
        _dark={{ bg: "dark4" }} 

        shadow="1"
        borderRadius={5} 
        borderTopWidth={hasColoredBorder ? 4 : 0}
        borderTopColor={borderColor}
        
        borderTopLeftRadius={hasColoredBorder ? 3 : 5}
        borderBottomLeftRadius={hasColoredBorder ? 3 : 5}

        borderLeftColor={"white"}
        borderRightColor={"white"}
        borderBottomColor={"white"} 

        width={"100%"} 
        height={"100%"} 
      >
        <Row mb={1}>
          <Text fontSize='sm'>{event.timeBlock}</Text>
        </Row>
        <Row mb={1}>
          <Heading fontSize="lg" numberOfLines={2} ellipsizeMode="tail" fontWeight='semibold'>{event.title}</Heading>
        </Row>

        {
          location &&
          <Row mb={1}>
            <LocationInfo location={location} fontSize={16} /> 
          </Row>
        }

        { needsSignUp && <Subtitle italic>Choose an option*</Subtitle> }
        { event.chosenOption && <Subtitle>{ isSignUp ? 'Chosen' : 'Assigned' }: {event.chosenOption.title}</Subtitle> }
      </Column>
    </TouchableHighlight>
  );
}