import FeatherIcons from '@expo/vector-icons/Feather';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'

/** 
 * Returns a component representing the weather code.
 * @see https://open-meteo.com/en/docs Section "WMO Weather interpretation codes (WW)"
 */
export function IconForWeatherCode(code: number, color = "black", size = 22) {
  switch (code) {
    // Clear sky
    case 0:
      return <FeatherIcons name='cloud' size={size} color={color} />
  
    // Partly cloudy
    case 1:
    case 2:
    case 3:
      return <MaterialCommunityIcons name='weather-partly-cloudy' size={size} color={color} />
    
    // Fog
    case 45:
    case 48:
      return <MaterialCommunityIcons name='weather-fog' size={size} color={color} />
    
    // Drizzle
    case 51:
    case 52:
    case 53:
      return <MaterialCommunityIcons name='weather-rainy' size={size} color={color} />

    // Freezing drizzle
    case 56:
    case 57:
      return <MaterialCommunityIcons name='snowflake-melt' size={size} color={color} />

    // Rain
    case 61:
    case 63:
    case 65:
    case 66:
    case 67:
    case 80:
    case 81:
    case 82:
      return <FeatherIcons name='cloud-rain' size={size} color={color} />

    // Snow
    case 71:
    case 73:
    case 75:
    case 77:
    case 85:
    case 86:
      return <MaterialCommunityIcons name='snowflake' size={size} color={color} />

    // Thunderstorm
    case 95:
    case 96:
    case 99:
      return <FeatherIcons name='cloud-lightning' size={size} color={color} />

    default:
      return <FeatherIcons name='cloud' size={size} color={color} />
  }
}
