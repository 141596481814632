import { Alert } from "react-native";
// import NfcManager, { NfcEvents, NfcTech } from "react-native-nfc-manager";
import { IS_WEB } from "../utilities/constants";
import Logger from "../utilities/Logger";
import { ndefDecode } from "../services/NFCTools";

export default class NFCManager {
  static async setup() {
    return

    // try {
    //   await NfcManager.start()
    //   Logger.log('NFC Started')
    // } catch (error) {
    //   Logger.error('Error => Failed to start nfc manager', error)
    // }
  }

  /** Read a single tag, then stop. */
  static async readTag(opts = { skipCancel: false }) {
    let tagData = null

    // try {
    //   await NfcManager.requestTechnology(NfcTech.Ndef)

    //   const rawNFCData = await NfcManager.getTag()
    //   tagData = ndefDecode(rawNFCData?.ndefMessage[0])

    // } catch (error) {
    //   Logger.error('Error => Failed to read tag:', JSON.stringify(error))
    // } finally {
    //   if (!opts.skipCancel) NfcManager.cancelTechnologyRequest()
    // }

    return tagData
  }

  static async startScanning() {
    // await NfcManager.requestTechnology(NfcTech.Ndef)
  }

  static async stopScanning() {
    // await NfcManager.cancelTechnologyRequest();
  }
}
