import { Button, Column, Heading, Modal, Row, ScrollView, Spacer, Spinner, Text, View, useColorModeValue } from "native-base";
import { ActivityDetailedInfo } from "../../types/schema/activity_info.schema";
import LocationInfo from "../shared/LocationInfo";
import MarkdownViewer from "../shared/MarkdownViewer";
import Subtitle from "../shared/Subtitle";
import { SafeAreaView } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useState } from "react";
import ActivityAPI from "../../utilities/api_slices/activity.api";
import ActivityDetailsHeader from "./ActivityDetailsHeader";

export default function InfoActivity(props: { activity: ActivityDetailedInfo }) {
  const { activity } = props

  const isAdmin = useSelector((state: RootState) => state.userInfo.userInfo.is_admin)

  const bg = useColorModeValue("slate", "dark3")
  const fontColor = useColorModeValue("black", "white")

  const insets = useSafeAreaInsets()

  return (
    <>
      <Column p={4} flex={1}>
        {/* Need to specify height on this ScrollView, otherwise it overflows beyond the sreen... */}
        <ScrollView px={2} mb={insets.bottom + "px"}>
          { activity.description && <MarkdownViewer>{activity.description}</MarkdownViewer> }
        </ScrollView>
      </Column>
    </>
  )
}
