import NetInfo from "@react-native-community/netinfo";
import { AUTH_COOKIE_KEY } from "../utilities/constants"
import Logger from "../utilities/Logger";
import RallieAPI from "../utilities/RallieAPI";
import StorageManager from "./StorageManager"

/** Handle authenticating user, check if session is valid, etc. */
export default class AuthService {
  static async checkValidSession() {
    try {
      const cookie = await StorageManager.getItem(AUTH_COOKIE_KEY)
      if(cookie && cookie.length > 0) {
        // If user has no internet or server is down, let them view cached content
        const isValidSession = await RallieAPI.isSessionValid(cookie)
        if (isValidSession) {
          await RallieAPI.setup(cookie)

          return true
        }
        else {
          Logger.log('Cookie is invalid, taking to login')
          return false
        }
      }
      else {
        Logger.log('No cookie, taking to login')
        return false
      }
    } catch (error) {
      Logger.error('Could not validate user session', error)
      return false
    }
  }
}