import NetInfo from '@react-native-community/netinfo';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { NotificationContent } from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import { setStatusBarHidden } from 'expo-status-bar';
import * as Updates from 'expo-updates';
import { Column, NativeBaseProvider, useColorMode, useColorModeValue } from "native-base";
import React, { useEffect } from 'react';
import { LogBox, Button as RNButton, Text as RNText, View as RNView, SafeAreaView, StyleSheet } from 'react-native';
import 'react-native-gesture-handler';
import { Provider, useDispatch, useSelector } from 'react-redux';
import ActivityDetails from './src/components/ActivityDetails';
import ChatScreen from './src/components/ChatScreen';
import HomeScreen from './src/components/HomeScreen';
import LandingScreen from './src/components/LandingScreen';
import LoginScreen from './src/components/LoginScreen';
import OnboardingScreen from './src/components/OnboardingScreen';
import SupportScreen from './src/components/SupportScreen';
import ActivityEditScreen from './src/components/admin/ActivityEditScreen';
import AdminModeEnabledMessage from './src/components/admin/AdminMessage';
import NoNetworkMessage from './src/components/admin/NoNetworkMessage';
import ImageGallery from './src/components/screens/ImageGallery';
import { setLatestDeepLink } from './src/redux/deepLinkSlice';
import { setNetworkConnected, setServerConnected } from './src/redux/networksSlice';
import { ReactNavigationRoutes } from './src/routes';
import AuthService from './src/services/AuthService';
import ErrorService from './src/services/ErrorService';
import NFCManager from './src/services/NFCManager';
import NotificationManager from './src/services/NotificationManager';
import { RootState, store } from './src/store';
import Logger from './src/utilities/Logger';
import { IS_WEB } from './src/utilities/constants';
import { ColorModeManager, CustomTheme } from './src/utilities/theme';
import { CheckForUpdates } from './src/utilities/updates';
import RallieAPI from './src/utilities/RallieAPI';

// LogBox.ignoreAllLogs()

SplashScreen.hideAsync()
NFCManager.setup()
const Stack = createNativeStackNavigator();

ErrorService.init()
const ErrorBoundary = ErrorService.ErrorBoundary

function App() {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state: RootState) => state.userInfo)
  const adminEnabled = useSelector((state: RootState) => state.admin.adminEditEnabled)
  const { networkConnected, serverConnected } = useSelector((state: RootState) => state.network)


  setStatusBarHidden(true, 'slide')
  NotificationManager.setup()

  if(userInfo) {
    ErrorService.setUser(userInfo.id.toString(), userInfo.email, userInfo.first_name + ' ' + userInfo.last_name)
  }

  // Check for application updates
  useEffect(() => {
    async function run() { 
      await CheckForUpdates();
    }
    run()
  }, [])

  // Check for user's device network status
  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      dispatch(setNetworkConnected(state.isConnected))
    })

    return unsubscribe
  }, [])

  // Check for server alive status
  useEffect(() => {
    async function run() { dispatch(setServerConnected(await RallieAPI.isAlive())) }


    const interval = setInterval(async () => {
      dispatch(setServerConnected(await RallieAPI.isAlive()))
    }, 10000)

    run()
    return () => { clearInterval(interval) }
  }, [])

  const linkingOpts: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [Linking.createURL('/'), 'http://localhost:19006', 'https://rallie.org'],
    config: { screens: ReactNavigationRoutes },

    subscribe(listener) {
      const onReceiveURL = async ({ url }: { url: string }) => {
        if (await AuthService.checkValidSession()) {
          Logger.log('[DeepLink] User session IS valid.')
          listener(url)
        } else {
          Logger.error('[DeepLink] User session not valid.')
          listener('/')
        }
      };

      // Listen to incoming links from deep linking
      const urlSubscription = Linking.addEventListener('url', onReceiveURL);

      // Listen to expo push notifications
      const pushSubscription = Notifications.addNotificationResponseReceivedListener(async (response) => {
        const content = response.notification.request.content as NotificationContent;
        
        dispatch(setLatestDeepLink(content.data?.url))
      });

      return () => {
        // Clean up the event listeners
        urlSubscription.remove()
        pushSubscription.remove();
      };
    }
  }

  // A bug with React navigation causes the navigation
  // stack to not load properly. This workaround kicks
  // the user back to the `/` page, ensuring they go
  // via the proper flow.
  if (IS_WEB) {
    if (location.pathname !== '/' && location.pathname !== '/LandingScreen') {
      localStorage.setItem("urlToVisit", location.pathname)
      window.location.href = window.location.origin
    }
  }

  const bg = useColorModeValue(CustomTheme.colors.lace, CustomTheme.colors.dark0)

  return (
    <NativeBaseProvider theme={CustomTheme} colorModeManager={ColorModeManager}>
      <NavigationContainer
        linking={linkingOpts}
      >
        <Stack.Navigator 
          initialRouteName="LandingScreen"
          screenOptions={{ 
            fullScreenGestureEnabled: true,
            header: () => {
              const toggle = useColorMode().toggleColorMode

              return (
                <Column>
                  { (!networkConnected || !serverConnected) && <NoNetworkMessage /> }
                  { adminEnabled && <AdminModeEnabledMessage /> }
                  {/* <Button onPress={toggle}>Toggle</Button> */}
                </Column>
              )
            },
            contentStyle: {
              backgroundColor: bg
            }
          }}
        >
            <Stack.Screen
              name="SupportScreen"
              component={SupportScreen}
            />

            <Stack.Screen
              name="LandingScreen"
              component={LandingScreen}
            />

            <Stack.Screen 
              name="Onboarding"
              component={OnboardingScreen}
            />

            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{ headerShown: false, animation: 'none' }}
            />

            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={{
                animation: "none"
              }}            
            />

            <Stack.Screen
              name="Activity Details"
              component={ActivityDetails}
            />

            <Stack.Screen
              name="Gallery"
              component={ImageGallery}
            />
{/* 
            <Stack.Screen
              name="GalleryList"
              component={GalleryList}
              options={{
                header: () => {
                  const navigation = useNavigation()
                  const insets = useSafeAreaInsets()
                  
                  return (
                    <View>
                      <Row pl='4' pr='2' width='full' minHeight={75 + insets.top + "px"} pt={insets.top + "px"} backgroundColor='slate' alignItems='center'>
                        <FeatherIcons onPress={() => goBackNavigation(navigation)} name='arrow-left' size={32} color='white' />
                        <Heading ml={2} size='md' color='white'>My Gallery</Heading>
                      </Row>
                    </View>
                  )
                }
              }}
            /> */}

            {/* <Stack.Screen
              name='Chat'
              component={ChatScreen}
            /> */}

            <Stack.Screen
              name='ActivityEdit'
              component={ActivityEditScreen}
            />
        </Stack.Navigator>
      </NavigationContainer>
    </NativeBaseProvider>
  );
}

function ErrorView () {
  const reload = () => {
    if(IS_WEB) window?.location?.reload()
    else Updates.reloadAsync()
  }

  return (
    <SafeAreaView style={StyleSheet.absoluteFill}>
      <RNView style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <RNText style={{ fontSize: 20, marginBottom: 6 }}>Something went wrong!</RNText>
        <RNText style={{ marginBottom: 12 }}>Our team has been notified.</RNText>
        <RNButton onPress={reload} title='Reload' />
      </RNView>
    </SafeAreaView>
  )
}

const RallieApp = () => 
  <Provider store={store}>
    <App />
  </Provider>


console.log('using error boundary', ErrorBoundary)
// export default RallieApp;
export default () => 
  <ErrorBoundary FallbackComponent={ErrorView}>
    <RallieApp />
  </ErrorBoundary>
