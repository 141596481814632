import FeatherIcons from '@expo/vector-icons/Feather';
import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import { Text, Row, Column, Spacer, useColorModeValue } from "native-base";
import { TouchableOpacity } from "react-native";
import { Activity } from "../../types/schema/activities.schema";
import Subtitle from "../shared/Subtitle";

export default function ActivitySummary({ activity }: { activity: Activity; }) {
  const navigation = useNavigation();

  const startTime = DateTime.fromJSDate(new Date(activity.start_time));
  const startTimeTitle = startTime.toRelative();
  const startTimeReadable = startTime.setZone("America/Barbados").toLocaleString({
    month: 'short', day: '2-digit',
    hour: '2-digit', minute: '2-digit', hour12: true
  });

  const openActivity = () => {
    navigation.navigate('Activity Details', { id: activity.id });
  };

  const iconColor = useColorModeValue("gray", "white");

  return (
    <TouchableOpacity onPress={openActivity}>
      <Row my={2} alignItems="center">
        <Column flexShrink={1}>
          <Text>{activity.title}</Text>
          <Subtitle mt={1}>{startTimeReadable} • {startTimeTitle}</Subtitle>
        </Column>

        <Spacer />

        <Column ml={1} alignItems='flex-end'>
          <FeatherIcons name="chevron-right" size={24} color={iconColor} />
        </Column>
      </Row>
    </TouchableOpacity>
  );
};
