import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface NetworkState {
  networkConnected: boolean,
  serverConnected: boolean,
}

const initialState: NetworkState = {
  networkConnected: true,
  serverConnected: true
}

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    setNetworkConnected: (state, action: PayloadAction<boolean>) => {
      state.networkConnected = action.payload
    },
    setServerConnected: (state, action: PayloadAction<boolean>) => {
      state.serverConnected = action.payload
    }
  }
})


export const { setNetworkConnected, setServerConnected } = networkSlice.actions
export default networkSlice.reducer
