import RallieAPI from "../RallieAPI";
import ErrorService from "../../services/ErrorService";
import { LivingAccomodation } from "../../types/schema/livingAccomodation.schema";
import StorageManager from "../../services/StorageManager";

export default class LivingAccomodationsAPI extends RallieAPI {
  static STORAGE_KEY = "LIVING_ACCOMODATION"

  /** True iff saved successfully */
  static async setAccomodation(data: LivingAccomodation): Promise<boolean> {
    try {
      await StorageManager.setItem(this.STORAGE_KEY, JSON.stringify(data))
      return true
    } catch (error) {
      ErrorService.notify(error)
      return false
    }
  }

  static async getAccomodation(): Promise<LivingAccomodation | null> {
    try {
      const exists = await StorageManager.getItem(this.STORAGE_KEY)

      return exists ? JSON.parse(exists) : null

    } catch (error) {
      ErrorService.notify(error)

      return null
    }
  }

  static async removeAccomodation() {
    try {
      await StorageManager.removeItem(this.STORAGE_KEY)
    } catch (error) {
      ErrorService.notify(error)
    }
  }
}
