import { Platform } from "react-native"
import { IS_DEV } from "./constants"

export default class Logger {
  static log(...args) { this._log('log', ...args) }
  static warn(...args) { this._log('warn', ...args) }
  static error(...args) { this._log('error', ...args) }

  private static _log(type: any, ...args: any[]) {
    if(!IS_DEV) return

    const timestamp = new Date().toLocaleTimeString(
      'en-US',
      { 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit',
      }
    )
    console[type](`[${timestamp}] [${Platform.OS}]`, ...args)
  }
}
