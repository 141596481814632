import { configureStore } from '@reduxjs/toolkit'
import adminSlice from './redux/adminSlice'
import conversationsSlice from './redux/conversationsSlice'
import deepLinkSlice from './redux/deepLinkSlice'
import networkSlice from './redux/networksSlice'
import userInfoSlice from './redux/userInfoSlice'
import mapSlice from './redux/mapSlice'
import appEventsSlice from './redux/appEventsSlice'

export const store = configureStore({
  reducer: {
    appEvents: appEventsSlice,
    conversations: conversationsSlice,
    deepLinks: deepLinkSlice,
    admin: adminSlice,
    userInfo: userInfoSlice,
    network: networkSlice,
    map: mapSlice
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
