import { Text, View } from "native-base";

type PropType = {
  backgroundColor?: string,
  color?: string,
  fontSize?: number | string,
  width?: string | number,

  my?: any,
  mx?: any,
  
  label: string
}

export default function Chip({label, mx, my, color, backgroundColor, fontSize, width}: PropType) {
  return (
    <View px={2} py={1} mx={mx || 0} my={my || 0} rounded='md' width={width || '100%'} backgroundColor={backgroundColor || 'gray.500'}>
      <Text fontSize={fontSize || 12} color={color || 'black'}>{ label }</Text>
    </View>
  )
}
