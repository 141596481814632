import FeatherIcons from '@expo/vector-icons/Feather';
import { Button, Column, Heading, Modal, Row, ScrollView, Spinner, Text, View, useColorModeValue } from "native-base"
import { Activity } from "../../types/schema/activities.schema"
import Subtitle from "../shared/Subtitle"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import LocationInfo from "../shared/LocationInfo"
import { useState } from "react"
import ActivityAPI from "../../utilities/api_slices/activity.api"
import { ActivityDetailedInfo } from "../../types/schema/activity_info.schema"
import { useNavigation } from "@react-navigation/native"
import { CustomTheme } from '../../utilities/theme';

type PropType = {
  activity: ActivityDetailedInfo,
  onCheckIn: any
  onCheckOut: any,
  loading: boolean
}

export default function ActivityDetailsHeader(props: PropType) {
  const { activity, loading, onCheckIn, onCheckOut } = props

  const navigation = useNavigation()
  const isAdmin = useSelector((state: RootState) => state.userInfo.userInfo.is_admin)
  const bg = useColorModeValue("slate", "dark3")

  const [open, setOpen] = useState(false)
  const [checkedIn, setCheckedIn] = useState(null)
  const [checkedOut, setCheckedOut] = useState(null)

  const [refreshing, setRefreshing] = useState(false)

  const refresh = async () => {
    setRefreshing(true)

    const resp = await ActivityAPI.listOfCheckedInAndOutUsers(activity.id)
  
    setCheckedIn(resp.checkedIn)
    setCheckedOut(resp.checkedOut)
    setRefreshing(false)
  }

  const openModal = () => {
    setOpen(true)

    refresh()
  }

  const goToAdmin = () => {
    navigation.navigate('ActivityEdit', { activity_id: activity.id })
  }

  const goToInfoGallery = () => {
    if (activity.options?.length > 0) {
      navigation.navigate('Gallery', { option_id: activity.options[0].id })
    }
  }

  const sort = (users: any[]) => {
    return users.sort((a, b) => a.fullName > b.fullName ? 1 : -1)
  }

  let canCheckIn = activity?.allowCheckIn
  if (activity?.type !== 'info' ) {
    canCheckIn = canCheckIn && activity?.chosenOption != null
  }

  const commonButtonProps = {
    mr: 4, minH: 1, rounded: "2xl", isLoading: loading, shadow: "none"
  }

  return (
    <Column>
      <Column px={8} py={4} bg={bg}>
        <Text color='white' fontSize='xl'>{ activity?.title }</Text>
        <Subtitle color='white' fontSize="md">{ activity.timeBlock }</Subtitle>

        { activity.location && 
          <View mt={4} mb={2}>
            <LocationInfo location={activity.location} color="white" fontSize="md" />
          </View>
        }

        <View mb={4} />

        <Row mb={4}>
        {
          canCheckIn &&
          <>
            {
              activity.checkedIn ?
              <Button 
                {...commonButtonProps}
                onPress={onCheckOut} 
                colorScheme="gray"
                variant="outline"
                startIcon={<FeatherIcons color="white" name="upload" size={20} />}
              >
                <Text ml={2} color="white">Check-out</Text>
              </Button>
              :
              <Button 
                {...commonButtonProps}
                onPress={onCheckIn}

                background="orange.400" 
                bg="tangerine" 

                startIcon={<FeatherIcons color="white" name="download" size={20} />}
              >
                <Text ml={2} color="white">Check-in</Text>
              </Button>
            }
          </>
          }

          {
            activity.type === "info" &&
            <Button {...commonButtonProps} 
              background="merlotTint"
              bg="merlot"
              flexGrow={1}
              isLoading={false}
              startIcon={<FeatherIcons name='image' color="white" size={17} />}

              onPress={goToInfoGallery}
            >
              <Text color="white">Gallery</Text>
            </Button>
          }
        </Row>

        {
          isAdmin &&
          <Row>
            <Button 
              {...commonButtonProps} isLoading={false}
              variant="outline" 
              endIcon={<FeatherIcons size={18} name="external-link" color="white" />}
              onPress={goToAdmin}
            >
              <Text color="white">Admin</Text>
            </Button>

            <Button onPress={openModal} {...commonButtonProps} isLoading={false} colorScheme="gray" variant="outline">
              <Text color="white">View Checked-in Users</Text>
            </Button>
          </Row>
        }
      </Column>

      <Modal isOpen={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Content p={6} flex={1}>
          <Column flex={1}>
            <Row w="full">
              <Button flexGrow={1} minH={1} mb={6} isLoading={refreshing} onPress={refresh}>
                <Text color="white">Refresh</Text>
              </Button>

              <Button ml={4} flexGrow={1} minH={1} mb={6} shadow="none" colorScheme="gray" variant="outline" onPress={() => setOpen(false)}>
                <Text>Close</Text>
              </Button>
            </Row>

            <ScrollView>
              <Heading size="md" mb={2}>NOT Checked-In</Heading>
              {
                checkedOut && sort(checkedOut).map(user => <Text key={user.id}>{user.fullName}</Text>)
              }
              {
                checkedOut && checkedOut.length === 0 && <Subtitle>All set!</Subtitle>
              }

              <Heading size="md" mt={4} mb={2}>Checked-In</Heading>
              {
                checkedIn && sort(checkedIn).map(user => <Text key={user.id}>{user.fullName}</Text>)
              }
              {
                checkedIn && checkedIn.length === 0 && <Subtitle>No users.</Subtitle>
              }
              { !checkedIn && <Spinner /> }
            </ScrollView>
          </Column>
        </Modal.Content>
      </Modal>
    </Column>
  )
}
