import Logger from "../Logger";
import RallieAPI from "../RallieAPI";
import ErrorService from "../../services/ErrorService";
import { UserInfoType } from "../../types/userInfo";
import StorageManager from "../../services/StorageManager";

export default class UserAPI extends RallieAPI {
  static USER_DATA_CACHE_KEY = "user_data_cache_key"

  static async requestActionCableToken(): Promise<string> {
    try {
      const resp = await this.client.get('/users/cable_token')
      return resp.data.token
    } catch (error) {
      ErrorService.notify(error)
      return ''
    }
  }

  /** Get user data. Only call this once user is authenticated. */
  static async getUserData(): Promise<UserInfoType> {
    try {
      const resp = await this._http.get('/user_info')

      await StorageManager.setItem(this.USER_DATA_CACHE_KEY, JSON.stringify(resp.data))

      return resp.data
    } catch (error) {
      ErrorService.notify(error)
      if (error.code === "ERR_NETWORK") {
        const data = await StorageManager.getItem(this.USER_DATA_CACHE_KEY)

        return data ? JSON.parse(data) : null
      }
      return null
    }
  }
}
