import Constants from "expo-constants"
import { Platform } from "react-native"
import { CustomTheme } from "./theme"

const PROD_API = Constants.expoConfig.extra.PRODUCTION_API_URL
const WEB_DEV_API = "http://localhost:5100"

const PROD_CABLE = Constants.expoConfig.extra.PRODUCTION_CABLE_URL
const WEB_DEV_CABLE = "http://localhost:5100/cable"
const NATIVE_DEV_CABLE = "ws://10.0.2.2:5100/cable"

export const IS_WEB = Platform.OS === 'web'
export const IS_NATIVE = !IS_WEB
export const IS_DEV = !!__DEV__
export const IS_IOS = Platform.OS === "ios"
export const IS_ANDROID = Platform.OS === "android"

let API_URL_BUILDER;
if (IS_DEV) {
  if (IS_WEB) API_URL_BUILDER = WEB_DEV_API
  else if (IS_IOS) API_URL_BUILDER = "http://localhost:5100"
  else {
    API_URL_BUILDER = "http://192.168.2.15:5100"
  }
} else {
  API_URL_BUILDER = PROD_API
}
export const API_URL = API_URL_BUILDER

export const CABLE_URL = IS_DEV ? (IS_WEB ? WEB_DEV_CABLE : NATIVE_DEV_CABLE) : PROD_CABLE

export const AUTH_CLIENT_IDS = {
  expoClientId: '894457063029-3i0uf2i241j22l5v2lvlpvifim27u610.apps.googleusercontent.com',
  iosClientId: '894457063029-qu2aqg8nj42g8cdbl0cj3h5hjoh5ibkv.apps.googleusercontent.com',
  androidClientId: '894457063029-dd0h01qvbgh6rcjk302sqv230ckgtmts.apps.googleusercontent.com',
  webClientId: '894457063029-1ve4k5ees1n9moleacns736oplr3bgr5.apps.googleusercontent.com',

}

export const DAYS_OF_WEEK_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const SNACKBAR_SHORT = 2000
export const SNACKBAR_MEDIUM = 5000
export const SNACKBAR_LONG = 8000
export const SNACKBAR_INDEFINITE = 9999999
export const SNACKBAR_WARN ={
  backgroundColor: '#f97316',
  duration: SNACKBAR_MEDIUM,
}
export const SNACKBAR_ERROR = {
  backgroundColor: CustomTheme.colors.error,
  duration: SNACKBAR_MEDIUM
}

// Storage Manager
export const AUTH_COOKIE_KEY = 'auth_cookie'

export const WEATHER_URL = 'https://api.open-meteo.com/v1/forecast?latitude=18.6935&longitude=-68.4279&current_weather=true'

export const DEBUG = {
  borderWidth: 2,
  borderColor: 'red.500'
}
