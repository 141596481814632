import { PathConfigMap } from "@react-navigation/native"

/**
 * React Navigation routes.
 * @see https://reactnavigation.org/docs/configuring-links#mapping-path-to-route-names
 */
const ReactNavigationRoutes: PathConfigMap<ReactNavigation.RootParamList> = {
  Home: {
    initialRouteName: 'Dashboard',
    screens: {
      Dashboard: 'dashboard',
      Socials: {
        screens: {
          Chats: 'socials/chats',
          SocialsGallery: 'socials/gallery'
        }
      },
      Schedule: 'schedule',
      
      // TODO: Add filter options as params for map?
      Map: 'map',
    }
  },
  Chat: {
    initialRouteName: 'LandingScreen',
    path: 'socials/chats/:chat_id'
  },
  Gallery: 'gallery/:option_id',
  'Activity Details': 'activity_details/:id',
  SupportScreen: 'support',
  LandingScreen: '*',
}

// Mapps the route path to the name used by navigation.navigate(...)
const NavigationRoutesReverseMapping = {
  activity_details: 'Activity Details',
  gallery: 'Gallery',
  dashboard: 'Home',
  schedule: 'Schedule',
  map: 'Map',
  GalleryList: 'GalleryList',
  support: 'SupportScreen'
}

export { ReactNavigationRoutes, NavigationRoutesReverseMapping }
