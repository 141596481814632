import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppEventsState {
  activitySignUp: number,
  gallery: number
}

const initialState: AppEventsState = {
  /* When user has done a sign up action for an activity */
  activitySignUp: 0,

  /* When user has modified a gallery */
  gallery: 0
}

// Poor-mans event bus, get notified of updates across the app :)
export const appEventsSlice = createSlice({
  name: "appEvents",
  initialState,
  reducers: {
    activitySignUpModified: (state, action) => { state.activitySignUp += 1 },
    galleryModified: (state, action) => { state.gallery += 1 }
  }
})

export const { activitySignUpModified, galleryModified } = appEventsSlice.actions
export default appEventsSlice.reducer
