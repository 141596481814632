import { Text, Button, Divider, Input, Column, Row, Heading, Spinner, FlatList, Spacer, useColorModeValue, } from "native-base";
import { useEffect, useState } from "react";
import { Keyboard, RefreshControl, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";
import SnackbarService from "../../services/SnackbarService";
import { RootState } from "../../store";
import { AdminActivitySummary } from "../../types/schema/activity_info.schema";
import AdminAPI from "../../utilities/api_slices/admin.api";
import Chip from "../shared/Chip";
import AlertService from "../../services/AlertService";

export default function AdminActivitiesList({ navigation }) {
  const [allActivities, setAllActivities] = useState<AdminActivitySummary[]>()
  
  const openActivity = (activity_id: number) => {
    navigation.navigate('ActivityEdit', { activity_id })
  }

  const reset = async () => {
    setAllActivities(null)
    setAllActivities(await AdminAPI.getAllActivities())
  }

  const handleDelete = (item: AdminActivitySummary) => {
    const doDelete = async () => {
      const result = await AdminAPI.deleteActivity(item.id)
      if (result) {
        SnackbarService.warn("Activity deleted.")
      } else {
        SnackbarService.error("Error trying to delete activity. We have been notified.")
      }

      await reset()
    }

    AlertService.confirm(
      "Delete Activity", 
      `Are you sure you want to delete "${item.title}"?\n\nYOU CANNOT UNDO THIS!!`,
      [
        {
          text: "Cancel", style: "cancel" 
        },
        {
          "text": "Delete", style: "destructive",
          onPress: doDelete
        }
      ]
    )
  }

  useEffect(() => {
    reset()
  }, [])

  const cleaned = allActivities || []
  const bg = useColorModeValue("lace", "dark0")

  return (
    <Column p={2} pt={4} bg={bg}>
      <FlatList 
        refreshControl={<RefreshControl refreshing={!allActivities} onRefresh={reset} />}
        data={cleaned}
        renderItem={
          ({ item }) => 
          <Row p={1} key={item.id} mb={2}>
            <TouchableOpacity onPress={() => openActivity(item.id)} style={{ flexShrink: 1 }}>
              <Column>
                <Text bold>{item.title}</Text>
                <Text fontSize='xs'>{item.timeBlock}</Text>
                {
                  item.hidden && <Chip label="Hidden" my={1} fontSize={11} width={'55px'} color='white' backgroundColor="slate" />
                }
              </Column>
            </TouchableOpacity>

            <Spacer />

            <Row>
              <Button onPress={() => handleDelete(item)} variant="outline" borderColor="red.500" borderWidth={2} shadow="none" alignSelf="flex-start">
                <Text color="red.500">Delete</Text>
              </Button>
            </Row>
          </Row>
        }
      />
    </Column>
  );
}
