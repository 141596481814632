import { useEffect } from "react";
import Animated, { Easing, FadeInUp, useAnimatedProps, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated"
import Svg, { Defs, ClipPath, Path, G } from "react-native-svg"
import { IS_WEB } from "../../utilities/constants";

const AnimatedPath = Animated.createAnimatedComponent(Path)

const RallieIcon = (props: { animated: boolean }) => {
  const shouldAnimate = props.animated && !IS_WEB
  const theta = useSharedValue(400);

  const animatedProps = useAnimatedProps(() => {
    const test = withTiming(theta.value, { duration: shouldAnimate ? 1000 : 0 })
    return {
      strokeDashoffset: test,
    }
  })

  useEffect(() => {
    theta.value = 0
  }, [])

  return (
    <Svg
      strokeMiterlimit={10}
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        width: 100, 
        height: 100
      }}
      viewBox="0 0 400 400"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#a)">
        <G fill="none" stroke="#8d335c" strokeWidth={24}>
          <AnimatedPath
            animatedProps={animatedProps}
            strokeDasharray={IS_WEB ? '' : '450 400'}
            d="M158.481 91.808c0-24.25 19.659-43.91 43.91-43.91 24.25 0 43.909 19.66 43.909 43.91 0 24.25-19.659 43.909-43.909 43.909-24.251 0-43.91-19.659-43.91-43.91ZM191.868 339.812c-24.425 0-44.226-19.264-44.226-43.027v-86.109c0-23.764 19.801-43.027 44.226-43.027h14.5c24.425 0 44.226 19.263 44.226 43.027l1.764 141.426M44.55 111.737c0-16.579 13.439-30.018 30.018-30.018 16.578 0 30.018 13.44 30.018 30.018 0 16.579-13.44 30.018-30.018 30.018-16.579 0-30.019-13.439-30.019-30.018ZM67.374 288.141c-16.698 0-30.234-13.17-30.234-29.415v-58.868c0-16.246 13.536-29.415 30.234-29.415h9.913c16.698 0 30.234 13.169 30.234 29.415l1.206 96.684M355.451 115.856c0-16.578-13.44-30.018-30.019-30.018-16.578 0-30.018 13.44-30.018 30.018 0 16.579 13.44 30.018 30.018 30.018 16.579 0 30.019-13.439 30.019-30.018ZM332.626 292.26c16.698 0 30.234-13.17 30.234-29.415v-58.868c0-16.245-13.536-29.415-30.234-29.415h-9.913c-16.698 0-30.234 13.17-30.234 29.415l-1.206 96.684" />
        </G>
      </G>
    </Svg>
  )
}

export default RallieIcon
