import { createConsumer, logger } from '@rails/actioncable';
import UserAPI from '../utilities/api_slices/user.api';
import { CABLE_URL, IS_DEV } from '../utilities/constants';
import Logger from '../utilities/Logger';
import RallieAPI from '../utilities/RallieAPI';

export type ActionCableConnection = {
  chat: {
    [id: string]: any
  },

  // Can only subscribe to one at a time
  read_receipt: any
}

export default class ActionCableService {
  private static consumer: any
  private static channels: ActionCableConnection = { chat: [], read_receipt: null}
 
  public static token: string = '';
  private static inited = false;

  static async init() {
    if(this.inited) return

    this.token = await UserAPI.requestActionCableToken()
    this.consumer = createConsumer(CABLE_URL + '?token=' + this.token)

    this.inited = true
  }

  static reset () {
    try {
      this.disconnectFromChats()
      this.disconnectFromReadReceipt()
    } catch(e) {
      // Don't care
    }

    this.token = ""
    this.inited = false
    this.consumer = null
    this.channels = { chat: [], read_receipt: null}
  }

  static getChatChannelForId(user_id: number) {
    return this.channels.chat[user_id]
  }

  static connectToReadReceipt({ received, connected, disconnected, rejected }) {
    const channel = this.consumer.subscriptions.create(
      { channel: 'ReadReceiptChannel' },
      { received, connected, disconnected, rejected }
    )

    this.channels.read_receipt = channel
  }

  /** Connect to chats and run the provided callbacks */
  static connectToChat({ user_id = null, received, connected, disconnected, rejected }) {
    if (this.token === '') throw new Error('ActionCableService: Token must be set before use!')

    // Avoid redundant connections, less load on server
    if(this.channels.chat[user_id]) {
      Logger.log('Already connected to', user_id)
      return
    }

    const channel = this.consumer.subscriptions.create(
      { channel: 'ChatChannel', user_id },
      { received, connected, disconnected, rejected }
    )

    this.channels.chat[user_id] = channel
  }

  /** Mark chat message with given ID as read. */
  static sendReadReceipt(id: number) {
    if (!this.channels.read_receipt) {
      // Logger.warn('ActionCableService: Read receipt channel must be initialized first!')
      return
    }
    
    this.channels.read_receipt.perform('mark_as_read', { message_id: id })
  }

  static disconnectFromReadReceipt() {
    this.channels?.read_receipt?.unsubscribe()

    this.channels.read_receipt = null

    Logger.log('Disconnected from read receipts.')
  }

  static disconnectFromChats() {
    Logger.log('Disconnecting from', Object.keys(this.channels.chat).length, 'chats.')

    for(const chat_id in this.channels.chat) {
      this.channels.chat[chat_id].unsubscribe()
      delete this.channels.chat[chat_id]
    }
  }
}