import { Alert, AlertButton, AlertOptions } from "react-native";
import { IS_WEB } from "../utilities/constants";

export default class AlertService {

  /** Show alert to user. On the web, only title is displayed. */
  static alert(title: string, message?: string, buttons?: AlertButton[], options?: AlertOptions) {
    if (IS_WEB) {
      window.alert(title)
    } else {
      Alert.alert(title, message, buttons, options)
    }
  }

  /** Same as alert(), but on the browser asks user for confirmation. */
  static confirm(title: string, message?: string, buttons?: AlertButton[], options?: AlertOptions): boolean {
    if (IS_WEB) {
      return window?.confirm(title)
    } else {
      Alert.alert(title, message, buttons, options)
      return true
    }
  }
}